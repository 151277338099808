<template lang="html">
  <div v-bind:id="id">
    
    <bulmarowsprops v-if="showModal && isAdmin() && getEditProps" 
      @change="setContainerProps($event)" 
      @cancel="showModal=false" 
      @remove-component="removeComponent" 
      v-bind:rows="config.rows" 
   
      v-bind:id="this.id"
      
    ></bulmarowsprops>
    
    <!-- <div class="box"  v-if="editmode" ><span class="icon" v-on:click="showModal=true"><i class="fas fa-pen"></i></span></div> -->
    <div class="rowspacer" v-for="(r, index) in config.rows" v-bind:key="r.id" >
      <div class="box">
        <component v-bind:is="r.type" v-bind:id="r.id" ></component>
        <creator v-if="r.type=='bulmaEmpty' && isAdmin() && getEditProps"  v-bind:index="index" v-bind:oldid="r.id" v-bind:fatherId="idCopy" @create-component="addComponent"></creator>
        <div class="block" v-if="r.type==''">
          
          <div class="block">
            <span class="icon" v-if="true" v-on:click="showModal=true"><i class="fas fa-pen"></i></span>
          </div>
        </div>
        
      </div>
    </div>
    
    
    

    
    
    <div v-if="isAdmin() && getEditProps" class="lastComponent">
    <div class="rowspacer" v-if="!config.rows">
      <div class="box"><span class="icon" v-if="true" v-on:click="showModal=true"><i class="fas fa-pen"></i></span></div>
    </div>

    
    
    
    <div class="mt-4">
      
      <button v-on:click="switchEditMode" class="button is-link ml-2" >
        <span class="icon is-small">
          <i class="fas" :class="editmode ? 'fa-check' : 'fa-pen'" id="edit"></i>
        </span>
      </button>
      <button class="button is-link is-pulled-right mr-2" v-on:click="showModal = true" v-if="editmode">Edit rows</button>
    </div>
    
    <creator v-if="editmode" v-bind:fatherId="idCopy" @create-component="addComponent" ></creator>
    </div>
    
    
    
  </div >
</template>

<script>
import bulmarowsprops from '../components/bulma_rowsprops.vue'
import creator from './creator.vue'

export default {
  name: 'bulmaRows',
  components: {
    bulmarowsprops,
    creator,
  },
  computed: {
    config: function() {
      return this.$store.getters.getObject(this.id).data;
    },
    editmode: function() {
      return this.$store.getters.getEditMode(this.id);
    },
    romode: function() {
      return this.$store.getters.getROMode(this.id);
    },
    getEditProps(){
      return this.$store.getters.getEditProps;
    },
    
  },
  props: {
    id: String,
    data: Object,
  },
  data() {
    return {
      showModal:false,
      components: [],
      idCopy: this.id,
    }
  },
  mounted: function() {
  },
  async created() {
  },
  methods: {
    switchEditMode(){
      var newEditStatis = !this.$store.getters.getEditMode(this.id)
      var payload={ id: this.id, editmode: newEditStatis};
      this.$store.commit('SET_EDITMODE', payload);
    },
    saveConfig: function() {
      console.log('saveConfig');
    },
    setContainerProps: function( p ) {
      this.showModal=false;
      //this.$emit('change', p.cols);
    },
    async addComponent(data) {
      
      if (!data.component) {
        console.warn("addComponent: 'component' è undefined o nullo");
        return;
      }
      
      // Trova l'indice dell'elemento da rimuovere solo se `component.oldid` esiste
      //const index = data.oldid ? this.config.rows.findIndex(item => item.id === data.oldid) : -1;
      
      
      //cerco vecchio componente
      let localRows;
      if (data.index>=0) {
        // Se 'oldid' è presente, filtra le righe escludendo quella con lo stesso ID
        //localRows = this.config.rows.filter(item => item.id !== data.oldid);
        localRows = this.config.rows.filter((_, index) => index !== data.index);
      } else {
        // Se 'oldid' non è presente, usa tutte le righe
        localRows = [...this.config.rows];
      }
      
      
      //let savedNotes=component.notes;
      
      //inserisco nuovo componente
      console.log("row component", data.component);
      if (data.index >=0) {
        localRows.splice(data.index, 0, data.component);  
      } else {
        localRows.push(data.component); 
      }
      
      
      const props= { rows:localRows };
        
      
      
      console.log("new props: ", data.component);
      await this.$store.dispatch('createObject', { id: data.component.id, type: data.component.type});
      
      console.log("new rows", localRows);
      await this.$store.dispatch('setObjectDB', { id: this.id, data: props });
      
      let cfg = this.config;
      cfg.rows = localRows;
      
      
      // nuovo oggetto
      this.$store.commit('SET_CONFIG_OBJECT', { id: data.component.id, type: data.component.type });
      
      // modifica bulmaRows
      this.$store.commit('SET_CONFIG_OBJECT', { id: this.id, type: "bulmaRows", data: cfg });
    },
    removeComponent(data){
      console.log("entered")
      const cfg = this.config;
      cfg.rows = data;
      this.$store.commit('SET_CONFIG_OBJECT', { id: this.id, type: "bulmaRows", data: cfg });
    },
    isAdmin(){
      return this.$store.getters.isAdmin;
    },
    
  }
}
</script>

<style scoped>
.rowspacer:not(:last-child) {
  margin-bottom: 0.5rem;
}

.lastComponent{
  margin-bottom: 11%;
}
</style>
