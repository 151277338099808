<template>

  <div class="home" v-if="ready">

    <component v-bind:is="rootobjecttype" v-bind:id="rootobjectid" ></component>
    <bulmaModal v-on:click="manageModal($event)"
        v-for="status in process_stack" v-bind:key="status.id" v-bind:id="status.processId" v-bind:title="status.title">
      <component v-bind:is="status.type" v-bind:id="status.id" ></component>
    </bulmaModal>

  </div>
  
</template>

<script>
// @ is an alias to /src
// import numeral from 'numeral'
import apiclient from '@/apiclient.js'
import gnetMixLib from '@/mixin.js'

//import configfile from '@/config.js'

export default {
  name: 'Home',
  mixins: [gnetMixLib],
  components: {
  },
  props: {
    process: String,
    verb: String,
    recordid: String,
  },
  watch: { 
    '$route': async function() {
      console.log("watch routeChanged");
      console.log(this.$route.path);
      console.log(this.$route.params);

      if (!this.$store.state.myprofile) {
        let res=await this.$store.dispatch('myprofile');
        console.log('watch myprofile:', res.myprofile);
        this.$store.commit('SET_USER_ID', res.myprofile.user);
        console.log('set userid:', this.$store.state.userid);
        // eslint-disable-next-line no-undef
        if (OpenReplay) OpenReplay.setUserID(this.$store.state.userid);
      }

      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      if (this.process) this.$store.commit( 'SET_GLOBAL_STATE_PROCESS', this.process );
      if (this.verb) this.$store.commit( 'SET_GLOBAL_STATE_VERB', this.verb );

      this.setRecordState();

      if (this.recordid) {
        console.log('recordid is present', this.recordid);
        console.log('getRecordState', this.$store.getters.getRecordState);
        
        // TODO: quando va cambiato il record? se non cambia il recordid potrebbe cambiare il verbo, tipo un cancel su edit che va annullato
        this.$store.commit('SET_RECORDS_ID', { id: this.process, data: this.recordid });
        if (this.$store.getters.getRecordState=='TOLOAD' || !this.$store.getters.getRecordState) {
          await this.loadRecord();
          this.$store.commit('SET_RECORDS_STATE', { id: this.process, state:'READY' });
        }
      }
    },
    message: function(newVal) { // bus message
      if (newVal && newVal.message) this.doMessage(newVal);
    },
    broadcast_message: function(newVal) { // bus message
      if (newVal && newVal.message) {
        console.log('broadcast_message:', this.broadcast_message);
      }
    },
  },
  computed: {
    process_stack: function() {
      let ps=[]
      this.$store.state.process_stack.forEach( (p) => {
        let page=this.resolveProcessPage(p.processId, p.verbId);
        let obj=this.$store.getters.getObject(page.initialid);
        if (!obj) {
          console.error('object not found:', p.processId, p.verbId, page.initialid)
          return ps;
        }

        let entry={
          id: obj.id,
          type: obj.type,
          title: p.title
        }
        ps.push( entry );
      })
      return ps;
    },
    message: function() { // bus message
      return { message: this.$store.state.message, payload :this.$store.state.message_payload };
    },
    broadcast_message: function() { // bus message
      return { message: this.$store.state.broadcast_message, payload :this.$store.state.broadcast_message_payload };
    },
    pageid: function() {
      console.log('--> pageid (computed Home.vue)')
      let pconfig=this.$store.getters.getProcess(this.process);
      console.log("pconfig", pconfig)
      if (!pconfig || !pconfig.verbs) {
        this.$store.commit( 'SET_GLOBAL_STATE_PAGEID', "p404" );
        return "p404";
      }
      
      let vconfig=pconfig.verbs.find( (v) => { if (this.verb) return v.id==this.verb; else return v.id=="default"; } );
      console.log("vconfig", vconfig);
      if (!vconfig) {
        this.$store.commit( 'SET_GLOBAL_STATE_PAGEID', "p404" );
        return "p404";
      }

      this.$store.commit( 'SET_GLOBAL_STATE_PAGEID', vconfig.pageid );
      // console.log("pageid", vconfig.pageid);

      return vconfig.pageid;
    },
    pageconfig: function() {
      if (!this.pageid) {
        console.log("pageid null");
        return {   "id": "", "initialid": "" };
//        return this.$store.getters.getPage('home');
      }
      let pc=this.$store.getters.getPage(this.pageid);
      if (!pc) {
        console.log("pageconfig null");
        return this.$store.getters.getPage('home');
      }
      return pc;
    },
    rootobjectid: function() {
      return this.pageconfig.initialid;
    },
    rootobjecttype: function() {
      return this.$store.getters.getObject(this.rootobjectid).type;
    },
  },
  data: function() {
    return {
      ready: false,
      editmode:true,
      showheader: true,
      debug:false,
      lab:false,
    };
  },
  created: async function() {
    console.log('home created');
  },
  mounted: async function() {
    await this.$store.dispatch('getConfigFromDB');
    this.ready=true;

    if (this.debug) console.log('home mounted:', this.process, this.verb, this.recordid);   
    this.$store.commit( 'SET_GLOBAL_STATE_PROCESS', this.process );
    this.$store.commit( 'SET_GLOBAL_STATE_VERB', this.verb );
    if(this.process != undefined) {
      console.log('mounted');
      if (this.recordid) {
        this.$store.commit( 'SET_RECORDS_ID', { id: this.process, data: this.recordid });
        this.$store.commit( 'SET_RECORDS_STATE', { id: this.process, state:'TOLOAD' });
      }
    }

    await this.setRecordState();

    console.log('process is', this.process);
    console.log('recordid is', this.recordid);
    console.log('getRecordState', this.$store.getters.getRecordState);

    if (this.$store.getters.getRecordState=='TOLOAD' || (!this.$store.getters.getRecordState && this.recordid) ) {
      console.log('initial load');
      await this.loadRecord();
      console.log('loaded');
      if(this.process != undefined) this.$store.commit( 'SET_RECORDS_STATE', { id: this.process, state:'READY' });
    }
    // if (this.verb=='e') this.$store.commit( 'SET_RECORDS_STATE', { id: this.process, state:'EDIT' });
  },
  methods: {
    setRecordState: async function() {
      console.log('setRecordState');

      let oldstate=this.$store.getters.getRecordState
      console.log('oldstate:', oldstate);

      let vconfig='';
      if(this.process != undefined) {
        let pconfig=this.$store.getters.getProcess(this.process);
        if (pconfig && pconfig.verbs) {
          vconfig=pconfig.verbs.find( (v) => { if (this.verb) return v.id==this.verb; else return v.id=="default"; } );
        }
      }
      // console.log('vconfig!!',vconfig);

      let newstate=vconfig.state;
      console.log('newstate:',newstate);

      if (newstate=='NEW' && oldstate!='NEWDEFAULT') this.$store.commit( 'INIT_RECORDS_DATA', this.process );
      if (newstate=='EDIT') { 
        if (!oldstate || oldstate=='TOLOAD') {
          console.log('oldstate reloading:', oldstate);
          await this.loadRecord();
          console.log('oldstate reloaded:', oldstate);
        }
      }
      this.$store.commit( 'SET_RECORDS_STATE', { id: this.process, state: newstate });


      
      // TODO: depending on the verb, set state
      /*
      let pconfig=this.$store.getters.getProcess(this.process);
      if (pconfig && pconfig.verbs) {
        let vconfig=pconfig.verbs.find( (v) => { if (this.verb) return v.id==this.verb; else return v.id=="default"; } );
        if (vconfig) {
          console.log('vconfig!!',vconfig);
          
          if (vconfig.state)
            console.log('set state!!!');
            let oldstate=this.$store.getters.getRecordState
            console.log('oldstate:', oldstate);
            if (!oldstate) { // better to reload
              console.log('reloading');              
              await this.loadRecord();
              console.log('reloaded');              
            }
            if (oldstate!='NEWDEFAULT') {
              if (vconfig.state=='NEW') this.$store.commit( 'INIT_RECORDS_DATA', this.process );
            }
            if(this.process != undefined) {
              console.log('set state:', vconfig.state);
              this.$store.commit( 'SET_RECORDS_STATE', { id: this.process, state: vconfig.state });
            }
          }
      }
      */
    },
    resolveProcessPage: function(processId, verbId) {
      console.log('--> resolveProcessPage')
      let pconfig=this.$store.getters.getProcess(processId);
      console.log("pconfig", pconfig)
      if (!pconfig || !pconfig.verbs) {
        return "p404";
      }

      let vconfig=pconfig.verbs.find( (v) => { if (verbId) return v.id==verbId; else return v.id=="default"; } );
      console.log("vconfig", vconfig);
      if (!vconfig) {
        return "p404";
      }

      let pageid=vconfig.pageid;
      if (!pageid) {
        console.log("pageid null");
        return {   "id": "", "initialid": "" };
      }

      let pc=this.$store.getters.getPage(pageid);
      if (!pc) {
        console.log("pageconfig null");
        return this.$store.getters.getPage('home');
      }

      return pc;
    },
    routeChanged: function() {
      console.log("routeChanged");
      console.log(this.$route.path);
      console.log(this.$route.params);
    },
    doMessage: async function(message) { // bus message
      console.log('doMessage:',message.message);
      if (this.$store.getters.getRecordState=='TOLOAD') {
        await this.loadRecord();
      }

      switch (message.message) {
        /*
        case 'test_event': 
          let timestamp=Date.now();
          let eventTemplateId=1350905;
          let objectId=56601;
          let tokens={
            showroom_visit_date: Date.now(),
            showroom_visit_feedback: 5
          }
          apiclient.createcustomevent(this.$store.getters.connection_id, timestamp, eventTemplateId, objectId, tokens)
          break;
        */
        case 'new_record':
          this.$store.commit( 'SET_RECORDS_STATE', { id: this.process, state:'NEW' });
          this.$store.commit( 'INIT_RECORDS_DATA', this.process );
          if (message.payload)
            this.$router.push('/'+message.payload+'/n');
          else
            this.$router.push('/'+this.process+'/n');
          break;
        case 'edit_record':
          console.log('prima');
          await this.loadRecord();
          console.log('dopo');
          this.$store.commit( 'SET_RECORDS_STATE', { id: this.process, state:'EDIT' });
          this.$router.push('/'+this.process+'/e/'+this.recordid);
          break;
        case 'quick_save':
          console.log('quick save!');
          this.fillValid();
          var rid=await this.saveRecord();
          if (!rid)
            this.$router.push('/'+this.process+'/e/'+101);
          else
            this.loadRecord()
            this.$router.push('/'+this.process+'/e/'+rid).catch(err => {
              if ( err.name !== 'NavigationDuplicated' ) console.error(err);
            });
          break;
        case 'save_record':
          if (!this.isValid()) {
            alert('Required fields are missing');
            return;
          }
          var rid=await this.saveRecord();
          this.$store.commit( 'SET_RECORDS_STATE', { id: this.process, state:'READY' });
          if (!rid)
            this.$router.push('/'+this.process+'/d/'+101);
          else
            this.$router.push('/'+this.process+'/d/'+rid);
          break;
        case 'cancel_record':
          if (this.recordid) {
            await this.loadRecord();
            this.$store.commit( 'SET_RECORDS_STATE', { id: this.process, state:'READY' });
            this.$router.push('/'+this.process+'/d/'+this.recordid);
          }  else {
            this.$store.commit( 'SET_RECORDS_STATE', { id: this.process, state:'' });
            this.$router.push('/'+this.process);
          }
          break;
        case 'delete_record':
          // goto process list
          this.$router.push('/'+this.process);
          break;
        case 'goto_home':
          this.$router.push('/');
          break;
        case 'goto_tasks':
          this.$router.push('/tasks');
          break;
        case 'goto_search':
          // var fakedata={ id:"locate", data:[
          //   { Id:'0011', Name:'Ciao', Type:'Contact'},
          //   { Id:'0031', Name:'Ciaone', Type:'Contact'},
          //   { Id:'0011', Name:'Ciao', Type:'Contact'},
          //   { Id:'0031', Name:'Ciaone', Type:'Contact'},
          //   { Id:'0011', Name:'Ciao', Type:'Contact'},
          //   { Id:'0031', Name:'Ciaone', Type:'Contact'},
          //   { Id:'0011', Name:'Ciao', Type:'Contact'},
          //   { Id:'0031', Name:'Ciaone', Type:'Contact'},
          //   ]
          // };
          // this.$store.commit('SET_RECORDS_LIST', fakedata );
          this.$router.push('/locate');
          break;
        case 'locate_records':
          this.$store.commit('SET_RECORDS_LIST', {id: 'locate', data: [] } )
          this.locateRecords(message.payload)
          break;
        case 'activate_lab':
          var p={ id:'deals', type:'bulmaRows' };
          this.$store.commit('PUSH_PROCESS_STATUS', p);
          break;
        case 'activate_buddy':
          this.$router.push('/buddy');
        // var p={ id:'buddy', type:'bulmaBuddy' };
        //   this.$store.commit('PUSH_PROCESS_STATUS', p);
          break;
        case 'deactivate_lab':
          var p=this.$store.commit('POP_PROCESS_STATUS');
          console.log(p);
          break;
        case 'goto_back':
          this.$router.go(-1);
          break;
        default:
          console.log('unknown message:', message.message);
          break;
      }
      this.$store.commit( 'CLEAR_MESSAGE');
    },
    fillValid: function() {
      let pconfig=this.$store.getters.getProcess(this.process);
      if (!pconfig) return;
      if (!this.$store.state.database.has(pconfig.table)) return;

      let thetable=this.$store.state.database.get(pconfig.table);
      let r=this.$store.getters.getRecordData;

      thetable.forEach( f => {
        if (f.required && !r[f.name]) {
          this.$store.commit('SET_FIELD_VALUE', { fieldname: f.name, fieldvalue: f.label } );
        }
      });
    },
    isValid: function() {

      let invalid=false;

      let pconfig=this.$store.getters.getProcess(this.process);
      if (!pconfig) return;
      if (!this.$store.state.database.has(pconfig.table)) return;

      let thetable=this.$store.state.database.get(pconfig.table);
      let r=this.$store.getters.getRecordData;
      
      thetable.forEach( f => {
        if (f.required && !r[f.name]) {
          invalid=true;
        }
      });

      return !invalid;
    },
    loadRecord: async function() {
      console.log("loadRecord!", this.process);

      let pconfig=this.$store.getters.getProcess(this.process);
      if (!pconfig) { 
        console.log("pconfig null!");
        return;
      }
      if (!this.$store.state.database.has(pconfig.table)) {
        console.log("database null!");
        return;
      }
      console.log(pconfig);

      let thetable=this.$store.state.database.get(pconfig.table);
      console.log(thetable);
      
      let p=[]
      thetable.forEach( f => {
        p.push(f.name);
        if (f.type=='lookup') {
          p.push(f.related);
        }
      });

      this.$store.commit('SET_RECORDS_DATA', { id: this.process, data:{} });

      let assoc=['companies', 'contacts', 'company'];
      await this.$store.dispatch('retrieve_record', { id: this.process, object: pconfig.table, recordId: this.recordid, properties: p, associations: assoc })
      console.log("loadRecord done");
    },
    setFieldValue: function(obj, path, val) {
      let elements=path.split('.');
      let n_elements=elements.length;
      var last=obj;
      for (let i=0; i<(n_elements-1); i++) {
        if (!last[elements[i]]) last[elements[i]]={};
        last=last[elements[i]];
      }
      console.log(last)
      last[elements[n_elements-1]]=val;

      return obj;
    },
    createProperties: function(table, data) {
      let prop={};
      let thetable=this.$store.state.database.get(table);
      
      for (let p in data) {
        if (typeof data[p] != 'object') {
          let f=thetable.get(p);
          if (f && !f.readonly) {
            prop[p]=data[p];
          }
        }
      }
      return prop;
    },
    saveRecord: async function() {
      console.log("Home - saveRecord!");
      
      let pconfig=this.$store.getters.getProcess(this.process);
      let prop=this.createProperties(pconfig.table, this.$store.getters.getRecordData);
      console.log('prop', prop);
      
      if (!this.recordid) {
        await this.$store.dispatch('create_record', { object: pconfig.table, properties: prop });
        let r=this.$store.getters.getRecordData;
        this.recordid= this.getFieldValue(r, 'Id');

        return this.getFieldValue(r, 'Id');
      }

      await this.$store.dispatch('update_record', { object: pconfig.table, recordId: this.recordid, properties: prop });
      return this.recordid;
    },
    locateRecords: async function(string) {
      console.log('--> locateRecords: search '+string)
      var r = await this.$store.dispatch('locate_records', {querystring: string})
      console.log('resp', r)
      var recordslist = { input: string, records: r}
      this.$store.commit('SET_RECORDS_LIST', {id: 'locate', data: recordslist } )

      let m={ message: 'refresh'};
      this.$store.commit('BROADCAST_MESSAGE', m);
      this.$nextTick( () => {
        this.$store.commit('CLEAR_BROADCAST');
      })
    },
    manageModal: async function(e) {
      console.log('close event:', e)
      var modalButtons = this.$store.getters.getModalButtons

      if(modalButtons[e]) {
        console.log('clicked button',modalButtons[e])
        if (modalButtons[e].label == 'Save') { // Save

          let status=this.$store.getters.getProcessStatus;
          console.log('status',status)

          let state=this.$store.getters.getRecordState
          console.log('status', this.$store.getters.getRecordState, this.$store.state.records_state)

          if (state=="NEW") {
            console.log('NEW!!!');
            /*
            if (!this.$store.getters.getProcessDepth) {
              console.log('quick save on new:', this.$store.getProcessDepth);

              let m={ message: 'quick_save'};
              this.$store.commit('POST_MESSAGE', m);
            } else {
              console.log('no quick save on new:', this.$store.getProcessDepth);
            }
            */
            
            let pconfig=this.$store.getters.getProcess(status.processId);
            let dname = this.$store.getters.getRecordData.Name;
            let prop=this.createProperties(pconfig.table, this.$store.getters.getRecordData);
            let crecord=await this.$store.dispatch('create_record', { object: pconfig.table, properties: prop });
            console.log('crecord',crecord)

            let m={ message: 'refresh'};
            this.$store.commit('BROADCAST_MESSAGE', m);
            this.$nextTick( () => {
              this.$store.commit('CLEAR_BROADCAST');
            })
            
            this.$store.commit('POP_PROCESS_STATUS'); // remove the insert dialog

            status=this.$store.getters.getProcessStatus; // set the created record like a selected
            console.log('new: status is', status);
            console.log('new: status crecord is', crecord);
            if (status) {
              status.selected_id = crecord.data.id;
              status.selected_name = dname;
              if(!dname) status.selected_name = 'just inserted'
            }
          }

          if (state == 'EDIT') {
            console.log('EDIT!!!');

            let pconfig=this.$store.getters.getProcess(status.processId);
            let prop=this.createProperties(pconfig.table, this.$store.getters.getRecordData);
            await this.$store.dispatch('update_record', { object: pconfig.table, properties: prop, recordId: this.$store.getters.getRecordData.Id});
            
            let m={ message: 'refresh'};
            this.$store.commit('BROADCAST_MESSAGE', m);
            this.$nextTick( () => {
              this.$store.commit('CLEAR_BROADCAST');
            })
            
            this.$store.commit('POP_PROCESS_STATUS'); // remove the insert dialog
          }

          if (status && status.selected_id) {
            console.log('selected_id!!!');

            this.$store.commit('POP_PROCESS_STATUS');
            this.$store.commit('SET_FIELD_VALUE', { fieldname: status.fieldname, fieldvalue: status.selected_id } );
            this.$store.commit('SET_FIELD_VALUE', { fieldname: status.related_fieldname, fieldvalue: status.selected_name } );

            /*
            if (!this.$store.getters.getProcessDepth) {
              console.log('quick save on new:', this.$store.getProcessDepth);

              let m={ message: 'quick_save'};
              this.$store.commit('POST_MESSAGE', m);
            } else {
              console.log('no quick save on new:', this.$store.getProcessDepth);
            }
            */

          }
          return;
        }

        if(modalButtons[e].label == 'Submit' || modalButtons[e].label == 'Confirm') {
          console.log('fai submit')
          var process = this.$store.getters.getProcessStatus.processId
          var verb = this.$store.getters.getVerb( this.$store.getters.getProcess(process), 'a' )
          var objectData = this.$store.getters.getObject(verb.pageid).data

          let prop = {}
          this.$store.state.approvalInfo.forEach( p => { prop[p.field] = p.value })

          let resp = await this.$store.dispatch('update_record', { object: objectData.table, recordId: this.$store.getters.getRecordData.Id, properties: prop });

          if (resp) this.$store.state.approvalInfo.forEach( v => {this.$store.commit('SET_FIELD_VALUE', { fieldname: v.field, fieldvalue: v.value } ) })

        }

        if(modalButtons[e].label == 'Delete') {
          if(confirm('Do you really want to delete this record?')) {
            console.log('elimina',this.$store.getters.getRecordData)
            await this.$store.dispatch('delete_record', { object: this.$store.getters.getProcessStatus.table, recordId: this.$store.getters.getRecordData.Id });
          }
        }
      }

      let m={ message: 'refresh'};
      this.$store.commit('BROADCAST_MESSAGE', m);
      this.$nextTick( () => {
        this.$store.commit('CLEAR_BROADCAST');
      })

      this.$store.commit('POP_PROCESS_STATUS');

      var process = this.$store.getters.getProcessStatus
      var verb = this.$store.getters.getVerb( this.$store.getters.getProcess(process.processId), process.verbId )
      this.$store.commit('SET_RECORDS_STATE', {id: process.processId, state: verb.state});
      
      return;
      // da qui in base a 0 o 1 chiama goto_tasks e goto_search nella doMessage ????
      /*
      this.$store.commit('POP_PROCESS_STATUS');
      this.$store.commit('SET_RECORDS_STATE', 
        {
          id: this.$store.state.global_state_process,
          state: this.$store.getters.getVerb(this.$store.state.global_state_process, this.$store.state.global_state_verb)
        }
      );
      */
    }
  },
}
</script>
<style scoped>
</style>

