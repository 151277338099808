<template lang="html">
  
  <!--
  <nav class="level" v-bind:id="id">
    <div class="level-item level-right buttons" >
      <div v-for="(action, index) in config.actions" v-bind:key="action.name">
        <button class="button is-primary" v-on:click="doAction(action)" v-bind:class="{ 'is-outlined': !action.default}" v-bind:disabled="action.disabled">{{ $t(action.label) }}
          <span class="icon is-small" v-if="action.icon"><i class="fas fa-bold" v-bind:class="'fa-'+action.icon"></i></span>
        </button>
      </div>
    </div>
  </nav>
  -->
  <div>
    <div v-if="hasConfig()">
      <nav v-bind:id="id" >
        
        <div class="columns is-desktop">
          
          <!-- COLONNA SX -->
          <div class="column pb-0">
            <nav class="breadcrumb is-small has-arrow-separator">
              <ul>
                <li v-for="(step, index) in steps" :class="{ 'is-active': index == actualStepPosition }">
                  <a href="#" class="tag" :class="{ 'has-background-success': index == actualStepPosition}">{{ step.label }}</a>
                </li>
                <!-- <li v-for="(step, index) in steps" :class=" index <= actualStepPosition ? 'has-background-link': ''">
                  <span class="p-2" :class=" index <= actualStepPosition ? 'has-text-white has-text-weight-bold is-underlined':''">
                    {{ step.label }}
                  </span>
                </li> -->
              </ul>
            </nav>
          </div>
          
          <!-- COLONNA DX -->
          <div class="column is-4-desktop">
            <button class="button is-loading is-small" v-if="false && $store.state.busy" >Loading</button>
            
            <div class="buttons has-addons is-right are-small">
              
              <button v-for="(action, index) in buttons" v-bind:key="action.name" class="button " v-on:click="doAction(action)" v-bind:class="{ 'is-selected is-link': action.default}" v-bind:disabled="!isEnabled(action) && action.disabled">
                <span class="icon is-small" v-if="action.icon"><i class="fas" v-bind:class="'fa-'+action.icon"></i></span>
                <span>{{ $t(action.label) }}</span>
              </button>
              
            </div>
          </div>
        </div>
        
        <!-- PATH GESTITO CON CSS -->
        <!-- <div class="arrowBlock firstArrowBlock selected-block">Created</div>
        <div class="arrowBlock selected-block">Lead</div>
        <div class="arrowBlock selected-block">Pending</div>
        <div class="arrowBlock">Planning</div>
        <div class="arrowBlock">Bidding</div>
        <div class="arrowBlock">Delayed</div>
        <div class="arrowBlock">Contract Awarded</div>
        <div class="arrowBlock lastArrowBlock">Ordered</div>
        <div class="arrow selected-arrow"></div><div class="arrow selected-arrow"></div><div class="arrow selected-arrow"></div><div class="arrow"></div>
        <div class="arrow"></div><div class="arrow"></div><div class="arrow"></div> -->
        <!-- FINE PATH GESTITO CON CSS -->
        
        <!-- PATH CON BUTTONS -->
        <!-- <div class="field has-addons">
          <p class="control">
            <button class="button is-link is-selected is-small">Left</button>
          </p>
          <p class="control">
            <button class="button is-small is-link is-selected">Center</button>
          </p>
          <p class="control">
            <button class="button is-small">Right</button>
          </p>
        </div> -->
        <!-- FINE PATH CON BUTTONS -->
        
        <!-- <div class="level-item level-right" >
          <button class="button is-loading is-small" v-if="$store.state.busy" >Loading</button>
          
          <div class="buttons has-addons is-right are-small">
            
            <button v-for="(action, index) in buttons" v-bind:key="action.name" class="button " v-on:click="doAction(action)" v-bind:class="{ 'is-selected is-link': action.default}" v-bind:disabled="action.disabled">
              <span class="icon is-small" v-if="action.icon"><i class="fas" v-bind:class="'fa-'+action.icon"></i></span>
              <span>{{ $t(action.label) }}</span>
            </button>
            
          </div>
        </div> -->
      </nav>
      
      <!-- gestione props -->
      <div v-if="isAdmin()">
        <!-- Modale di configurazione -->
        <bulmaActionsProps v-if="showModal" 
        v-bind:id="id" 
        v-bind:config="config" 

        v-on:close="closeModal">
      </bulmaActionsProps>
      
      <!--modifica componente-->
     
      <button class="button" v-on:click="changeShowModal()" v-if="isAdmin() && getEditProps">
        <span class="icon is-small">
          <i class="fas fa-pen"></i>
        </span>
        <span>Actions</span>
      </button>
      
    </div>
    
  </div>
  <div v-if="!hasConfig() && isAdmin()">
    <button class="button" v-on:click="changeShowModal()">Initialise Actions</button>
    <bulmaActionsProps v-if="showModal" 
    v-bind:id="id" 
    v-bind:config="config" 
   
    v-on:close="closeModal">
  </bulmaActionsProps>
</div>
</div>  
</template>

<script>
import bulmaActionsProps from './bulma_actionsprops.vue';
export default {
  name: 'bulmaActions',
  components: {
    bulmaActionsProps
  },
  props: {
    id: String,
  },
  data: function () {
    return {
      showModal: false,
    }
  },
  watch: {
  },
  computed: {
    config: function () {
      return this.$store.getters.getObject(this.id).data;
    },
    buttons: function () {
      let b = [];
      this.config.actions.forEach((a) => {
        if (this.isValid(a)) {
          if (this.isValidSW(a)) {
            b.push(a)
          }
        }
      })
      return b;
    },
    tableName() {
      return this.$store.getters.getProcess(this.$store.state.global_state_process).table
    },
    actualStep() {
      if(this.config.steps_field && this.$store.getters.getRecordData) return this.$store.getters.getRecordData[this.config.steps_field]
      return ''
    },
    actualStepPosition() {
      for(var i in this.steps) {
        if(this.steps[i].value == this.actualStep) return i
      }
    },
    steps: function() {
      if (!this.$store.state.database.has(this.tableName)) return [];
      let table=this.$store.state.database.get(this.tableName);
      let solvedfields=[]
      if (table.has(this.config.steps_field)) {
        solvedfields.push(table.get(this.config.steps_field));
      }
      if(solvedfields.length > 0) {
        return solvedfields[0].options
      }
      return [];
    },
    getEditProps(){
      return this.$store.getters.getEditProps;
    },
   
  },
  mounted: function() {},
  filters: {
  },
  methods: {
    changeShowModal(){
      this.showModal=!this.showModal;
    },
    editmode() {
      //return true;
      return this.$store.getters.getEditMode(this.id);
    },
    hasConfig() {
      //return this.config && 'actions' in this.config;
      console.log(this.config, "has config")
      if(this.config){
        return true
      }else return false;
    },
    changeEditMode(){
      var newEditStatis = !this.$store.getters.getEditMode(this.id)
      var payload={ id: this.id, editmode: newEditStatis};
      this.$store.commit('SET_EDITMODE', payload);
    },
    async setActionsConfig(updatedConfig) {
      //const payload = { id: this.id, cfg: { id: this.id, type: this.type, data: updatedConfig } };
      //this.$store.commit('SET_CONFIG', payload);
      //await this.$store.dispatch('getConfigFromDB');
      this.showModal = false;
    },
    closeModal() {
      this.showModal = false;
      console.log("closeModal")
    },
    isValid: function (action) {
      if (!action.states) return true;
      let f = false
      action.states.forEach(s => {
        f = f || (s == this.$store.getters.getRecordState);
      });
      return f;
    },
    isValidSW: function (action) {
      if (!action.conditions) return true;
      let f = false
      action.conditions.forEach(s => {
        f = f || this.$store.getters.getSWState(s);
      });
      return f;
    },
    isEnabled: function (action) {
      if (!action.fieldname) return false;
      let r=this.$store.getters.getRecordData;
      return r[action.fieldname];
    },
    doAction: function(action) {
      console.log('doAction: ',action);
      if(action.approval_process) {

        var verb = this.$store.getters.getVerb( this.$store.getters.getProcess(action.approval_process), 'a' )

        var data = {
          processId: action.approval_process,
          verbId: "a",
          title: this.$store.getters.getObject(verb.pageid).data.title,
        }
        this.$store.commit('PUSH_PROCESS_STATUS', data);
        this.$store.commit('SET_RECORDS_STATE', {id: action.approval_process, state:'READY'});
        // this.$store.commit('SET_RECORDS_DATA', {id: action.label, data: this.$store.getters.getRecordData });
      } else {
        let m={ message: action.action, payload: this.id};
        this.$store.commit('POST_MESSAGE', m);
      }
    },
    isAdmin(){
      return this.$store.getters.isAdmin;
    },
  }
}
</script>

<style scoped>

/* CLASSI PER PATH - da finire di sistemare*/
.arrow {
  color: lightblue;
  border-left: 13px solid;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  width: 90px;
  left: -630px;
  position: relative;
} 

.selected-arrow {
  border-left: 13px solid #4258FF;
}

.arrowBlock {
  background-color: lightblue;
  width: 90px;
  height: 26px;
  text-align: center;
  border-top: solid black 1px;
  border-bottom: solid black 1px;
  
}

.selected-block {
  background-color: #4258FF;
  color: white;
}

.firstArrowBlock {
  border-radius: 20px 0 0 20px;
}

.lastArrowBlock {
  border-radius: 0 20px 20px 0;
}

</style>