<template>
  <bulmaModal
    v-bind:buttons="['Save', 'Cancel']"
    v-on:click="manageModal($event)"
    v-bind:title="'Set Tabs'"
  >
    <!-- Lista delle tabs su due colonne -->
    <div class="columns is-multiline">
      <div v-for="(tab, index) in toUpdate.tabs" :key="'tab-'+index" class="column is-3">
        <div class="box">
        <div class="columns">
          <div></div>
          <div class="clolumn">
            <div class="field is-narrow">
              <div class="label">
                <label>Name of Tab n. {{ index + 1 }}</label>
              </div>
            
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model="tab.name"
                  placeholder="Tab Name"
                />
              </div>
            </div>
          </div>
          <div class="column">
            <!-- bottone elimina -->
            <button class="button is-small  mt-5" v-on:click="removeTab(index)" v-if="toUpdate.tabs.length>1">
              <span class="icon is-small">
                <i class="fas fa-trash"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
        
        
      </div>
    </div>

    <!-- Bottoni per aggiungere o rimuovere tab -->
    <div class="buttons">
      <button class="button is-primary" @click="addTab('')">Add Tab</button>
      <button class="button is-danger" @click="removeLastTab(toUpdate.tabs.length-1)" :disabled="toUpdate.tabs.length <= 1">Remove Tab</button>
    </div>

    <div class="columns is-horizontal box">
      <div class="column is-3">
        <!-- Selezione del tab attivo -->
         
        <label class="label">Name of Active Tab</label>
        <div class="control">
          <div class="select">
            <select v-model="toUpdate.active_tab">
              <option v-for="(tab, tabIndex) in toUpdate.tabs" :value="tab.name" :key="tabIndex">
                {{ tab.name }}
              </option>
            </select>
          </div>
        </div>
   
      </div>
      <div class="column">
        <!-- Selezione del tab attivo di default -->
        <label class="label">Default active tab name</label>
        <div class="control">
          <div class="select">
            <select v-model="toUpdate.default_active_tab">
              <option v-for="(tab, tabIndex) in toUpdate.tabs" :key="tabIndex" :value="tab.name" >
                {{ tab.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
      

   

  </bulmaModal>
</template>

<script>
export default {
  name: 'bulmaSubTabProps',
  props: {
    id: {
      type: String,
      required: true
    },
    config: {
      type: Object,
      required: false,
      default: () => ({
        tabsno: 1,
        tabs: [{ name: '', type: 'bulmaEmpty', id: '' }],
        active_tab: '',
        default_active_tab: ''
      })
    },
    editmode: {
      type: Boolean,
      default: false
    },
    closemodal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      toUpdate: {
        tabsno: 1,
        tabs: [{ name: 'Tab 1', type: 'bulmaEmpty', id: '' }],
        active_tab: '',
        default_active_tab: ''
      }
    };
  },
  created() {
    // Carica `config` dalla prop solo se non è vuoto
    if (this.config && this.config.tabs) {
      this.toUpdate = JSON.parse(JSON.stringify(this.config)); // Deep copy per evitare mutazioni accidentali
    }
    if(this.config && this.config.tabs && this.config.tabs.length<=0) this.toUpdate.tabs=[{ name: '', type: 'bulmaEmpty', id: '' }];
  },
  methods: {
    // setTabs(tabCount) {
    //   tabCount = parseInt(tabCount) || 1;
    //   if (tabCount > 12) tabCount = 12;
    //   if (tabCount < 1) tabCount = 1;

    //   if (tabCount > this.toUpdate.tabs.length) {
    //     for (let i = this.toUpdate.tabs.length; i < tabCount; i++) {
    //       this.toUpdate.tabs.push({ name: '', type: 'bulmaEmpty', id: '' });
    //     }
    //   } else if (tabCount < this.toUpdate.tabs.length) {
    //     this.toUpdate.tabs.splice(tabCount);
    //   }

    //   this.toUpdate.tabsno = tabCount;
    // },

    addTab(id) {
      if(!id) id="";

      if (this.toUpdate.tabs.length < 12) {
        this.toUpdate.tabs.push({ name: id, type: 'bulmaEmpty', id:  ''});
      }
    },

    removeLastTab() {
      if (this.toUpdate.tabs.length > 1) {
        this.toUpdate.tabs.pop();
      }
    },

    async manageModal(buttonIndex) {
      if (buttonIndex === 1 || buttonIndex === -1) {
        this.$emit('close');
        return ;
      } 
      console.log(this.toUpdate)
      // if(this.config){
      //   console.log("dentro")
      //   for(let i = 0; i<this.config.tabs.length; i++){
      //     console.log("dentro al ciclo")
      //     console.log(this.toUpdate.tabs)
      //     this.toUpdate.tabs[i].id = this.config.tabs[i].id;
      //     this.toUpdate.tabs[i].type = this.config.tabs[i].type;
      //   }
      // }
      await this.$store.dispatch('setObjectDB', { id: this.id, data: this.toUpdate });
      // console.log("new props for subtab: ", props);
      this.$store.commit('SET_CONFIG_OBJECT', { id: this.id, type: "bulmaSubTab", data: this.toUpdate });
          
      
      this.$emit('close');
      
    },
    // async removeTab(index){
    //   this.toUpdate.tabs.splice(index, 1);

    //   console.log(this.toUpdate.tabs, " righe aggiornate");


    //   const props = { 
    //     data: { 
    //         tabs: this.toUpdate.tabs,
    //         active_tab: this.toUpdate.active_tab,
    //         default_active_tab: this.toUpdate.default_active_tab
    //      },
   
    //   };
				
				
    //   // Dispatch actions to update the store and fetch the new config
    //   await this.$store.dispatch('sendObjectProps', { id: this.id, props });
    //   await this.$store.dispatch('getConfigFromDB');
    // }
    async removeTab(index){
      console.log(this.toUpdate.tabs, " tabs test bug")
      const id = this.toUpdate.tabs[index].id;
      //rimuovo row richiesta
      this.toUpdate.tabs.splice(index, 1);
      //this.toUpdate.tabs = this.toUpdate.tabs.filter(cell => cell.id !== id);
      // var props={
      //   data:{
      //     tabs: this.toUpdate.tabs
      //   }
      // }
  
      //await this.$store.dispatch('setObjectDB', {id: this.id, data: this.toUpdate});


      //richiedo la config per dare ad isUsed la nuova config
      // await this.$store.dispatch('getConfigFromDB');
      // //controllo se non è usato
      // console.log(await this.$store.getters.isUsed(id || ""), "   IS IN CONFIGUTATION?");
      // if(!this.$store.getters.getObject(id)){
      //   if(!await this.$store.getters.isUsed(id)){
      //     // se non usato elimino
      //     await this.$store.dispatch('deleteObject', id);
      //   }else console.log("componente ancora usato, non eliminato")

      //   //aggiorno visivamente la config
      //   await this.$store.dispatch('getConfigFromDB');
      // }
      
    }
  }
};
</script>

<style scoped>
</style>
