<template lang="html">
    <article class="message" v-bind:id="id">

        <div class="block" v-if="this.config">

            <!-- <div class="message-header"> <p>{{ config.title }}</p> <button
            v-on:click="loadMap()">Update Map</button> </div> -->

            <div class="message-body">
                <div id="mymap" style="height: 600px;"></div>
            </div>
         
            <button class="button mt-4" v-on:click="changeShowModal()" v-if="isAdmin() && getEditProps"> <!-- v-if="editprop" -->
                <span class="icon">
                    <i class="fas fa-pen"></i>
                </span>
                <span>Map</span>
            </button>
        </div>
        <div> 

            <button class="button" v-on:click="changeShowModal()" v-if="!this.config && isAdmin() && getEditProps"> initialise map </button>
            <bulmamapprops
                v-if="showModal && isAdmin() && getEditProps"
                v-bind:id="id"
                v-bind:config="config"
                v-on:change="updateData"
                v-on:cancel="closeModal"
               
                >
                
            </bulmamapprops>

        </div>

    </article>
</template>

<script>
    import {Loader} from "@googlemaps/js-api-loader"
    import bulmamapprops from '../components/bulma_mapprops.vue'

    export default {
        name: 'bulmaMap',
        components: {
            bulmamapprops
        },
        computed: {
            config: function () {
                return this.$store.getters.getObject(this.id).data;
            },
            busy: function () {
                return this.$store.state.busy;
            },
            latitude: function () {
                if (!this.config || !this.config.latitude) 
                    return 0;
                let r = this.$store.getters.getRecordData;
                return this.getFieldValue(r, this.config.latitude);
            },
            longitude: function () {
                if (!this.config || !this.config.longitude) 
                    return 0;
                let r = this.$store.getters.getRecordData;
                return this.getFieldValue(r, this.config.longitude);
            },
            getEditProps(){
                return this.$store.getters.getEditProps;
            },
        },
        watch: {
            config: function (newVal) {
                if (newVal && this.loader) 
                    this.loadMap();
            },
            
            latitude: function (newVal) {
                if (this.latitude && this.longitude) {
                    console.log('coord1:', this.latitude, this.longitude);

                    this.map.setCenter(new google.maps.LatLng(this.latitude, this.longitude));
                    this.setMarker();
                }
            },
            longitude: function (newVal) {
                if (this.latitude && this.longitude) {
                    console.log('coord2:', this.latitude, this.longitude);

                    this.map.setCenter(new google.maps.LatLng(this.latitude, this.longitude));
                    this.setMarker();
                }
            }
        },
        props: {
            id: String
        },
        data() {
            return {
                loader: {}, 
                map: {}, 
                marker: undefined,
                showModal: false
            }
        },
        mounted: async function () {
            if (this.config) {
                this.loader = await this.$store.dispatch('googleMapLoader', {
                        loader: Loader,
                        apiKey: this.config.apiKey
                    })
                console.log('loader map!', this.loader);

                this.$nextTick(() => {
                    this.loadMap();
                })
            }else console.log("tester")
        },
        async created() {},
        methods: {
            getFieldValue: function (obj, path) {
                // eslint-disable-next-line no-redeclare
                for (var i = 0, path = path.split('.'), len = path.length; i < len; i++) {
                    if (obj) 
                        obj = obj[path[i]];
                    }
                return obj;
            },
            setMarker: async function () {
                // eslint-disable-next-line no-undef
                const {AdvancedMarkerElement} = await google.maps.importLibrary("marker");
                this.marker = new AdvancedMarkerElement({
                    map: this.map,
                    position: new google.maps.LatLng(this.latitude, this.longitude),
                    title: 'the project'
                });
            },
            loadMap: function () {
                this.loader.load().then(async () => {
                        // eslint-disable-next-line no-undef
                        var geocoder = new google
                            .maps
                            .Geocoder();
                        // eslint-disable-next-line no-undef
                        const {Map} = await google
                            .maps
                            .importLibrary("maps");

                        // if (this.latitude && this.longitude) {
                        this.map = new Map(document.getElementById("mymap"), {
                            center: {
                                lat: this.latitude,
                                lng: this.longitude
                            },
                            zoom: 15,
                            mapId: "FTI_Project"
                        });

                        this.setMarker();
                        /*
          } else {
            this.map = new Map(document.getElementById("mymap"), {
              mapId: "FTI_Project",
            });
          }
            */
                        /*

          let addr='';
          if (this.config.street_fieldname) addr+=this.getFieldValue(r, this.config.street_fieldname)+' ';
          if (this.config.city_fieldname) addr+=this.getFieldValue(r, this.config.city_fieldname)+' ';
          if (this.config.state_fieldname) addr+=this.getFieldValue(r, this.config.state_fieldname)+' ';
          console.log(addr);

          var self=this;
          geocoder.geocode({'address': addr }, (results, status) => {
            console.log('results', results);

            if (status === 'OK') {
              console.log('results location', results[0].geometry.location);
              self.map.setCenter(results[0].geometry.location);

              const projectTag = document.createElement("button");
              projectTag.className = "button";
              projectTag.textContent = addr;
              projectTag.title ='ciaone'

              setTimeout( () => {

                // const marker2 = new AdvancedMarkerElement({
                  // map: self.map,
                  // position: results[0].geometry.location,
                  // content: projectTag,
                  // content: pinTextGlyph.element,
                  // title: addr,
                // });

                const marker = new AdvancedMarkerElement({
                  map: self.map,
                  position: results[0].geometry.location,
                  title: addr,
                });

                // const pinTextGlyph = new PinElement({
                //   glyph: addr,
                //   glyphColor: "white",
                // });
              },1000)

            } else {
              alert('Geocode was not successful for the following reason: ' + status);
            }
          });
        */
                    });

            },
            closeModal(){
                this.showModal=false;
            },
            updateData(data){
                console.log(data)
                console.log(this.config, "config");
                
                this.$store.commit('SET_CONFIG_OBJECT', data );

                this.showModal=false;
            },
          
            changeShowModal(){
                this.showModal = !this.showModal;
            },
            isAdmin(){
                return this.$store.getters.isAdmin;
            },
        }
    }
</script>

<style scoped="scoped"></style>