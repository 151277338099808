<template lang="html">
    <div> 
      <div v-if="hasConfig()">
        <div v-bind:id="id" style="width: 100%; height: 20em;">
        </div>
      </div>
      
      <bulmaGraphProps 
        v-bind:id="id" 
        v-bind:config="config" 
        v-if="showModal && isAdmin() && getEditProps"
        v-on:close="closeModal"
        v-on:update="updateGraph"
      ></bulmaGraphProps>
      
      <button v-if="isAdmin() && getEditProps" class="button" v-on:click="showModal=!showModal">
        <span class="icon is-small">
          <i class="fas fa-pen"></i>
        </span>
        <span>Graph</span>
      </button>
    </div>
   
  
  
</template>

<script>
import * as echarts from 'echarts';
import bulmaGraphProps from './bulma_graphprops.vue';
export default {
  name: 'bulmaGraph',
  components: {
    bulmaGraphProps
  },
  computed: {
    config: function() {
      return this.$store.getters.getObject(this.id).data;
    },
    getEditProps(){
      return this.$store.getters.getEditProps;
    },
    
  },
  props: {
    id: String,
  },
  data() {
    return {
      showModal: false
    }
  },
  mounted: function() {
    if(this.hasConfig()){
      var myChart = echarts.init(document.getElementById(this.id));
      myChart.setOption(this.config);
    }
  },
  async created() {
  },
  methods: {
    hasConfig(){
      //return this.config && 'actions' in this.config;
      if(this.config){
        return true
      }else return false;
    },
    closeModal(){
      this.showModal = false; 
     
    },
    updateGraph(){
      console.log("--> updateGraph")
      let m={ message: 'refresh'};
      this.$store.commit('BROADCAST_MESSAGE', m);
      this.showModal=false;
    },
    isAdmin(){
      return this.$store.getters.isAdmin;
    },
  }
}
</script>

<style scoped>
</style>
