<template>
    <bulmaModal v-bind:buttons="['Save', 'Cancel']" v-on:click="manageModal($event)" v-bind:title="'Set Simple Table'">
        
        

        <div class="columns">
            <div class="column is-one-third is-narrow">
                <div class="field">
                    <label class="label">Data Id:</label>
                    <div class="control">
                        <input class="input " type="text" v-model="toUpdate.dataid" placeholder="Section Title">
                    </div>
                </div>
            </div>

            <div class="column is-one-third is-narrow">
                <div class="field">
                    <label class="label">Title:</label>
                    <div class="control">
                        <input class="input " type="text" v-model="toUpdate.title" placeholder="Section Title">
                    </div>
                </div>
            </div>
            <div class="column is-one-third is-narrow">
                <div class="field">
                    <label class="label">Number of cols:</label>
                    <div class="control">
                        <input class="input " type="text" v-model="length" placeholder="Cols Number" v-on:change="setCols()">
                    </div>
                </div>
            </div>
        </div>
        <div class="box" v-for="(col, index) in toUpdate.cols" :key="index">
            <label class="label">Col N.{{ index + 1 }}:</label>
            <div class="columns is-multiline">
                <div class="column is-4 is-narrows">
                    <label class="label">ID:</label>
                    <div class="control">
                        <input class="input " type="text" v-model="col.id" placeholder="Id of the Column">
                    </div>
                </div>
                <div class="column is-4 is-narrows">
                    <label class="label">Label:</label>
                    <div class="control">
                        <input class="input " type="text" v-model="col.label" placeholder="Label of the Column">
                    </div>
                </div>


         
                <div class="column is-4 is-narrows">
                    <label class="label">Field Name:</label>
                    <div class="control">
                        <input class="input " type="text" v-model="col.fieldname" placeholder="Name of the Field of the Column">
                    </div>
                </div>
                <div class="column is-4 is-narrows">
                    <label class="label">Format:</label>
                    <div class="control">
                        <input class="input " type="text" v-model="col.format" placeholder="Format of the Column">
                    </div>
                </div>
        

          
                <div class="column is-4 is-narrows">
                    <label class="label">Field Name Link:</label>
                    <div class="control">
                        <input class="input " type="text" v-model="col.link_fieldname" placeholder="Link connected to field name">
                    </div>
                </div>
            </div>
        </div>


       
            

        
    </bulmaModal>
</template>


<script>
export default {
    name: 'simpleTableProps',
    props: {
        config: {
            type: Object,
            required: false
        },
        id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            // Initialize localConfig based on config or default values if config is empty
            toUpdate: {
                dataid: this.config && this.config.dataid ? this.config.dataid : '',
                title: this.config && this.config.title ? this.config.title : '',
                cols: this.config && this.config.cols && this.config.cols.length > 0 ?
                    this.config.cols.map(action => ({ ...action })) : [{ id: '', label: '', fieldname: '', format: '', link_fieldname: '' }]
            },
            length: this.config && this.config.cols && this.config.cols.length > 0 ? this.config.cols.length : 1
            
        };
    },
    mounted: async function(){
        if(!this.config){
            this.toUpdate = {
                dataid: this.config && this.config.dataid ? this.config.dataid : '',
                title: this.config && this.config.title ? this.config.title : '',
                cols: this.config && this.config.cols && this.config.cols.length > 0 ?
                    this.config.cols.map(action => ({ ...action })) : [{ id: '', label: '', fieldname: '', format: '', link_fieldname: '' }]
            }
            this.length = this.config && this.config.cols && this.config.cols.length > 0 ? this.config.cols.length : 1
           
        } else console.log("config is existing")
        
    },
    methods: {
        // Modifica il numero di azioni
        setCols() {
            this.length = parseInt(this.length) || 1;
            
            // Limita il numero di azioni
            //if (this.length > 10) this.length = 10;
            if (this.length < 1) this.length = 1;
            
            // Aggiusta il numero di azioni
            if (this.length > this.toUpdate.cols.length) {
                for (let i = this.toUpdate.cols.length; i < this.length; i++) {
                    console.log("setCols, simple table props: ", this.toUpdate)
                    this.toUpdate.cols.push({
                        id:'',
                        label:'',
                        fieldname:'',
                        format:'',
                        link_fieldname:'',
                    });
                }
            } else if (this.length < this.toUpdate.cols.length) {
                this.toUpdate.cols.splice(this.length);
            }
            
            this.length = this.length;
            console.log(this.length)
        },
        
        // Gestisci la chiusura del modale
        manageModal(buttonIndex) {
            if (buttonIndex === 1 || buttonIndex === -1) {
                this.$emit('close'); // Annulla le modifiche
                return;
            } 
            
            
            const IDs = this.toUpdate.cols.map(obj => obj.id);
            const uniqueIDs = new Set(IDs);
            
            
            if (IDs.length!==uniqueIDs.size) {
                alert("all IDs must be different");
                return;
            }
            
            // Salva le modifiche
            const data = {
                title: this.toUpdate.title,
                cols: this.toUpdate.cols
            };
            
            this.$store.dispatch('setObjectDB', { id: this.id, data: data });
            console.log("new props for cols: ", this.toUpdate);
            //this.$emit('change', this.toUpdate); // Emit updated localConfig
            
            
            
            
            this.$store.commit('SET_CONFIG_OBJECT', { id: this.id, type: "bulmacols", data: this.toUpdate });
            this.$emit('close');
            
        }
    }
};
</script>
<style>
.checkbox-container input[type="checkbox"] {
    transform: scale(2.1); /* Aumenta la dimensione della checkbox */
    margin-right: 10px; /* Spazio tra checkbox e il testo */
}
/* @media screen and (max-width: 768px) {
    .field.is-horizontal-mobile {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    
} */
</style>



<!--

"data": {
    "dataid": "locate",
    "title": "Search Results",
    "cols":[
        { "id":"id", "label":"Key", "fieldname":"Id", "format": "hidden", },
        { "id":"name", "label":"Name", "fieldname":"Name", "format": "link", "link_fieldname": "Id", },
        { "id":"type", "label":"Type", "fieldname":"Type", "format": "text",  },
    ],
}



-->