<template lang="html">

  <!--

  <div v-bind:id="id" >

    <component class="block" v-if="config.actionid" v-bind:is="'bulmaActions'" v-bind:id="config.actionid"></component>

    <nav class="panel" v-for="(r, index) in config.rows" v-bind:key="r.id">

      <p class="panel-heading" v-if="r.title" style="padding: 0.5em; font-size: 1em;">
        <button v-on:click="tabClick(index, !r.opened)">
          <span class="icon">
            <i v-if="r.opened" class="fas fa-chevron-down"></i>
            <i v-if="!r.opened" class="fas fa-chevron-left"></i>
          </span>
        </button>
        {{ r.title }}
      </p>

      <div class="panel-block" v-if="r.opened">
        <component v-bind:is="r.type" v-bind:id="r.id"></component>
      </div>
      
    </nav>

    <component class="block" v-if="config.actionid" v-bind:is="'bulmaActions'" v-bind:id="config.actionid"></component>

  </div>
  -->

  <div>
    <!-- <bulmaSectionsProps 
      v-if="showModal"
      v-bind:data="config"
      v-bind:id="id"
      v-on:close="closeModal"
    ></bulmaSectionsProps> -->
    <article class="message" v-bind:id="id" v-if="this.config">
      
      <!-- <button class="button" v-on:click="showModal=!showModal">show section props</button> -->
      <component class="block" v-if="config.actionid" v-bind:is="'bulmaActions'" v-bind:id="config.actionid"></component>

      <div v-for="(r, index) in config.rows" v-bind:key="r.id" v-bind:class="{'block': index==(config.rows.length-1) }" >
        
      
        <div v-if="r.id || r.type">
          <div class="message-header" v-if="r.title">
            <p>{{ r.title }}</p>

            <button v-on:click="tabClick(index, !r.opened)">
              <span class="icon">
                <i v-if="r.opened" class="fas fa-chevron-down"></i>
                <i v-if="!r.opened" class="fas fa-chevron-left"></i>
              </span>
            </button>
          </div>

          <div class="message-body" v-if="r.opened" v-bind:style="{'border-left': !r.title?'0px':'', 'padding-top': !r.title?'0px':''}">
            <component v-bind:is="r.type" v-bind:id="r.id"></component>
            <creator v-bind:index="index" v-bind:editButton="true" @create-component="addComponent"></creator>
          </div>
        </div>
        <!-- v-if="!r.id && !r.type" -->
          
          <!-- v-if="r.type && isAdmin() && getEditProps" --> <!-- v-bind:oldid="r.id" v-bind:fatherId="id" -->
      
          

      </div>

        <component class="block" v-if="config.actionid" v-bind:is="'bulmaActions'" v-bind:id="config.actionid"></component>

    </article>
    <div v-if="!this.config">
      <button class="button" v-on:click="showModal=!showModal">
        initialize section
      </button>
    </div>
  </div>
</template>

<script>
import bulmaSectionsProps from '../components/bulma_sectionsprops.vue'
import creator from './creator.vue'
export default {
  name: 'bulmaSections',
  components: {
    bulmaSectionsProps,
    creator

  },
  computed: {
    config: function() {
      return this.$store.getters.getObject(this.id).data;
    },
  },
  props: {
    id: String,
  },
  data() {
    return {
      showModal: false,
    }
  },
  mounted: function() {
  },
  async created() {
  },
  methods: {
    tabClick: function(tabindex, opened) {
      var c=this.config;
      c.rows[tabindex].opened=opened;
      var payload={ id:this.id, cfg:{ id:this.id, type:this.type, data:c} }      
      this.$store.commit('SET_CONFIG', payload);
    },
    closeModal(){
      this.showModal = false;
    },
    async addComponent(data) {
      
      if (!data.component) {
        console.warn("addComponent: 'component' è undefined o nullo");
        return;
      }
      
      
      // Trova l'indice dell'elemento da rimuovere solo se `component.oldid` esiste
      //const index = data.oldid ? this.config.rows.findIndex(item => item.id === data.oldid) : -1;
      // if(data.index >=0){
      //   console.log("sono giusto", this.config.rows);
      //   data.component.title = this.config.rows[data.index].title;
      //   data.component.opened = this.config.rows[data.index].opened;
      // }
      
      //cerco vecchio componente

      let localRows;
      if (data.index>=0) {

        localRows = this.config.rows.filter((_, index) => index !== data.index);
      } else {
      
        localRows = [...this.config.rows];
      }
      
      
      //let savedNotes=component.notes;
      
      //inserisco nuovo componente
      console.log("row component", data);
      
      if (data.index >=0) {
        console.log("sono giusto", this.config.rows);
        data.component.title = this.config.rows[data.index].title;
        data.component.opened = this.config.rows[data.index].opened;
        localRows.splice(data.index, 0, data.component);  
      } else {
        data.component.title= "new section";
        data.component.opened="false";
        localRows.push(data.component); 
      }
      
      
      const props= { 
        
        rows:localRows 
      };
      
        
      
      
      console.log("new props: ", data.component);
      await this.$store.dispatch('createObject', { id: data.component.id, type: data.component.type});
      
      console.log("new rows", localRows);
      await this.$store.dispatch('setObjectDB', { id: this.id, data: props });
      
      let cfg = this.config;
      cfg.rows = localRows;
      
      
      // nuovo oggetto
      this.$store.commit('SET_CONFIG_OBJECT', { id: data.component.id, type: data.component.type });
      
      // modifica bulmaRows
      this.$store.commit('SET_CONFIG_OBJECT', { id: this.id, type: "bulmaRows", data: cfg });
    },
    isAdmin(){
      return this.$store.getters.isAdmin;
    },
    switchEditMode(){
      var newEditStatis = !this.$store.getters.getEditMode(this.id)
      var payload={ id: this.id, editmode: newEditStatis};
      this.$store.commit('SET_EDITMODE', payload);
    },
    getEditProps(){
      return this.$store.getters.getEditProps;
    },
  }
}
</script>

<style scoped>
</style>
