<template>
  <bulmaModal
  :buttons="['Save', 'Cancel']"
  @click="manageModal($event)"
  :title="'Configura Sezioni'"
  >
  <!-- Titolo principale -->
  <div class="field">
    <label class="label ">Title</label>
    <input class="input " type="text" v-model="toUpdate.title" placeholder="Section Title" />
  </div>
  
  <!-- Action ID -->
  <div class="field">
    <label class="label ">Action ID</label>
    <input class="input " type="text" v-model="toUpdate.actionid" placeholder="Action ID" />
  </div>
  
  <!-- Numero di sezioni -->
  <div class="field">
    <label class="label ">Number of Sections</label>
    <input class="input " type="number" v-model.number="rowsno" :placeholder="rowsno || 'example: 3'" @change="setRows(rowsno)" />
  </div>
  
  <!-- Lista delle sezioni -->
  <div class="columns is-multiline">
    <div v-for="(row, index) in toUpdate.rows" :key="index" class="column is-full">
      <div class="field is-flex is-align-items-center">
        <!-- Label Sezione -->
        <label class="label  mr-3" style="white-space: nowrap; min-width: 120px; text-align: right;">
          Section N. {{ index + 1 }}
        </label>
        
        <!-- Input per il titolo -->
        <input class="input  ml-3 is-flex-grow-1" type="text" v-model="row.title" placeholder="Sections title" />
        
        <!-- Checkbox e bottone rimuovi -->
        <label class="checkbox  ml-3">
          <input type="checkbox" v-model="row.opened" /> Open
        </label>
        
        <button class="button  is-light ml-3" @click="removeSection(index)">
          <span class="icon ">
            <i class="fas fa-trash"></i>
          </span>
        </button>
      </div>
    </div>
  </div>
</bulmaModal>
</template>









<script>
export default {
  name: 'bulmaSectionsProps',
  props: {
    data: {
      type: Object,
      required: false,
    },
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      toUpdate: {
        title: this.data && this.data.title ? this.data.title : '',
        actionid: this.data && this.data.actionid? this.data.actionid : '',
        rows: this.data && this.data.rows ? this.data.rows : []
      },
      rowsno: this.data && this.data.rows && this.data.rows.length ? this.data.rows.length : 1
    };
  },
  methods: {
    // Modifica il numero di sezioni
    setRows(rowCount) {
      rowCount = parseInt(rowCount) || 1;
      
      // Limita il numero di sezioni tra 1 e 12
      //if (rowCount > 12) rowCount = 12;
      if (rowCount < 1) rowCount = 1;
      
      // Aggiusta il numero di sezioni
      if (rowCount > this.toUpdate.rows.length) {
        for (let i = this.toUpdate.rows.length; i < rowCount; i++) { //
          this.toUpdate.rows.push({ title: 'New Section', type: 'bulmaEmpty'});
        }
      } else if (rowCount < this.toUpdate.rows.length) {
        this.toUpdate.rows.splice(rowCount);
      }
      
      this.rowsno = rowCount;
    },
    
    // Gestisci la chiusura del modale
    async manageModal(buttonIndex) {
      //console.log(" entro manage modal")
      if (buttonIndex === 1 || buttonIndex === -1) {
        //console.log("chiudo tutto")
        this.$emit("close"); // Annulla le modifiche
        return 0;
      } 
      //console.log("sono passato");
      this.$store.commit('SET_CONFIG_OBJECT', { id: this.id, type: "bulmaSections", data: this.toUpdate });
      //console.log("111");
      this.$store.dispatch("setObjectDB", { id: this.id, data: this.toUpdate });
      //console.log("222");
      // Salva le modifiche
      this.$emit("close");
    
    },
    //async 
    removeSection(index){
      this.toUpdate.rows.splice(index, 1);
    },
    oldRemoveSection(index){
      console.log(this.toUpdate.rows, " tabs test bug")
      const id = this.toUpdate.rows[index].id;
      
      
      this.toUpdate.rows = this.toUpdate.rows.filter(row => row.id !== id);
      // var props={
      //   data:{
      //     rows: this.toUpdate.rows
      //   }
      // }
      console.log(this.toUpdate.rows + " righe aggiornate");
      //await this.$store.dispatch('sendObjectProps', {id: this.id, props: props});


      // //richiedo la config per dare ad isUsed la nuova config
      // await this.$store.dispatch('getConfigFromDB');
      // //controllo se non è usato
      // console.log(await this.$store.getters.isUsed(id || ""), "   IS IN CONFIGUTATION?");

      // if(!await this.$store.getters.isUsed(id || "")){
      //   // se non usato elimino
      //   await this.$store.dispatch('deleteObject', id);
      // }else console.log("componente ancora usato, non eliminato")

      //aggiorno visivamente la config
      //await this.$store.dispatch('getConfigFromDB');
    }

  },
  watch: {
    data: {
      handler(newVal) {
        this.toUpdate = {
          title: newVal?.title || '',
          actionid: newVal?.actionid || '',
          rows: newVal?.rows || []
        };
      },
      deep: true,
      immediate: true
    }
  }
  
};
</script>

