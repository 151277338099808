<template lang="html">
  <div>
    
  </div>
</template>

<script>

export default {
  name: 'bulmaEmpty',
  components: {
  },
  computed: {
    config: function() {
      return this.$store.getters.getObject(this.id).data;
    },
  },
  props: {
    id: String,
  },
  data() {
    return {
    }
  },
  mounted: function() {
  },
  async created() {
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
