<template lang="html">

  <nav class="navbar is-fixed-bottom is-tab" role="navigation" aria-label="bottom navigation" v-bind:id="id" >

    <div class="tabs is-centered is-fullwidth is-toggle" style="flex: auto;">
      <ul>
        <li v-for="(tab, index) in config.tabs" v-bind:key="tab.order" v-bind:class="{ 'is-active': tab.name==config.active_tab }" v-on:click="tabClick(tab)">
          <a class="is-size-6 is-flex-direction-column" v-bind:class="{'itemdisabled': !tab.action}">
            <span v-if="editmode">{{ index }}:</span>
            <span class="icon" v-if="tab.icon"><i class="fas" v-bind:class="'fa-'+tab.icon"></i></span>
              {{ $t(tab.name) }}
          </a>
        </li>

        <li v-if="editmode">
          <a class="navbar-item" v-on:click="newTab()">
            +
          </a>
        </li>

        <li v-if="editmode">
          <a class="navbar-item" v-on:click="$emit('readonly')" v-if="editmode">
            <span class="icon"><i class="fas fa-check"></i></span> 
          </a>
        </li>

        <li v-if="editmode">
          <a class="navbar-item" v-on:click="$emit('edit')" v-if="!editmode">
            <span class="icon"><i class="fas fa-pen"></i></span> 
          </a>
        </li>

      </ul>
    </div>

    <bulmatabprops v-if="showModal" v-on:change="setTabName($event)" v-on:cancel="showModal=false" v-bind:isnew="isnew" v-bind:tab="tab"></bulmatabprops>

  </nav>

</template>

<script>
import bulmatabprops from '../components/bulma_tabprops.vue'

export default {
  name: 'bulmaBottomBar',
  components: {
    bulmatabprops
  },
  props: {
    id: String,

    titoloapp: String,
  },
  computed: {
    config: function() {
      return this.$store.getters.getObject(this.id).data;
    },
    editmode: function() {
      return this.$store.getters.getEditMode(this.id);
    },
    romode: function() {
      return this.$store.getters.getROMode(this.id);
    }
  },
  data() {
    return {
      showModal: false,
      isnew: false,
      tabindex: 0,
      tab:{
        name:'',
        path:'',
        index: -1
      },

      burger_open: false,
    }
  },
  methods: {
    setTabName: function(tab) {
      console.log('setTabName', tab);      
      this.showModal=false;
      if (this.isnew) {
        this.$emit('add', tab);
      } else {
        this.$emit('change', tab);
      }
    },
    newTab: function() {
      this.isnew=true;
      this.showModal=true;
      this.tab.name='';
      this.tab.path='';
      this.tab.index=-1;
    },
    updateConfig: function( name, val ) {
      var c=this.config;
      c[name]=val;
      var payload={ id:this.id, cfg:{ id:this.id, type:this.type, data:c} }      
      this.$store.commit('SET_CONFIG', payload);
    },
    tabClick: function(t) {
      if (!this.editmode) {
        if (t.action) {
          this.updateConfig("active_subtab", t.name);
          this.$emit('click', t);

          let m={ message: t.action, payload: this.id};
          this.$store.commit('POST_MESSAGE', m);
        }

      } else {
        /*
        this.tab.index=tabindex;
        this.tab.name=t.name;
        this.tab.path=t.path;
        this.isnew=false;
        this.showModal=true;
        */
      }
    },
  }
}
</script>

<style scoped>
.itemdisabled {
  color: darkslategrey;
}
</style>
