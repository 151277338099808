<template>
    <bulmaModal v-bind:buttons="['Save', 'Cancel']" v-on:click="manageModal($event)" v-bind:title="'Set Actions'">
        
        
        <div class="columns">
            <!-- Title Section -->
            <div class="column is-4">
                <div class="field">
                    <label class="label">Title</label>
                    <div class="control">
                        <input class="input " type="text" v-model="toUpdate.title" placeholder="Section Title">
                    </div>
                </div>
            </div>


            <!-- steps_field of section -->
            <div class="column is-4">
                <div class="field">
                    <label class="label">Steps Field</label>
                    <div class="control">
                        <input class="input " type="text" v-model="toUpdate.steps_field" placeholder="Section Steps field">
                    </div>
                </div>
            </div>
            
            
            <!-- Actions number -->
            <div class="column is-one-quarter">
                <!-- class for mible -->
                <div class="field is-horizontal-mobile ">
                    
                    <label class="label">Actions Number:</label>
                    
                    
                    <div class="control numberInput ml-2">
                        <input class="input " type="number" v-model.number="actionsno" v-on:change="setActions(actionsno)" placeholder="Actions Number">
                    </div>
                    
                </div>
            </div>
        </div>
        
        
        <!-- actions configuration -->
        <div v-for="(action, index) in toUpdate.actions" :key="index" class="box">
            <div class="columns is-mobile is-multiline">
                <!--action settings -->
                <div class="column is-half">
                    <div class="field">
                        <label class="label ">Action Name:</label>
                        <div class="control">
                            <input class="input  " type="text" v-model="action.name" placeholder="Action Name">
                        </div>
                    </div>
                </div>
                
                <div class="column is-half">
                    <div class="field">
                        <label class="label ">Action Label:</label>
                        <div class="control">
                            <input class="input " type="text" v-model="action.label" placeholder="Action Label">
                        </div>
                    </div>
                </div>
                
                <!-- command, icon and other params  -->
                <div class="column is-one-third">
                    <div class="field">
                        <label class="label  ">Command:</label>
                        <div class="control">
                            <input class="input " type="text" v-model="action.action" placeholder="Action Command">
                        </div>
                    </div>
                </div>
                
                <div class="column is-one-third">
                    <div class="field">
                        <label class="label  ">Icon:</label>
                        <div class="control">
                            <input class="input " type="text" v-model="action.icon" placeholder="ex: 'fa-save' became: 'save'">
                        </div>
                    </div>
                </div>
                <div class="column is-2">
                    <div class="checkbox-container ">
                        <label class="label  is-pulled-left">
                            Predefined:
                        </label>  <br>
                        <input class="mt-3 ml-2" type="checkbox" v-model="action.default"/>
                    </div>
                </div>
                <div class="column is-2">
                    <div class="checkbox-container ">
                        <label class="label is-pulled-left">
                            Disabled:
                        </label>  <br>
                        <input class="mt-3 ml-2" type="checkbox" v-model="action.disabled"/>
                    </div>
                </div>
            </div>
        </div>
        
    </bulmaModal>
</template>


<script>
export default {
    name: 'bulmaActionsProps',
    props: {
        config: {
            type: Object,
            required: false
        },
        id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            // Initialize localConfig based on config or default values if config is empty
            toUpdate: {
                steps_field: this.config && this.config.steps_field ? this.config.steps_field : '',
                title: this.config && this.config.title ? this.config.title : '',
                actions: this.config && this.config.actions && this.config.actions.length > 0 ?
                    this.config.actions.map(action => ({ ...action })) : [{ name: '', label: '', action: '', default: false, disabled: false, icon: '' }]
            },
            actionsno: this.config && this.config.actions ? this.config.actions.length : 1
        };
    },
    mounted: async function(){
        if(!this.config){
            this.toUpdate = {
                steps_field: this.config && this.config.steps_field ? this.config.steps_field : '',
                title: this.config && this.config.title ? this.config.title : '',
                actions: this.config && this.config.actions && this.config.actions.length > 0 ? 
                    this.config.actions.map(action => ({ ...action })) : [{ name: '', label: '', action: '', default: false, disabled: false,icon: '' }]
            }
            this.actionsno = this.config && this.config.actions ? this.config.actions.length : 1
        } else console.log("config is existing")
        
    },
    methods: {
        // sets the actions based on the template
        setActions(actionCount) {
            actionCount = parseInt(actionCount) || 1;
            
            // Llimit the number of actions
            //if (actionCount > 10) actionCount = 10;
            if (actionCount < 1) actionCount = 1;
            
            // fix action number
            if (actionCount > this.toUpdate.actions.length) {
                for (let i = this.toUpdate.actions.length; i < actionCount; i++) {
                    this.toUpdate.actions.push({
                        name: '',
                        label: '',
                        action: '',
                        default: false,
                        disabled: false,
                        icon: ''
                    });
                }
            } else if (actionCount < this.toUpdate.actions.length) {
                this.toUpdate.actions.splice(actionCount);
            }
            
            this.actionsno = actionCount;
        },
        
        // manage closing of the modal
        manageModal(buttonIndex) {
            if (buttonIndex === 1 || buttonIndex === -1) {
                this.$emit('close'); // do not save the changes
          
            } 
            
            
            const names = this.toUpdate.actions.map(obj => obj.name);
            const uniqueNames = new Set(names);
            
            
            if (names.length!==uniqueNames.size) {
                alert("all names must be different");
                return;
            }
            
            // Ssave the changer
            const data = {
                title: this.toUpdate.title,
                steps_field: this.toUpdate.steps_field,
                actions: this.toUpdate.actions
            };
            
            this.$store.dispatch('setObjectDB', { id: this.id, data: data });
            console.log("new props for actions: ", this.toUpdate);
            //this.$emit('change', this.toUpdate); // Emit updated localConfig
            
            
            
            
            this.$store.commit('SET_CONFIG_OBJECT', { id: this.id, type: "bulmaActions", data: this.toUpdate });
            
            this.$emit('close'); 
            console.log("sono passato")
            
        }
    }
};
</script>
<style>
.checkbox-container input[type="checkbox"] {
    transform: scale(2.1); /* encrase checkbox dimention */
    margin-right: 10px;
}
@media screen and (max-width: 768px) {
    .field.is-horizontal-mobile {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    
}
</style>