<template lang="html">

  <nav class="navbar is-fixed-top" role="navigation" aria-label="main navigation" v-bind:id="id" >
    <div class="navbar-brand">
      <a class="navbar-item largedevice" href="/">
        <img src="/favicon.png" style="width: 28px;"/>
      </a>

      <a class="navbar-item largedevice" v-for="(tab, index) in config.tabs" v-bind:key="tab.order" style="padding-right: 0.65rem; padding-left: 0.65rem;" v-bind:class="{ 'is-active': tab.name==config.active_tab }" v-on:click="tabClick(index)">
        <span class="icon" v-if="tab.icon"><i class="fas" v-bind:class="'fa-'+tab.icon"></i></span>
      </a>

      <a class="navbar-item largedevice" v-if="$store.getters.isAdmin">
          <span class="icon" v-on:click="switchEditProps()"><i class="fas fa-pencil"></i></span> 
      </a>
      <a class="navbar-item largedevice" > <!-- v-on:click="enableNotifications" -->
          <span class="icon"><i class="fas fa-bell"></i></span> 
      </a>

      <a class="navbar-burger" role="button" aria-label="menu" aria-expanded="false"  v-bind:class="{ 'is-active':burger_open }" v-on:click="burger_open=!burger_open">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <!-- burger opened only -->
    <div class="navbar-menu" v-bind:class="{ 'is-active':burger_open }">
      <div class="navbar-start">
        <a class="navbar-item" href="/" v-if="!burger_open">
          <img src="/images/logo/Florida-Tile-logo.png"/>
        </a>
        <a class="navbar-item" v-for="(tab, index) in config.tabs" v-bind:key="tab.order" v-bind:class="{ 'is-active': tab.name==config.active_tab }" v-on:click="tabClick(index)">
          <span v-if="editmode">{{ index }}:</span>{{ $t(tab.name) }}
        </a>
      </div>
      <div class="navbar-end" v-if="!burger_open">
        <a class="navbar-item"  v-if="$store.getters.isAdmin">
            <span class="icon" v-on:click="switchEditProps()"><i class="fas fa-pencil"></i></span> 
        </a>
        <a class="navbar-item" > <!-- v-on:click="enableNotifications" -->
            <span class="icon"><i class="fas fa-bell"></i></span> 
        </a>
      </div>
    </div>

    <bulmatabprops v-if="showModal" v-on:change="setTabName($event)" v-on:cancel="showModal=false" v-bind:isnew="isnew" v-bind:tab="tab"></bulmatabprops>

  </nav>

</template>

<script>
import bulmatabprops from '../components/bulma_tabprops.vue'

export default {
  name: 'bulmaTabBar',
  components: {
    bulmatabprops
  },
  props: {
    id: String,
    data: Object,
  },
  watch: { 
    process: function(newVal) {
      if (!newVal) newVal='/';  else newVal='/'+newVal;
      this.setActiveOnPath(newVal);
    },
  },
  computed: {
    process: function() {
      return this.$store.state.global_state_process;
    },
    config: function() {
      return this.$store.getters.getObject(this.id).data;
    },
    editmode: function() {
      return this.$store.getters.getEditMode(this.id);
    },
    romode: function() {
      return this.$store.getters.getROMode(this.id);
    }
  },
  data() {
    return {
      showModal: false,
      isnew: false,
      tabindex: 0,
      tab:{
        name:'',
        path:'',
        index: -1
      },

      burger_open: false,
    }
  },
  mounted: function() {
    let newVal=this.process;
    if (!newVal) newVal='/';  else newVal='/'+newVal;

    this.setActiveOnPath(newVal);
  },
  methods: {
    setActiveOnPath: function(newVal) {
      let t=this.config.tabs.find( (t) => {
        return t.path==(newVal);
      })
      if (t) this.updateConfig("active_tab", t.name);
    },
    saveConfig: function() {
      console.log('saveConfig');
    },
    gotoEditMode: function(flag) {
      var payload={ id: this.id, editmode: flag };
      this.$store.commit('SET_EDITMODE', payload);
    },
    updateConfig: function( name, val ) {
      var c=this.config;
      c[name]=val;
      var payload={ id:this.id, cfg:{ id:this.id, type:this.type, data:c} }      
      this.$store.commit('SET_CONFIG', payload);
    },
    setTabName: function(tab) {
      console.log('setTabName', tab);      
      this.showModal=false;
      if (this.isnew) {
        this.$emit('add', tab);
      } else {
        this.$emit('change', tab);
      }
    },
    newTab: function() {
      this.isnew=true;
      this.showModal=true;
      this.tab.name='';
      this.tab.path='';
      this.tab.index=-1;
    },
    tabClick: function(tabindex) {
      this.burger_open=false;
      var t=this.config.tabs[tabindex];
      if (!this.editmode) {
        this.updateConfig("active_tab", t.name);
        this.$router.push(t.path);
        this.$emit('click', t);
      } else {
        this.tab.index=tabindex;
        this.tab.name=t.name;
        this.tab.path=t.path;
        this.isnew=false;
        this.showModal=true;
      }
    },
    switchEditProps: function(){
      this.$store.dispatch('switchEditProps');
    },
    // async enableNotifications() {
    //   console.log("--> enableNotifications");
    //   if ('Notification' in window && 'serviceWorker' in navigator) {
    //     console.log("request notifications");
    //     const permission = await Notification.requestPermission();
        
    //     if (permission === 'granted') {
    //       console.log("allow notifications");
    //       await this.subscribeToPushNotifications();
    //       console.log("allowed notifications");
    //     } else {
    //       console.error('Notification permissions denied.');
    //     }
    //   } else {
    //     console.error('Browser does not support push notifications.');
    //   }
    // },
    // async subscribeToPushNotifications() {
    //   console.log("registration started");
    //   const registration = await navigator.serviceWorker.register('/service-worker.js', {scope: '/'});;
    //   let getResult=await registration.pushManager.getSubscription();
    //   console.log('getSubscription result: ', getResult);
    //   console.log("subscription started");
      
    //   this.$store.dispatch("saveSubscription", { id: this.id });
    // },
   
    // sendNotification(){
    //   this.$store.dispatch("sendNotification");
    // }
  }
}
</script>

<style scoped>

</style>
