<template>
    <bulmaModal v-bind:buttons="['Save', 'Cancel']" v-bind:title="'Set Graph'"  v-on:click="manageModal($event)" >
        <div class="columns">
            <div class="column is-3">
                <div class="field is-horizontal">
                    <div class="field-label">
                        <label class="label">Title:</label>
                    </div>
                    <div class="field-body">
                        <input class="input" type="text" v-model="toUpdate.title.text" placeholder="title"></input>
                    </div>
                </div>
            </div>
            <div class="column is-3">
                <div class="field  is-horizontal">
                    <div class="field-label">
                        <label class="label">Left:</label>
                    </div>
                    <div class="field-body">
                        <input class="input" type="text" v-model="toUpdate.title.left" placeholder="es: center"></input>
                    </div>
                </div>
            </div>
            <div class="column is-3">
                <div class="field  is-horizontal">
                    <div class="field-label">
                        <label class="label">Tooltip trigger:</label>
                    </div>
                    <div class="field-body">
                        <input class="input" type="text" v-model="toUpdate.tooltip.trigger" placeholder="es: item"></input>
                    </div>
                </div>
            </div>
            <!-- <div class="column i is-2">
                <div class="field is-horizontal">
                    <div class="field-label">
                        <label class="label">Series Number:</label>
                    </div>
                    <div class="field-body number">
                        <input class="input" type="number" v-model="length" v-on:change="setSeries" placeholder="N.."> </input>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="box" v-for="(serie, index) in this.toUpdate.series ">
            <div class="columns is-multiline">
                <div class="column is-3">
                    <div class="field is-horizontal">
                        <div class="field-label">
                            <label class="label">Name:</label>
                        </div>
                        <div class="field-body">
                            <input class="input" type="text" v-model="serie.name"></input>
                        </div>
                    </div>
                </div>
                <div class="column is-3">
                    <div class="field is-horizontal">
                        <label class="label">Type:
                            <div class="select">
                                <select v-model="serie.type" class="type" v-on:change="setType(index)"><!--   -->
                                    <option value="bar">bar</option>
                                    <option value="pie">pie</option>
                                </select>
                            </div>
                        </label>
                    </div>
                </div>
                <div class="column is-3">
                    <div class="field is-horizontal">
                        <div class="field-label">
                            <label class="label">Segment Number:</label>
                        </div>
                        <div class="field-body">
                            
                            <input
                            class="input"
                            type="number"
                            :value="segmentLengths[index]"
                            v-on:change="setSegment(index, $event.target.value, serie.type)"
                            placeholder="Segment Count"
                            />
                        </div>
                    </div>
                    
                </div> 
                
                <!-- <div class="column is-3" v-if="serie.type==='bar'">
                    <div class="select">
                        <select v-model="isX" >
                            <option :value=true>x</option>
                            <option :value=false>y</option>
                        </select>
                    </div>
                </div>  -->
            </div>

            <div class="columns is-multiline" v-if="serie.type==='pie'">
                <div class="column is-3x">
                    <div class="field is-horizontal">
                        <div class="field-label">
                            <label class="label">Radius:</label>
                        </div>
                        <div class="field-body">
                            
                            <input
                            class="input"
                            type="text"
                            v-model="serie.radius"
                            placeholder="Segment Radius"
                            v-on:change="checkRadius(serie.radius, index)"
                            />
                            <!-- toUpdate.series[index].data.length -->
                            
                            <!-- :value=""  -->
                        </div>
                    </div>
                    
                </div> 
                <div class="column is-3">
                    <div class="field is-horizontal">
                        <div class="field-label">
                            <label class="label">Shadow Blur:</label>
                        </div>
                        <div class="field-body">
                            <input
                            class="input"
                            type="text"
                            v-model="serie.emphasis.itemStyle.shadowBlur"
                            placeholder="Shadow Blur"
                            />
                        </div>
                    </div>
                </div> 
                <div class="column is-3">
                    <div class="field is-horizontal">
                        <div class="field-label">
                            <label class="label">Shadow Offset:</label>
                        </div>
                        <div class="field-body">
                            <input
                            class="input"
                            type="text"
                            v-model="serie.emphasis.itemStyle.shadowOffsetX"
                            placeholder="Shadow Blur"
                            />
                        </div>
                    </div>
                    
                </div> 
                <div class="column is-3">
                    
                            <div class="field is-horizontal">
                                <div class="field-label">
                                    <label class="label">Shadow Color:</label>
                                </div>
                                <div class="field-body ml-1" v-for="(rgbValue, rgbIndex) in rgb">
                                    <input
                                    class="input"
                                    type="text"
                                    v-model="rgb[rgbIndex]"
                                    v-on:change="buildRGB(index)"
                                    placeholder="0"
                                    />
                                </div>
 
                            </div>
                      
                    
                    
                </div> 
                <div v-for="(segment, segmentIndex) in serie.data" :key="segmentIndex" class="column is-6">
                    <div class="box">
                        <div class="columns">
                            <div class="column">
                                <div class="field is-horizontal is-narrow">
                                    <div class="field-label">
                                        <label class="label">Name:</label>
                                    </div>
                                    <div class="field-body">
                                        <input class="input" type="text" v-model="segment.name" placeholder="name"></input>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="column">
                                <div class="field is-horizontal is-narrow">
                                    <div class="field-label">
                                        <label class="label">Value:</label>
                                    </div>
                                    <div class="field-body">
                                        <input class="input" type="text" v-model="segment.value" placeholder="value"></input>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                    
                    
                </div>
            </div>
            <!-- inizio nuovo codice -->
            <div class="columns is-multiline" v-if="serie.type==='bar'">
                
                
                
                
                <div  class="column is-6" v-for="(col, segmentIndex) in serie.data" :key="segmentIndex"> <!-- v-for="(segment, segmentIndex) in serie.data" :key="segmentIndex" -->
                    <div class="box">
                        <div class="columns">
                            <div class="column is-6">
                                <div class="field is-horizontal is-narrow">
                                    <div class="field-label">
                                        <label class="label">Value:</label>
                                    </div>
                                    <div class="field-body">
                                        <input class="input" type="number" placeholder="value"  v-model="serie.data[segmentIndex]"></input>
                                    </div>
                                </div>
                            </div>
                            <div class="column is-6">
                                <div class="field is-horizontal is-narrow" v-if="isX">
                                    <div class="field-label">
                                        <label class="label">x Name:</label>
                                    </div>
                                    <div class="field-body">
                                        
                                        <input
                                        class="input"
                                        type="text"
                                        v-model="toUpdate.xAxis.data[segmentIndex]"
                                        placeholder="Name"
                                        
                                        />
                                        <!-- toUpdate.series[index].data.length -->
                                        
                                        <!-- :value=""  -->
                                    </div>
                                </div>
                                <!-- <div class="field is-horizontal is-narrow" v-if="!isX">
                                    <div class="field-label">
                                        <label class="label">y Name:</label>
                                    </div>
                                    <div class="field-body">
                                        
                                        <input
                                        class="input"
                                        type="text"
                                        v-model="toUpdate.yAxis.data[segmentIndex]"
                                        placeholder="Name"
                                        
                                        />
                                        
                                        
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        
                    </div>
                    
                    
                </div>

                <div class="column is-12"><label class="label">Y Values:</label></div>
                <div class="column is-3 " v-for="(yValue, indexY) in toUpdate.yAxis.data" :key="`yAxis-${indexY}`">
         
                    <div class="box">
                        <div class="columns">
                            <div class="column">
                                <input
                                    class="input"
                                    type="text"
                                    v-model="toUpdate.yAxis.data[indexY]"
                                    placeholder="Name"  
                                />
                            </div>
                            <div class="column is-2">
                                <button class="button is-pulled-right " v-on:click="deleteYValue(indexY)">
                                    <span class="icon">
                                        <i class="fas fa-trash"></i>
                                    </span>
                                </button>
                            </div>
                            
                        </div>
                        
                    </div>   
                    
                </div>
                <div class="column is-3">
                    <div class="box">
                        <button class="button" v-on:click="addYValue()" v-bind:style="{ width: '100%' }">Add</button>
                    </div>
                    
                </div>
                <!-- <label class="column is-12 label">x Values:</label>
                <div class="column is-3"  v-for="(value, dataIndex) in toUpdate.xAxis.data">
                    <div class="box">
                        <div class="field is-horizontal is-narrow">
                            <div class="field-label">
                                <label class="label">Value N.{{ dataIndex+1 }}:</label>
                            </div>
                            <div class="field-body">
                                
                                <input
                                class="input"
                                type="text"
                                :value="value"
                                placeholder="Segment Radius"
                                v-on:change="updateXBarValue($event.target.value, dataIndex)"
                                />
                                
                                
                                
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
                
                 -->
                
                <!-- <label class="column is-12 label">y Values:</label>
                <div class="column is-3"  v-for="(value, dataIndex) in toUpdate.yAxis.data">
                    <div class="box">
                        <div class="field is-horizontal is-narrow">
                            <div class="field-label">
                                <label class="label">Value N.{{ dataIndex+1 }}:</label>
                            </div>
                            <div class="field-body">
                                
                                <input
                                class="input"
                                type="text"
                                :value="value"
                                placeholder="Segment Radius"
                                v-on:change="updateYBarValue($event.target.value, dataIndex)"
                                />
                                
                                
                                
                            </div>
                        </div>
                        
                        
                    </div>
                </div> -->
                
                
                
            </div>
        </div>
        
        
        <!-- fine nuovo codice -->
        
        
        
        
    </bulmaModal>
    
</template>
<script>
/*
series{
data: [valore1, valore2, valore3]
name:
type:
}
title
tooltip{
trigger:
}
xAxis: array[nome1, nome2, nome3]
yAxis
*/ 

export default {
    name: 'bulmaGraphProps',
    props: {
        config: {
            type: Object,
            required: false
        },
        id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            // Initialize localConfig based on config or default values if config is empty
            toUpdate:{
                series: this.config && this.config.series ? this.config.series : {
                    data: [{name: "", value: ""}],
                    name: "",
                    type: "",
                    radius:"",
                    emphasis:  {
                        itemStyle:{
                            shadowBlur:'',
                            shadowColor:'',
                            shadowOffsetX:''
                        }
                    }
                },
                
                xAxis: this.config && this.config.xAxis ? this.config.xAxis : {data:[""]},
                yAxis: this.config && this.config.yAxis ? this.config.yAxis : {data:[""]},
                title: this.config && this.config.title ? this.config.title : {text:"", left:""},
                tooltip: this.config && this.config.tooltip ? this.config.tooltip : {
                    trigger: "",
                },
                
            },
            
            length: this.config && this.config.series && this.config.series.length ? this.config.series.length : 1,
            isX: true,
            mountedIterator:0,
            rgb: ["","","",""],
        };
    },
    //sistema mancati controlli del config, gestisci salvataggio e chiusura.
    //versione grafico a torta, implementa versione grafico a colonne con if
    mounted: async function(){
        console.log("entered in mounted", this.toUpdate.yAxis)
        if(!this.config){
            this.toUpdate = {
                series: this.config && this.config.series ? this.config.series : [{
                    data: [{name: "", value: ""}],
                    name: "",
                    type: "pie",
                    radius:"",
                    emphasis:{
                        itemStyle:{
                            shadowBlur:0,
                            shadowColor:'rgba(0, 0, 0, 0)',
                            shadowOffsetX:0
                        }
                    }
                }],
                
                xAxis: this.config && this.config.xAxis ? this.config.xAxis : {data:[""]},
                yAxis: this.config && this.config.yAxis ? this.config.yAxis : {data:[""]},
                title: this.config && this.config.title ? this.config.title : {text:"", left:""},
                tooltip: this.config && this.config.tooltip ? this.config.tooltip : {
                    trigger: "",
                },
                
            }
            this.length = this.config && this.config.series && this.config.series.length ? this.config.series.length : 1;
            
            
        } else console.log("config is existing")
        
        if(this.toUpdate.yAxis == {} || !this.toUpdate.yAxis || !this.toUpdate.yAxis.data){
            console.log("y setted in mounted")
            this.toUpdate.yAxis={data:[]}
        }
        if(this.toUpdate.xAxis == {} || !this.toUpdate.xAxis || !this.toUpdate.xAxis.data){
            console.log("x setted in mounted")
            this.toUpdate.xAxis={data:[]}
         
        }
        
        if(this.toUpdate && this.toUpdate.series){
        
            for (let i = 0; i < this.toUpdate.series.length; i++) {
                if(!this.toUpdate.series[i].emphasis){
                    this.toUpdate.series[i].emphasis = {
                        itemStyle: {
                            shadowBlur: '',
                            shadowColor: 'rgba(0, 0, 0, 0)',
                            shadowOffsetX: ''
                        }
                    };
                }
            }
            console.log("itemStyle setted, ",this.toUpdate.series)
        }
    },
    computed: {
        segmentLengths() {
            if(this.toUpdate && this.toUpdate.series && this.toUpdate.series.map){
                return this.toUpdate.series.map(serie => serie.data.length);
            }else return 0;
            
        }
    },
    methods: {
        // Modifica il numero di serie
        setSeries() {
            this.length = parseInt(this.length) || 1;
            
            // Limita il numero di serie
            //if (this.length > 10) this.length = 10;
            if (this.length < 1) this.length = 1;
            
            // Aggiusta il numero di serie
            if (this.length > this.toUpdate.series.length) {
                for (let i = this.toUpdate.series.length; i < this.length; i++) {
                    this.toUpdate.series.push({
                        data:[{name: "", value: ""}],
                        name:"",
                        type:"",
                        radius:""
                    });
                }
            } else if (this.length < this.toUpdate.series.length) {
                this.toUpdate.series.splice(this.length);
            }
            
            this.length = this.length;
            console.log(this.length)
        },
        
        setSegment(index, newLength, type) {
            const targetSerie = this.toUpdate.series[index]; // Serie target
            const currentLength = targetSerie.data.length;  // Numero attuale di segmenti
            const desiredLength = parseInt(newLength) || 1; // Nuovo numero di segmenti (con fallback a 1)
            if (desiredLength < 1) return; // Evitiamo valori invalidi
            
            if (desiredLength > currentLength) {
                // Aggiungi segmenti
                if(type=="pie"){
                    for (let i = currentLength; i < desiredLength; i++) {
                        targetSerie.data.push({
                            name: "",
                            value: ""
                        });
                    }
                }else if (type=="bar"){
                    for (let i = currentLength; i < desiredLength; i++) {
                        targetSerie.data.push(0);
                    }
                }
                
            } else if (desiredLength < currentLength) {
                // Rimuovi segmenti
                targetSerie.data.splice(desiredLength);
            }
            
            console.log(`Updated segments for series[${index}]:`, targetSerie.data);
        },
        
        
        
        
        // Gestisci la chiusura del modale
        manageModal(buttonIndex) {
            if (buttonIndex === 1 || buttonIndex === -1) {
                this.$emit('close'); // Annulla le modifiche
                console.log("launch close")
                return;
            } 
            
            
            const IDs = this.toUpdate.series.map(obj => obj.id);
            const uniqueIDs = new Set(IDs);
            console.log(this.toUpdate.series[0].type, "this.toUpdate.type")
            if(this.toUpdate.series[0].type =="bar"){
                //this.toUpdate.series[0].data = this.toUpdate.yAxis.data.concat(this.toUpdate.xAxis.data).sort((a, b) => a - b);
                console.log("yAxis refactored", this.toUpdate.yAxis.data)
                this.toUpdate.yAxis={};
                //toUpdate.xAxis.data
            } else if(this.toUpdate.series[0].type =="pie"){
                delete this.toUpdate.xAxis
                delete this.toUpdate.yAxis
                console.log("removed x and y", this.toUpdate)
            }
            


            
            
            
            
            this.$store.dispatch('setObjectDB', { id: this.id, data: this.toUpdate });
            console.log("new props for series: ", this.toUpdate);
            //this.$emit('change', this.toUpdate); // Emit updated localConfig
            
            
            
            
            this.$store.commit('SET_CONFIG_OBJECT', { id: this.id, type: "bulmaGraph", data: this.toUpdate });
            this.$emit('update');
            
        },
        checkRadius(radius, index){
            radius = radius.replace("%", "")
            console.log("radius value", radius)
            if(radius>100) radius = 100;
            if (radius<1) radius = 1;
            radius=radius+"%"
            this.toUpdate.series[index].radius = radius;
            console.log("new radius", radius)
        },
        updateXBarValue(value, dataIndex){
            console.log("ci ho provato", this.toUpdate.xAxis.data[dataIndex], "updated in:", value)
            if(!xAxis){
                for(let i = 0; i < this.toUpdate.series.length; i++) this.toUpdate.xAxis.data = ""
            }
            this.toUpdate.xAxis.data[dataIndex] = value;
            
        },
        updateYBarValue(value, dataIndex){
           
            if(!yAxis){
                for(let i = 0; i < this.toUpdate.series.length; i++) this.toUpdate.yAxis.data = ""
            }
            this.toUpdate.yAxis.data[dataIndex] = value;
            
        },
        setType(index){
            console.log('set type', this.toUpdate.series[index].type)
            if(this.toUpdate.series[index].type == "pie"){
                // this.toUpdate.yAxis= this.toUpdate.series.map(serie =>serie.name)
                // this.toUpdate.xAxis= this.toUpdate.series.map(serie =>serie.name)
                
                let mom;
                for(let j = 0; j < this.toUpdate.series[index].data.length; j++){
                    mom = this.toUpdate.series[index].data[j];
                    this.toUpdate.series[index].data[j]={};
                    this.toUpdate.series[index].data[j].value= mom;
                    
                    //.map(serie =>serie.data)
                    this.toUpdate.series[index].data[j].name= this.toUpdate.xAxis.data[j]
                }
                
                // this.toUpdate.series[].data[].value
                //console.log(this.toUpdate.series[index].data, 'series[index].data', this.toUpdate.series, 'series')
                
                // for(let i = 0; i < this.toUpdate.series.length; i++){
                //     this.toUpdate.series.emphasis={
                //         itemStyle:{
                //             shadowBlur:'',
                //             shadowColor:'',
                //             shadowOffsetX:''
                //         }
                
                //     }
                // }
                
            }else if(this.toUpdate.series[index].type == "bar"){
                console.log("entered bar",this.toUpdate.series[index].data )
                let mom;
                this.toUpdate.xAxis.data=[];
                this.toUpdate.yAxis.data=[];
                for(let j = 0; j < this.toUpdate.series[index].data.length; j++){
                    
                    
                    //.map(serie =>serie.data)
                    
                    mom=this.toUpdate.series[index].data[j];
                    this.toUpdate.xAxis.data.push(mom.name)
                    //this.toUpdate.yAxis.data.push(mom.name)
                    //this.toUpdate.series[index].data[j].name= this.toUpdate.xAxis.data[j]
                    console.log("xAxis Updated",this.toUpdate.xAxis.data, mom.name)
                    
                    
                    
                    //console.log(this.toUpdate.series[index].data[j].value, this.toUpdate.series[index].data[j].name)
                    
                    this.toUpdate.series[index].data[j]= mom.value;
                }
            }
            
            
            
        },
        addYValue(){
            console.log("this.toUpdate.yAxis.data", this.toUpdate.yAxis.data)
            let ciao=this.toUpdate.yAxis.data.length;
            if(!this.toUpdate.yAxis ||(!this.toUpdate.yAxis && !this.toUpdate.yAxis.data)) this.toUpdate.yAxis =[];
            this.toUpdate.yAxis.data.push(""+ciao);
        },
        deleteYValue(indexY){
            this.toUpdate.yAxis.data.splice(indexY, 1)
        },
        buildRGB(index){
            this.toUpdate.series[index].emphasis.itemStyle.shadowColor = ("rgba("+this.rgb[0]+","+this.rgb[1]+","+this.rgb[2]+","+this.rgb[3]+")")
            console.log("--> buildRGB, shadowColor:", this.toUpdate.series[index].emphasis.itemStyle.shadowColor)
        }
    }
    
    
    
};

</script>
<style>
.number{
    width:15%;
}
.type{
    width: 8.8vw;
    margin-left:0.6vw;
}
</style>