<template>
    <bulmaModal
        v-bind:buttons="['Save', 'Cancel']"
        v-on:click="manageModal($event)"
        v-bind:title="'map configuration'">
        <!-- Titolo -->
        <div class="field">
            <label class="label">Title</label>
            <div class="control">
                <input
                    class="input"
                    type="text"
                    v-model="toSend.title"
                    placeholder="Map Title"/>
            </div>
        </div>

        <!-- Latitudine -->
        <div class="field">
            <label class="label">Latitude</label>
            <div class="control">
                <input
                    class="input"
                    type="text"
                    v-model.number="toSend.latitude"
                    placeholder="Latitude"/>
            </div>
        </div>

        <!-- Longitudine -->
        <div class="field">
            <label class="label">Longitude</label>
            <div class="control">
                <input
                    class="input"
                    type="text"
                    v-model.number="toSend.longitude"
                    placeholder="Longitude"/>
            </div>
        </div>

        <!-- API Key -->
        <div class="field">
            <label class="label">API Key</label>
            <div class="control">
                <input
                    class="input"
                    type="text"
                    v-model="toSend.apiKey"
                    placeholder="API Key of Google Maps"/>
            </div>
        </div>

        <!-- Nome Campo Strada -->
        <div class="field">
            <label class="label">Street</label>
            <div class="control">
                <input
                    class="input"
                    type="text"
                    v-model="toSend.street_fieldname"
                    placeholder="street field name"/>
            </div>
        </div>

        <!-- Nome Campo Città -->
        <div class="field">
            <label class="label">city</label>
            <div class="control">
                <input
                    class="input"
                    type="text"
                    v-model="toSend.city_fieldname"
                    placeholder="city field name"/>
            </div>
        </div>

        <!-- Nome Campo Stato -->
        <div class="field">
            <label class="label">State</label>
            <div class="control">
                <input
                    class="input"
                    type="text"
                    v-model="toSend.state_fieldname"
                    placeholder="state field name"/>
            </div>
        </div>
    </bulmaModal>
</template>

<script>
    export default {
        name: 'bulmamapprops',
        props: {
            config: {

                required: true,
                default: () => ({}) // Ritorna un oggetto vuoto se `config` non è passato
            },
            id: {
                type: String,
                required: true
            }
        },
        mounted: async function () {
          if(!this.config){
            this.toSend= {
              title: this.config && this.config.title ? this.config.title : "",
              latitude: this.config && this.config.latitude ? this.config.latitude : "",
              longitude: this.config && this.config.longitude  ? this.config.longitude : "",
              apiKey: this.config && this.config.apiKey ? this.config.apiKey : "",
              street_fieldname: this.config && this.config.street_fieldname ? this.config.street_fieldname : "",
              city_fieldname: this.config && this.config.city_fieldname ? this.config.city_fieldname : "",
              state_fieldname: this.config && this.config.state_fieldname ? this.config.state_fieldname : "",
            }
          }else console.log("config is existing")

        },
        data() {
            return {
                // Copia locale di `config` per evitare errori quando `config` è nullo
               toSend: {
                  title: this.config && this.config.title ? this.config.title : "",
                  latitude: this.config && this.config.latitude ? this.config.latitude : "",
                  longitude: this.config && this.config.longitude  ? this.config.longitude : "",
                  apiKey: this.config && this.config.apiKey ? this.config.apiKey : "",
                  street_fieldname: this.config && this.config.street_fieldname ? this.config.street_fieldname : "",
                  city_fieldname: this.config && this.config.city_fieldname ? this.config.city_fieldname : "",
                  state_fieldname: this.config && this.config.state_fieldname ? this.config.state_fieldname : "",
                }
            };
        },
        methods: {
            manageModal(buttonIndex) {
                if (buttonIndex === 1 || buttonIndex === -1) {
                    this.$emit('cancel'); // Annulla le modifiche
                } else if (buttonIndex === 0) {
                    // Invia il nuovo oggetto props con tutti i campi richiesti
                    const obj = {
                        id: this.id,
                        data: this.toSend
                    };
                    this.$store.dispatch('setObjectDB', obj);
                    console.log("new props for map: ", this.toSend);
                    
                    this.$emit('change', {id: this.id, data: this.toSend, type:"bulmaMap", notes:""}); // Salva le modifiche

              
              }
            }
        }
    };
</script>

<style scoped="scoped"></style>