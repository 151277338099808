<template lang="html">

  <div class="timeline" v-bind:id="id" >
    <div v-for="event in events" v-bind:key="event.id" v-bind:class="{'timeline-item':event.type=='item','timeline-header':event.type=='header'}">

      <span v-if="event.type=='header'" class="tag is-primary">{{ event.subject }}</span>

      <div v-if="event.type=='item'" class="timeline-marker" v-bind:class="{'is-icon':event.icon}">
        <i class="fa" v-bind:class="'fa-'+event.icon"></i>
      </div>

      <div v-if="event.type=='item'" class="timeline-content">
        <!-- <p class="heading">{{ event.date }}</p> -->
        <p class="heading">{{ formatDate( event.date ) }}{{ event.priority?' - '+event.priority:'' }}</p>
        <a href="#" v-on:click.prevent="select(event)">
          <!-- {{ event.activity }}{{ event.subject?' - '+event.subject:'' }} -->
          {{ event.subject }}{{ event.status?' - '+event.status:'' }}
        </a>
      </div>

    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'bulmaTimeline',
  components: {
  },
  computed: {
    config: function() {
      return '';
//      return this.$store.getters.getObject(this.id).data;
    },
    process() {
      return this.$store.getters.getObject(this.id).data.lookup_process;
    },
    events() {
      var eventi = []
      if(this.records) {
        this.records.forEach( r => {
          // SOLO TASK
          let event;
          if (!r.Type) event=this.setTaskEvent(r);
          if (r.Type=='Task') event=this.setTaskEvent(r);
          if (r.Type=='Note') event=this.setNoteEvent(r);
          eventi.push(event);
        })
      }
      return eventi
    },

  },
  props: {
    id: String,
    // events: Array
    records: Array
  },
  data() {
    return {
    }
  },
  mounted: function() {
  },
  async created() {
  },
  methods: {
    setTaskEvent(r) {
      let event={
        type:'item',
        activity:'Task',
        icon:'tasks',
        subject: r["Subject"],
        id: r.Id,
        status: r["Status"],
        priority: r["Priority"],
      };
      if (r["ActivityDate"]) 
        event.date=r["ActivityDate"];
      else
        event.date=r["CreatedDate"];

      return event;
    },
    setNoteEvent(r) {
      let event={
        type:'item',
        activity:'Note',
        icon:'sticky-note',
        subject: r["Subject"],
        id: r.Id
      };
      if (r["ActivityDate"]) event.date=r["ActivityDate"];
      return event;
    },
    select(event) {
      console.log('--> select', event);
      var record = {}
      this.records.forEach(r => {
        if(r.Id == event.id) record = r
      })
      console.log('record: ', record)
      delete record.show

      var data = {
        processId: this.process,
        verbId: "e",
        title: event.subject,
      }
      console.log('data',data)
      this.$store.commit('PUSH_PROCESS_STATUS', data);
      this.$store.commit('SET_RECORDS_STATE', {id: this.process, state:'EDIT'});
      this.$store.commit('SET_RECORDS_DATA', {id: this.process, data: record });
    },
    formatDate(date) {
      var d=new moment(date);
      return d.format('MM/DD/YYYY');
    }
  }
}
</script>

<style scoped>
</style>
