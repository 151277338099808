<template lang="html">

    <div class="">

        <div v-if="enableApproval">
            
            <div v-if="beforeRequest">
                <p>You can ask for approval for your project. Press "Submit" button to proceed.</p>
            </div>

            <div v-if="afterRequest && !$store.getters.isApprover">
                <p>You have already submitted your request.</p>
                <p>Current status is: {{ statoApproval }}</p>
            </div>

            <div v-if="afterRequest && $store.getters.isApprover">
                <div v-if="$store.getters.canApprove">
                    <p>You can approve this project.</p>

                    <div class="field is-horizontal mt-3">
                        <div class="field-label is-small pl-1">
                            <label class="label">Choose Status:</label>
                        </div>
                        <div class="field-body">
                            <div class="select is-fullwidth" style="height:auto" v-bind:class="{ 'is-danger': status == ''}">
                                <select v-model="status" @change="changeStatus">
                                    <option v-for="option in config.options" :key="option.value" :value="option.value">{{ option.label }}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                </div>
                <p v-else>You have already {{ statoApproval }} this project.</p>
            </div>

        </div>
        <div v-else>
            <p>This project cannot yet be submitted to approval.</p>
        </div>
        <table v-if="records.length>0" class="table">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Approval Status</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="record in records" v-bind:key="record.Id">
                    <td>
                        {{ formatDate(record.CreatedDate) }}
                    </td>
                    <td>
                        {{ record.NewValue }}
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
  
</template>
  
<script>
import moment from 'moment'

export default {
    name: 'bulmaApproval',
    components: {},
    props: {},
    data: function () {
        return {
            records:[],
            status: ''
        }
    },
    watch: {},
    computed: {
        id() {
            var process = this.$store.getters.getProcessStatus.processId
            return this.$store.getters.getVerb( this.$store.getters.getProcess(process), 'a' ).pageid
        },
        config: function() {
            return this.$store.getters.getObject(this.id).data
        },
        enableApproval() {
            return this.$store.getters.getRecordData.enableApproval__c
        },
        approvalRequested() {
            return this.$store.getters.getRecordData.Approval_requested__c
        },
        beforeRequest() {
            return ( this.enableApproval && !this.approvalRequested )
        },
        afterRequest() {
            return ( this.enableApproval && this.approvalRequested )
        },
        statoApproval() {
            return this.$store.getters.getRecordData.Approval_status__c
        }
    },
    mounted: function() {
        this.loadRows();
        this.$store.commit('CLEAR_APPROVAL_INFO')
        if(!this.approvalRequested) this.$store.commit('SET_APPROVAL_INFO', this.config.submit)
    },
    methods: {
        formatDate: function(v) {
            var d=new moment(v);
            return d.format('L');
        },
        changeStatus() {
            console.log(this.config[this.status])
            this.$store.commit('SET_APPROVAL_INFO', this.config[this.status])
        },
        loadRows: async function() {
            // select id, OpportunityId, Field, OldValue, NewValue, CreatedDate, Opportunity.Name from OpportunityFieldHistory where Field ='Approval_status__c' and OpportunityId ='006Kd00000cHqp7IAC'
            console.log('--> loadRows')
            this.records=[];

            let p=[
                'Id',
                'OpportunityId',
                'Field',
                'OldValue',
                'NewValue',
                'CreatedDate',
                'Opportunity.Name',
            ];
            let f=[ 
                { "fieldname": "Field", "operator": "EQ", "value": "Approval_status__c" },
                // { "fieldname": "OpportunityId", "operator": "EQ", "value": "006Kd00000cHqp7IAC" }
                { "fieldname": "OpportunityId", "operator": "EQ", "value": this.$store.getters.getRecordData.Id }
             ];
            let o=[
                { "fieldname": "CreatedDate", "operator": "DESC" }
            ]
            var res=await this.$store.dispatch('search_records', { id: this.id, object: 'OpportunityFieldHistory', filters: f, properties: p, orders: o });
            if (res.data) {
                console.log(res.data);
                res.data.records.forEach( (r) => {
                    this.records.push(r);
                });
            }
        },
    }
}
</script>
  