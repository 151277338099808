<template lang="html">
  <div>
    <div class="" v-bind:id="id" v-if="hasConfig()">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title is-5">{{ config.title }}</h1><p class="ml-3">[ {{ records.length }} records found ]</p>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
          </div>
        </div>
      </div>

      <div class="table-container">
        <table class="table is-narrow">

          <thead>
            <tr>
              <th v-for="(col, index) in current_list_cols" v-bind:key="col.id" style="vertical-align:top"  v-on:click="$emit('filter',index)" >
                <!-- {{col.label}} -->
                <span v-on:click="sortOrder(col)">
                  {{ col.label }}<span class="icon"><i :class="'fa-solid fa-sort-'+col.sorting"></i></span>
                </span>
                <!-- <span v-if="col.filtered" class="icon"><i class="fas fa-filter"></i></span> -->
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="record in current_page_records" v-bind:key="record.id">
              
              <td v-for="col in current_list_cols" v-bind:key="col.id" v-bind:style="'text-align:'+align(col.format)" >

                <a v-if="col.format=='link'" href="#" v-on:click.prevent="select(record, col)">
                  {{ filter( getFieldValue(record, col.fieldname), col.format ) }}
                </a>

                <span v-if="col.format!='link' && col.format!='hidden'">
                  <span v-if="col.format=='alert' && record[col.name]" class="icon"><i class="fas fa-bell"></i></span>
                  {{ filter( getFieldValue(record, col.fieldname), col.format ) }}
                </span>

              </td>

            </tr>
          </tbody>
        </table>
      </div>
    
      <nav class="level" v-if="$store.state.busy">
        <div class="level-item has-text-centered">
          <button class="button is-loading" >Loading</button>
        </div>
      </nav>
      <bulmaPagination v-if="!$store.state.busy" v-bind:maxPages="maxPages" v-bind:currentPage="currentPage"  v-on:goto="loadPage" v-on:next="loadNextPage" v-on:previous="loadPreviousPage"></bulmaPagination>
      
      
      <button class="button" v-on:click="changeShowModal()" v-if="isAdmin() && getEditProps">Edit Simple Table</button>
    </div>
    <div v-if="!hasConfig() && isAdmin() && getEditProps">

      
      <button class="button" v-on:click="changeShowModal()">Initialise Simple Table</button>
    </div>
    <simpleTableProps
        v-bind:id="this.id"
        v-bind:config="this.config"
        v-on:close="closeModal()"
        v-if="showModal && isAdmin() && getEditProps"
      ></simpleTableProps> 
  </div>

</template>

<script>
/* { "id":"id", "label":"Key", "fieldname":"Id", "format": "hidden", },*/

import numeral from 'numeral'
import moment from 'moment'
import simpleTableProps from './bulma_simpletableprops.vue'

export default {
  name: 'bulmaSimpleTable',
  components: {
    simpleTableProps
  },
  props: {
    id: String,
  },
  data: function () {
    return {
      records: [],
      currentPage:1,
      nrecbypage:8,
      showModal: false,

      sortingAsc: true,
    }
  },
  watch: {
    broadcast_message: function(newVal) { // bus message
      if (newVal && newVal.message) {
        console.log('broadcast_message:', this.broadcast_message);
        if (this.broadcast_message.message=='refresh') {
          this.loadRows();
        }
      }
    },
  },
  computed: {
    broadcast_message: function() { // bus message
      return { message: this.$store.state.broadcast_message, payload :this.$store.state.broadcast_message_payload };
    },
    current_page_records: function() {
      let s=(this.currentPage-1)*this.nrecbypage;
      let e=s+this.nrecbypage;
      return this.records.slice(s,e);
    },
    current_list_cols: function() {
      if(this.hasConfig()) return this.config.cols.filter( (c) => { return c.format!='hidden'; })
    },
    maxPages: function() {
      // let res=this.$store.getters.getRecordsList(this.dataid);
      let res=this.records;
      console.log('res',res)
      if (res) {
        return Math.ceil(res.length/this.nrecbypage);
      }
      return 0;
      // let l = this.current_page_records.length
      // if(l) return Math.ceil(l/this.nrecbypage)
      // return 0;
    },
    config: function() {
      return this.$store.getters.getObject(this.id).data;
    },
    getEditProps(){
      return this.$store.getters.getEditProps;
    },
  },
  mounted: function() {
    if(this.hasConfig()){
      console.log('mounted simpletable');
      
      if (this.config.nrecbypage) this.nrecbypage=this.config.nrecbypage;

      this.loadRows();
    }
  },
  methods: {
    loadNextPage: async function() {
      this.currentPage++;
    },
    loadPreviousPage: async function() {
      this.currentPage--;
    },
    loadPage: async function(p) {
      this.currentPage=p;
    },
    loadRows: async function() {
      console.log('--> loadRows')
      this.records=[];
      // get records
      console.log('get records:',this.config.dataid);      
      let data=this.$store.getters.getRecordsList(this.config.dataid);
      console.log('data',data);      

      if(data && data.records) this.records = data.records
      console.log('this.records',this.records)

      this.config.cols.forEach( c => { c['sorting'] = '' })
    },
    filter: function(v, type) {
      if (!v) return v;

      // TODO: locale
      switch(type) {
        case 'hidden':
          return;
        case 'date':
          var d=new moment(v);
          return d.format('DD/MM/YYYY');
        case 'datetime':
          var dt=new moment(v);
          return dt.format('DD/MM/YYYY');
        case 'decimal':
          return numeral(v).format('0,0');
        case 'currency':
          return numeral(v).format('$ 0,0.00');
        case 'alert':
          return '';
        case 'truncate':
            return v.substr(0,32);
      }
      return v;
    },
    getFieldValue: function(obj, path) {
      // eslint-disable-next-line no-redeclare
      for (var i=0, path=path.split('.'), len=path.length; i<len; i++){
        if (obj)
          obj = obj[path[i]];
      }
      return obj;
    },
    align: function(type) {
      switch(type) {
        case 'currency':
        case 'decimal':
          return 'right';
      }
      return 'left';
    },
    changeShowModal(){
      let newValue = !this.showModal;
      console.log(newValue, " changeShowModal, newValue");
      this.showModal = newValue;
      console.log(this.showModal, " changeShowModal, showModal");
    },
    closeModal(){
      this.showModal=false;
    },
    hasConfig() {
      //return this.config && 'actions' in this.config;
      if(this.config){
        return true
      }else return false;
    },
    isAdmin(){
      return this.$store.getters.isAdmin;
    },
    sortOrder(col) {
      console.log('--> sortOrder', col)
      this.config.cols.forEach( c => { if(c.id != col.id) c.sorting = '' })
      switch (col.sorting) {
        case '':
          col.sorting = 'up'
          this.sortingAsc = true
          this.orderby(col.fieldname)
          break
        case 'up':
          col.sorting = 'down'
          this.sortingAsc = false
          this.orderby(col.fieldname)
          break
        case 'down':
          col.sorting = ''
          this.loadRows()
          break
      }
    },
    orderby(column) {
      console.log('F --> orderby')
      console.log('column',column)

      // INDIVIDUA VALUES
      if(column.includes('.')) var parentAndField = column.split('.')
      var containsNum = false

      var infos = []
      for(var r of this.records) {
        for(var f of Object.keys(r)) {
          if(f == column) {
            infos.push(r[f])
            if(typeof(r[f]) == 'number') containsNum = true
            break
          } else if( column.includes('.') && f == parentAndField[0] ) {
            infos.push(r[parentAndField[0]][parentAndField[1]])
            if(typeof(r[parentAndField[0]][parentAndField[1]]) == 'number') containsNum = true
            break
          }
        }
      }
      console.log('infos',infos)

      // SORT VALUES
      console.log('sort')
      infos = infos.sort()
      if(containsNum) infos.sort(function(a, b){return a-b})
      if(!this.sortingAsc) {
        console.log('reverse')
        infos = infos.reverse()
      }
      console.log('infos ', infos)

      // SHOW SORTED VALUES
      let recordsNotSorted = this.records
      this.records = []

      var identifiers = []
      for(var i of infos) {
        for(var r of recordsNotSorted) {
          for(var f of Object.keys(r)) {
            var identifier
            if(r.Id) identifier = r.Id
            else console.log('manca identifier nel record',r)

            var col = column
            var info = r[f]

            if(column.includes('.')) {
              col = parentAndField[0]
              info = r[parentAndField[0]][parentAndField[1]]
            }
            
            if(f == col && i == info && !identifiers.includes(identifier)) {
              this.records.push(r)
              identifiers.push(identifier)
            }

          }
        }
      }
    },
    select: function(record, col) {
      console.log('select: ', record, col)
      var table = this.getFieldValue(record, col.link_object)
      var process = this.$store.getters.getProcess(this.config.process[table])
      var verb = this.$store.getters.getVerb(process, 'd')
      if(verb) this.$router.push(`/${process.id}/d/` + record[col.link_fieldname])
      else alert('Page Not Visible')
    },
  }
}
</script>

<style scoped>
.bloccato {
  position: sticky;
  top: 0;
  z-index: 1;
}
.is-text-overflow {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>