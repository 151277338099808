<template>
  <div id="app" >
    <bulmaLogin v-if="!$store.getters.connection_id" />
    <router-view v-if="$store.getters.connection_id"/>
    <!-- <button class="button" @click="enableNotifications">Abilita Notifiche</button>
    <button class="button" @click="sendNotification">manda Notifica</button> -->

  </div>
</template>

<script>
import apiclient from '@/apiclient.js'
import bulmaLogin from './components/bulma_login.vue'

export default {
  name: 'app',
  components: {
    bulmaLogin
  },
  data: function() {
    return {
      events:[
        { type:'header', subject:'Il Futuro!'},
        { type:'item', date:'07 Sept 2024', icon:'home', activity:'Coding', subject:'code...code...debug...code...'},
        { type:'item', date:'06 Sept 2024', icon:'trek', activity:'Hicking', subject:'Cammin cammmin cammmin'},
        { type:'header', subject:'Croazia...'},
        { type:'item', date:'05 Sept 2024', icon:'swim', activity:'Swimming', subject:'Sbrac nontoc pescet'},
        { type:'header', subject:'Vacanze'},
      ]
    };
  },
  mounted: async function() {

    const queryString = window.location.search;
    console.log('queryString',queryString);
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token')
    const user = urlParams.get('user')
    const logout = urlParams.get('logout')
    

    if (logout) {
      console.log('logout',logout);
      await this.$store.dispatch('logout');
      window.location.href='/';
    }

    if (!this.$store.getters.connection_id) { // login needed
      console.log('login needed');

      if (!token) {
        let res=await this.$store.dispatch('ssologin');
        console.log('url:', res);
        
        window.location.href=res.data;
      } else {
        console.log('token',token);
        console.log('user',user);
        this.$store.commit('SET_CONNECTION_ID', token);
        this.$store.commit('SET_USER_ID', user);
        // eslint-disable-next-line no-undef
        if (OpenReplay) OpenReplay.setUserID(user);
      }
    } else {
      // console.log('validate_login');      
      // let x=await this.$store.dispatch('validate_login');
      // console.log(x);

      let res=await this.$store.dispatch('myprofile');
      console.log('myprofile:', res.myprofile);
      this.$store.commit('SET_CONNECTION_ID', res.token);
      this.$store.commit('SET_USER_ID', res.myprofile.user);

      // eslint-disable-next-line no-undef
      if (OpenReplay) OpenReplay.setUserID(this.$store.state.userid);

    }
    this.enableNotifications();
    //console.log(await registration.pushManager.getSubscription(), "provolone")
  },
  methods: {

    test_search: async function() {
      console.log('test_search');

      let object='deal';
      let filters=[ { "propertyName": "associations.company", "operator": "EQ", "value": "18092725352" } ];

      try {
        let resp=await apiclient.search(this.$store.getters.connection_id, object, filters);
        console.log(resp);
        return true;
      } catch(err) {
        console.log('test_search failed');
        console.log(err);
        return false;
      }
      
    },
    async enableNotifications() {
      // const server = require('http').createServer();
      // server.setMaxListeners(20);

      console.log("--> enableNotifications");
      if ('Notification' in window && 'serviceWorker' in navigator) {
        console.log("request notifications");
        const permission = await Notification.requestPermission();
        
        if (permission === 'granted') {
          console.log("allow notifications");
          await this.subscribeToPushNotifications();
          console.log("allowed notifications");
        } else {
          console.error('Notification permissions denied.');
        }
      } else {
        console.error('Browser does not support push notifications.');
      }
    },
    async subscribeToPushNotifications() {
      console.log("registration started");
      const registration = await navigator.serviceWorker.register('/service-worker.js', {scope: '/'});;
      let getResult=await registration.pushManager.getSubscription();
      console.log('getSubscription result: ', getResult);
      console.log("subscription started");
      
      this.$store.dispatch("saveSubscription", { id: this.id });
    },
    sendNotification(){
      this.$store.dispatch("sendNotification");
    },
  
  
  }
}
</script>

<style>
</style>
