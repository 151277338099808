<template lang="html">

  <div class="modal is-active" ref="modal">
    <div class="modal-background"></div>
    <div class="modal-card" style="width:80%">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
        <button v-on:click="selected=-1" class="delete" aria-label="close" v-on:click.prevent="$emit('click', -1)"></button>
      </header>
  
      <section class="modal-card-body">
        <slot></slot>
      </section>
      <footer class="modal-card-foot" v-if="buttons">
        <div class="buttons">
          <button class="button" v-on:click="$emit('click', bindex)" v-for="(b, bindex) of buttons" v-bind:class="{'is-success': bindex==0, 'is-danger':bindex==2}" v-bind:key="b.label" :disabled="b.disabled">{{ b.label }}</button>
        </div>
      </footer>
  </div>
  </div>
  
</template>
  
<script>

export default {
  name: 'bulmaModal',
  computed: {},
  components: {

  },
  props: {
    title: String,
    id: String,
  },
  data() {
    return {
    }
  },
  watch: {
    '$store.getters.getProcessDepth': function(newVal, oldVal) {
      console.log('watch modal $store.state.getters.getProcessDepth', newVal, oldVal)
      if(newVal != oldVal) this.$store.dispatch('checkRequiredFields')
    }
  },
  computed: {
    buttons() {
      return this.$store.getters.getModalButtons
    }
  },
  mounted: function() {
    this.$store.dispatch('checkRequiredFields')
  },
  methods: {
  }
}
</script>

<style scoped>

</style>