<template>
  <bulmaModal
  v-bind:buttons="['Save', 'Cancel']"
  v-on:click="handleModalAction($event)"
  v-bind:title="'Set Table'"
  >
  <div v-if="toUpdate">
    <!-- Table Settings -->
    <div class="columns">
      <div class="column is-2">
        <div class="field">
          <label class="label">Title</label>
          <div class="control">
            <input
            class="input"
            type="text"
            v-model="toUpdate.title"
            placeholder="Title"
            />
          </div>
        </div>
      </div>
      <div class="column is-3">
        <div class="field">
          <label class="label">Associated Table</label>
          <div class="control">
            <input
            class="input"
            type="text"
            v-model="toUpdate.table"
            placeholder="Table Name"
            />
          </div>
        </div>
      </div>
      
      
      
      <div class="column is-2">
        <div class="field">
          <label class="label">Process</label>
          <div class="control">
            <input
            class="input"
            type="text"
            v-model="toUpdate.process"
            placeholder="Process of the Table"
            />
          </div>
        </div>
      </div>

      <!-- lookup_process -->

      <div class="column is-3">
        <div class="field">
          <label class="label">Lookup Process</label>
          <div class="control">
            <input
            class="input"
            type="text"
            v-model="toUpdate.lookup_process"
            placeholder="Lookup Process"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      
      <div class="column is-2">
        
        <div class="field checkbox-container">
          <label class="label">Insert</label>
          <div class="control">
            <input type="checkbox" v-model="toUpdate.caninsert" />
          </div>
        </div>
        
      </div>
      <div class="column is-2">
        <div class="field checkbox-container">
          <label class="label">Delete</label>
          <div class="control">
            <input type="checkbox" v-model="toUpdate.candelete" />
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="field checkbox-container">
          <label class="label">Checkbox</label>
          <div class="control">
            <input type="checkbox" v-model="toUpdate.checkbox" />
          </div>
        </div>
      </div>
      <!-- <div class="column">
        <button class="button" v-on:click="searchActions()">ciao</button>
        
      </div> -->
    </div>  
    <!-- Paths -->
    <div class="field">
      <label class="label">Paths</label>
      <div class="columns is-multiline">
        <div class="column is-4">
          <label>Create</label>
          <input
          class="input"
          type="text"
          v-model="toUpdate.path.create"
          placeholder="Path to Creation"
          />
        </div>
        <div class="column is-4">
          <label>Details</label>
          <input
          class="input"
          type="text"
          v-model="toUpdate.path.details"
          placeholder="Path to Details"
          />
        </div>
        <div class="column is-4">
          <label>Edit</label>
          <input
          class="input"
          type="text"
          v-model="toUpdate.path.edit"
          placeholder="Path to Edit"
          />
        </div>
      </div>
    </div>
    
    <!-- Actions -->
    <div class="field">
      <label class="label">Actions</label>
      <div class="box">
        <div class="columns is-multiline">
          
          <div class="column is-3">
            <div class="select">
              <select v-if="searchActions()" v-model="selectedAction">
                <option v-for="actionsPick in searchActions()" :key="actionsPick.id" :value="actionsPick.data.actions">{{ actionsPick.id }}</option>
              </select>
            </div>
          </div>
          
          <div class="column is-6"></div>
          
          <div class="column is-3">
            <button class="button is-pulled-right" v-on:click="actionCreator()" >New Actions</button>
            
          </div>
          
          
          
          <div class="column is-12">
            <div class="control">
              <textarea class="textarea" readonly :value="formattedActions"></textarea>
            </div>
          </div>
          
          
        </div>
        
      </div>
    </div>
    
    
    
    <bulmaModal
    v-bind:buttons="['OK']"
    v-on:click="handleActions()"
    v-bind:title="'Configura Actions'" v-if="showActionCreator">
    
    <div class="field">
      
      <label class="label">Actions</label>
      
      <div v-for="(action, index) in toUpdate.actions" :key="index" class="box">
        <div class="columns is-multiline">
          
          <div class="column is-3">
            
            <label class="label">Name</label>
            <input
            class="input"
            type="text"
            v-model="action.name"
            placeholder="Action Name"
            />
          </div>
          <div class="column is-3">
            <label class="label">Label</label>
            <input
            class="input"
            type="text"
            v-model="action.label"
            placeholder="Action Label"
            />
          </div>
          <div class="column is-2">
            <label class="label">Action</label>
            <input
            class="input"
            type="text"
            v-model="action.action"
            placeholder="Action Type"
            />
          </div>
          <div class="column is-2">
            <label class="label">Icon</label>
            <input
            class="input"
            type="text"
            v-model="action.icon"
            placeholder="Action Icon"
            />
          </div>
          <div class="column is-2 checkbox-container">
            
              <label class="label">Default</label>
              <div class="control">
                <input type="checkbox" v-model="action.default" /> 
              </div>
       
            
          </div>
          <div class="column is-2">
            
            
          </div>
        </div>
        <button class="button is-danger" @click="removeAction(index)">Remove Action</button>
      </div>
      <button class="button is-primary" @click="addAction">Add Action</button>
    </div>
    
    
    
    
    
    
  </bulmaModal>
  
  
  
  
  
  <!-- Lists -->
  <!-- List Management -->
  <div class="field">
    <label class="label">Lists</label>
    <div v-for="(list, index) in toUpdate.lists" :key="index" class="box">
      <!-- basic configuration of lists-->
      <div class="columns is-multiline">
        <div class="column is-3">
          <label class="label">Label</label>
          <input
          class="input"
          type="text"
          v-model="list.label"
          placeholder="List Label"
          />
        </div>
        <div class="column is-3">
          <label class="label">Name</label>
          <input
          class="input"
          type="text"
          v-model="list.name"
          placeholder="List Name"
          />
        </div>
        
        <div class="column is-2">
          <label class="label">Limit</label>
          <input
          class="input"
          type="number"
          v-model="list.limit"
          placeholder="Limit"
          />
        </div>
        
        
        <div class="column  checkbox-container">
          <label class="checkbox label">Default</label>
          
          <div class="control mt-4">
            <input type="checkbox" v-model="list.default" /> 
          </div>
          
          
        </div>
        <div class="column checkbox-container">
          <label class="checkbox label">Shared</label>
          <div class="control mt-4">
            <input type="checkbox" v-model="list.shared" /> 
          </div>
          
          
        </div>
        <div class="column is-1">
          <button class="button" v-on:click="pushNewList(list)">
            <span class="icon is-small">
              <i class="fa-solid fa-clone"></i>
            </span>
            
          </button>
          
        </div>
      </div>
      
      <!-- Management of Filters -->
      <div class="field">
        <label class="label">Filters</label>
        <div v-for="(filter, filterIndex) in list.filters" :key="filterIndex" class="box">
          <div class="columns is-multiline">
            <div class="column is-3">
              <label>Field name</label>
              <input
              class="input"
              type="text"
              v-model="filter.fieldname"
              placeholder="field name of filter"
              />
            </div>
            <div class="column is-2">
              
              <div class="ml-3">
                <label>Operator</label>
              </div>
              <div class="select">
                  <select v-model="filter.operator" class="type">
                      <option value="EQ">== (with apostrophes)</option>
                      <option value="NEQ">!= (with apostrophes)</option>
                      <option value="NO_STRING_EQ">==</option>
                      <option value="NO_STRING_NEQ">!=</option>
                      <option value="LT"><</option>
                      <option value="LTE"><=</option>
                      <option value="GT">></option>
                      <option value="GTE">>=</option>
                      <option value="LIKE">like</option>
                      <option value="HAS_PROPERTY">has props</option>
                      <option value="NOT_HAS_PROPERTY">has no props</option>
                      <option value="INCLUDES">includes</option>
                      <!--NO_STRING_EQ, NO_STRING_NEQ, INCLUDES-->
                      
                  </select>
              </div>
              
              <!-- <label>Operator</label>
              <input
              class="input"
              type="text"
              v-model="filter.operator"
              placeholder="operator of filter"
              /> -->
            </div>
            <div class="column is-3">
              <label>record field</label>
              <input
              class="input"
              type="text"
              v-model="filter.recordField"
              placeholder="record of filter"
              />
            </div>
            <div class="column is-3">
              <label>Obtained value</label>
              <input
              class="input"
              type="text"
              v-model="filter.value"
              placeholder="Value of filter"
              />
            </div>
            <div class="column is-1 checkbox-container">
              <label class="checkbox"> Is mine</label>
              <div class="control mt-1">
                <input type="checkbox" v-model="filter.mine" /> 
              </div>
            </div>
            <div class="column is-8"></div>
            <div class="column is-3">
              <label>Property</label>
              <input
              class="input"
              type="text"
              v-model="filter.propertyName"
              placeholder="property name of filter"
              />
            </div>
            <div class="column is-1">
              <button class="button is-danger mt-5" @click="removeListFilter(index, filterIndex)">
                <span class="icon is-small">
                  <i class="fas fa-trash"></i>
                </span>
              <!--   Remove Filter -->
              </button>
            </div>
            
          

            
          </div>
          <!-- <button class="button is-danger" @click="removeListFilter(index, filterIndex)">
            <span class="icon is-small">
              <i class="fas fa-trash"></i>
            </span>
    
          </button> -->
        </div>
        <button class="button is-primary" @click="addListFilter(index)">Add Filter</button>
      </div>
      
      <!-- Management of Order) -->
      <div class="field">
        <label class="label">Orders</label>
        
        <div class="columns is-multiline">
          <div 
          v-for="(order, orderIndex) in list.order" 
          :key="orderIndex" 
          class="column is-6"
          >
          <div class="box">
            <div class="columns">
              <div class="column is-6">
                <label>Field name</label>
                <input
                class="input"
                type="text"
                v-model="order.fieldname"
                placeholder="field name of order"
                />
              </div>
              <div class="column is-6">
                
                <div>
                  <label>Operator</label>
                </div>
                <div class="select">
                    <select v-model="order.operator">
                        <option value="ASC">Ascendant</option>
                        <option value="DESC">Descending</option>
                        <option value="NULLS FIRST">Nulls first</option>
                        <option value="NULLS LAST">Nulls last</option>
                        
                        
                    </select>
                </div>
          
              </div>
              <!-- <div class="column is-6">
                <label>Operator</label>
                <input
                class="input"
                type="text"
                v-model="order.operator"
                placeholder="operator of order"
                />
              </div> -->
            </div>
            <button 
            class="button is-danger" 
            @click="removeListOrder(index, orderIndex)"
            >
            Remove Order
          </button>
        </div>
      </div>
    </div>
    
    
    
    
    <button class="button is-primary" @click="addListOrder(index)">Add Order</button>
  </div>
  
  
  <!-- Management Cols -->
  <div class="field">
    <label class="label">Columns</label>
    <div class="box">
      <label class="label">Default Value</label>
      <div class="columns is-multiline">
        <div class="column is-3">
          <label>Label</label>
          <input
          class="input"
          type="text"
          v-model="columnsDefaultValue.label"
          placeholder="Column Label"
          />
        </div>

        <div class="column is-3">
          <label>Field</label>
          <input
          class="input"
          type="text"
          v-model="columnsDefaultValue.fieldname"
          placeholder="Field Name"
          />
        </div>

        <div class="column is-3">
          <label>id</label>
          <input
          class="input"
          type="text"
          v-model="columnsDefaultValue.id"
          placeholder="Column ID"
          />
        </div>
       
        
      </div>
      <div class="columns">
        <div class="column is-3">
          <label>Format</label>
          <input
          class="input"
          type="text"
          v-model="columnsDefaultValue.format"
          placeholder="Column Format"
          />
        </div>
        <div class="column is-3">
          <label>Link name</label>
          <input
          class="input"
          type="text"
          v-model="columnsDefaultValue.link_fieldname"
          placeholder="link field name"
          />
        </div>
      
      </div>
    </div>
    <div v-for="(col, colIndex) in list.cols" :key="colIndex" class="box">
      <div class="columns is-multiline">
        <div class="column is-3">
          <label>Label</label>
          <input
          class="input"
          type="text"
          v-model="col.label"
          placeholder="Column Label"
          />
        </div>

        <div class="column is-3">
          <label>Field</label>
          <input
          class="input"
          type="text"
          v-model="col.fieldname"
          placeholder="Field Name"
          />
        </div>

        <div class="column is-3">
          <label>id</label>
          <input
          class="input"
          type="text"
          v-model="col.id"
          placeholder="Column ID"
          />
        </div>
       
        
      </div>
      <div class="columns">
        <div class="column is-3">
          <label>Format</label>
          <input
          class="input"
          type="text"
          v-model="col.format"
          placeholder="Column Format"
          />
        </div>
        <div class="column is-3">
          <label>Link name</label>
          <input
          class="input"
          type="text"
          v-model="col.link_fieldname"
          placeholder="link field name"
          />
        </div>
        <div class="column is-3">
          <button class="button is-danger mt-5 ml-6" @click="removeListCol(index, colIndex)">
            <span>Remove Column</span>
            <span class="icon is-small">
              <i class="fas fa-trash"></i>
            </span>

          </button>
        </div>
      </div>
      <!-- <button class="button is-danger" @click="removeListCol(index, colIndex)">
        Remove Column
      </button> -->
    </div>
    <button class="button is-primary" @click="addListCol(index)">Add Column</button>
  </div>
  
  <!-- Button for remove list -->
  <button class="button is-danger" @click="removeList(index)">Remove List</button>
</div>
<!-- Button for add a new list -->
<button class="button is-primary" @click="addList">Add List</button>
</div>

<!-- Default Columns -->
<div class="field">
  <label class="label">Default Columns</label>
  <div v-for="(col, index) in toUpdate.default_cols" :key="index" class="box">
    <div class="columns is-multiline">
      <div class="column is-3">
        <label>ID</label>
        <input
        class="input"
        type="text"
        v-model="col.id"
        placeholder="Column Id"
        />
      </div>
      <div class="column is-3">
        <label>Label</label>
        <input
        class="input"
        type="text"
        v-model="col.label"
        placeholder="Column Label"
        />
      </div>
      <div class="column is-2">
        <label>Field</label>
        <input
        class="input"
        type="text"
        v-model="col.fieldname"
        placeholder="Column Field Name"
        />
      </div>
      <div class="column is-2">
        <label>Format</label>
        <input
        class="input"
        type="text"
        v-model="col.format"
        placeholder="Column Format"
        />
      </div>
      <div class="column is-2">
        <label>link name</label>
        <input
        class="input"
        type="text"
        v-model="col.link_fieldname"
        placeholder="link field name"
        />
      </div>
    </div>
    <button class="button is-danger" @click="removeCol(index)">Remove Column</button>
  </div>
  <button class="button is-primary" @click="addCol">Add Column</button>
</div>
</div>
</bulmaModal>
</template>





<script>
export default {
  name: "bulmaTableProps",
  props: {
    config: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      toUpdate: {
        table: "",
        title: "",
        process: "",
        lookup_process: "",
        caninsert: false,
        candelete: false,
        checkbox: false,
        path: { create: "", details: "", edit: "" },
        actions: [],
        lists: [],
        default_cols: [],
      },
      actions: [],
      selectedAction: null,
      showActionCreator: false,
      columnsDefaultValue: {
        label: "",
        name: "",
        format: "",
        fieldname: "",
        link_fieldname: "",
      }, 
    };
  },
  created() {
    this.initializetoUpdate();
  },
  computed: {
    formattedActions() {
      return this.toUpdate.actions ? JSON.stringify(this.toUpdate.actions, null, 2) : '';
    }
  },
  watch: {
    selectedAction(newAction) {
      this.toUpdate.actions = newAction;
    }
  },
  methods: {
    actionCreator(){
      this.toUpdate.actions = [{
        name: "",
        label: "",
        action: "",
        default: false,
        icon: ""
      }];
      this.showActionCreator = true;
      
    },
    setAction(event){
      toUpdate=update;
      console.log(toUpdate, " set action ", update)
    },
    searchActions(){
      
      //takes all the bulmaActions objects
      let toReturn=(this.$store.getters.getObjByType("bulmaActions")).flat();
      for(let i = 0; i < toReturn.length; i++){
        console.log(toReturn[i].data)
        if(!toReturn[i].data){
          toReturn.splice(i, 1);
        }
        if(!toReturn[i].data.actions){
          toReturn.splice(i, 1);
        }
        
      }
      console.log(toReturn, "search actions");
      return toReturn;
    },
    initializetoUpdate() {
      if (this.config) {
        this.toUpdate = {
          ...this.toUpdate,
          table: this.config  && this.config.table ? this.config.table :  "",
          title: this.config && this.config.title? this.config.title : "",
          process: this.config && this.config.process ? this.config.process : "",
          lookup_process: this.config && this.config.lookup_process ? this.config.lookup_process : "",
          caninsert: this.config && this.config.caninsert ? this.config.caninsert : false,
          candelete: this.config && this.config.candelete ? this.config.candelete : false,
          checkbox: this.config && this.config.checkbox ? this.config.checkbox : false,
          path: this.config && this.config.path ? this.config.path : { create: "", details: "", edit: "" },
          actions: this.config && this.config.actions ? this.config.actions : [],
          lists: this.config && this.config.lists ? this.config.lists : [],
          default_cols: this.config && this.config.default_cols ? this.config.default_cols : [],
        };
      }
    },
    addAction() {
      this.toUpdate.actions.push({
        name: "",
        label: "",
        action: "",
        default: false,
        icon: "",
      });
    },
    removeAction(index) {
      this.toUpdate.actions.splice(index, 1);
    },
    addList() {
      this.toUpdate.lists.push({
        label: "",
        name: "",
        default: false,
        shared: false,
        cols: [
        {
          label: "",
          fieldname: "",
          format: "",
        },
        ],
        filters: [],
        order: [],
      });
    },
    removeList(index) {
      this.toUpdate.lists.splice(index, 1);
    },
    addListCol(listIndex) {
      if (!this.toUpdate.lists[listIndex].cols) {
        this.toUpdate.lists[listIndex].cols = [];
      }
      this.toUpdate.lists[listIndex].cols.push(this.columnsDefaultValue);
      /*
      {
        id: "",
        label: "",
        fieldname: "",
        format: "",
        link_fieldname: "",
      }
      */
    },
    removeListCol(listIndex, colIndex) {
      this.toUpdate.lists[listIndex].cols.splice(colIndex, 1);
    },
    addListFilter(listIndex) {
      this.toUpdate.lists[listIndex].filters.push({
        fieldname: "",
        operator: "",
        value: "",
        mine: false,
        recordField: "",
      });
    },
    removeListFilter(listIndex, filterIndex) {
      this.toUpdate.lists[listIndex].filters.splice(filterIndex, 1);
    },
    addListOrder(listIndex) {
      this.toUpdate.lists[listIndex].order.push({
        fieldname: "",
        operator: "",
      });
    },
    removeListOrder(listIndex, orderIndex) {
      this.toUpdate.lists[listIndex].order.splice(orderIndex, 1);
    },
    addCol() {
      this.toUpdate.default_cols.push({
        label: "",
        fieldname: "",
        format: "",
      });
    },
    removeCol(index) {
      this.toUpdate.default_cols.splice(index, 1);
    },
    async handleModalAction(buttonIndex) {
      if (buttonIndex === 0) {
        this.toUpdate

        //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- finisci controlli di nomi duplicati =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
        const seen = new Set();
        for (const obj of this.toUpdate.lists) {
          if (seen.has(obj["name"])) {
            alert("Duplicated name!");
            return true; // Duplicato trovato

          }
          seen.add(obj["name"]);
        }
        // if(){
        if(!this.toUpdate.candelete) delete this.toUpdate.candelete;
        // }
        this.$store.commit('SET_CONFIG_OBJECT', { id: this.id, type: "bulmaTable", data: this.toUpdate });
        this.$store.dispatch("setObjectDB", { id: this.id, data: this.toUpdate });
        
        this.$emit("refresh");
      } else {
        this.$emit("close");
      }
    },
    async handleActions(buttonIndex) {
      
      this.showActionCreator=false;
      
    },
    pushNewList(listToPush){
      console.log("entered")
      if(this.toUpdate && this.toUpdate.lists){
        this.toUpdate.lists.push(listToPush);
        console.log("bulma_tableprops --> pushNewList --> pushed");
      }else console.log("bulma_tableprops --> pushNewList --> not pushed");
    }
   
  },
};
</script>


<style scoped>
  .checkbox-container input[type="checkbox"] {
    transform: scale(2.1); /* Aumenta la dimensione della checkbox */
    margin-left: 8px; /* Spazio tra checkbox e il testo */
}
</style>
