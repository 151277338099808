import Vue from 'vue'
import Vuex from 'vuex'
// import metaremote from '@/metaremote.js'
import apiclient from '@/apiclient.js'
// import { platform } from 'chart.js'

Vue.use(Vuex)

function setFieldValue(obj, path, val) {
  let elements=path.split('.');
  let n_elements=elements.length;
  var last=obj;
  for (let i=0; i<(n_elements-1); i++) {
    if (!last[elements[i]]) last[elements[i]]={};
    last=last[elements[i]];
  }
  Vue.set( last, elements[n_elements-1], val);
//  last[elements[n_elements-1]]=val;

  return obj;
}

export default new Vuex.Store({
  state: {
    app: 'SalesPaddy 4.0',
    logo: 'app.png',
    busy: false,

    login:false,
    connection:'',
    userid:'',

    config: { },
    editmode: { },
    romode: { },

    global_state_process:"",
    global_state_verb:"", 
    global_state_pageid:"",

    lookup_field: '',
    lookup_field_value: '',

    // bus message
    message:"",
    message_payload:{},

    broadcast_message:"",
    broadcast_message_payload:{},

    // one record at time
    /*
    record_id: "",
    record_data: { },
    record_assoc: { },
    record_state: "",
    */

    records_id: { },
    records_data: { },
    records_assoc: { },
    records_state: { },
    records_list: { },

    software_states: {},

    database: new Map(),

    process_stack: [],
    google_map_loader: undefined,
    myprofile:'',
    editprops:false,
    /*
      NEW: record empty
      EDIT: record in edit mode
      READY: record loaded
    */

    emptyRequiredFields: false,
    advancedFilteringProperties: [],
    approvalInfo: {}
  },
  getters: {
    isAdmin: function(state) { return state.myprofile.role=="Admin";},
    appname: function(state) { return state.app },

    connection_id: function(state) { 
      if (state.connection) return state.connection 
      let previous=localStorage.getItem('salespaddy_id');
      if (previous) {
        state.connection=previous;
        state.userid=localStorage.getItem('salespaddy_userid');
        return state.connection
      }
      return; 
    },
    logged: function(state) { return state.login },

    getConfig: (state) =>  (id) => {
      return state.config[id];
    },
    getEditMode: (state) =>  (id) => {
      return state.editmode[id];
    },
    getROMode: (state) =>  (id) => {
      return state.romode[id];
    },
    getProcess: (state) =>  (id) => {
      if (!state.config.processes) return {}
      return state.config.processes.find( (v) => { if (id) return v.id==id; else return v.id=="default"; } );
    },
    getVerb: () =>  (process, verb) => {
      if (!process.verbs) return {}
      return process.verbs.find( (v) => { if (verb) return v.id==verb; else return v.id=="default"; } );
    },
    getPage: (state) =>  (id) => {
      if (!state.config.pages) return {}
      return state.config.pages.find( (v) => { return v.id==id} );
    },
    getObject: (state) =>  (id) => {
      if (!state.config.objects) return {}
      return state.config.objects.find( (v) => { return v.id==id} );
    },
    getSWState: (state) =>  (name) => {
      return state.software_states[name]
    },
    getRecordsList: (state) =>  (id) => {
      return state.records_list[id];
    },
    getRecordData: function(state) { 
      if (!state.config.processes) {
        console.log('Panico! Non ho i processi')
        return {};
      }
      if (state.process_stack.length > 0) {
        console.log('Ho qualcosa nello stack')
        let s = state.process_stack[state.process_stack.length - 1].processId
        return state.records_data[s]
      }
      return state.records_data[state.global_state_process];
    },
    getRecordState: function(state) { 
      if (!state.config.processes) return '';
      if (state.process_stack.length > 0) {
        let s = state.process_stack[state.process_stack.length - 1].processId // or object id?
        return state.records_state[s]
      }
      return state.records_state[state.global_state_process];
    },
    getRecordId: function(state) {
      if (!state.config.processes) return '';
      return state.records_id[state.global_state_process];
    },
    getRecordAssoc: (state) =>  (id) => {
      return state.records_assoc[id];
    },
    getProcessStatus: function(state) { return state.process_stack[state.process_stack.length-1] },

    copyProcessStatus: function(state) { return { ...state.process_stack[state.process_stack.length-1] } },

    getProcessDepth: function(state) { return state.process_stack.length },

    isUsed: (state) => (id) => {
      console.log("id ricevuto da isUsed: ", id)
   
      //context.commit('SET_CONFIG', nmPayload);
      if (!state.config.objects) return false;
      
      for (let i = 0; i < state.config.objects.length; i++) {
        const obj = state.config.objects[i];
        
        if (obj.type === "bulmaRows" || obj.type === "bulmaSections") {
          if (obj.data && obj.data.rows && obj.data.rows.find(v => v.id === id)) {
            return true;
          }
        } else if (obj.type === "bulmaSubTab") {
          if (obj.tabs && obj.tabs.find(v => v.id === id)) {
            return true;
          }
        } else if (obj.type === "bulmaContainer") {
          console.log(" trovato container: ", obj)
          if (obj.data && obj.data.cells && obj.data.cells.find(v => v.id === id)) {
            return true;
          }
        }
      }
      
      return false;
    },
    getEditProps: function(state){
      return state.editprops;
    },
    getObjByType: (state) => (type) => {
      //ritorno l'array filtrato in base al type richiesto
      return state.config.objects.filter(obj => obj.type == type);
    },
    getModalButtons(state, getters) {
      let buttons = []

      var processStatus = getters.getProcessStatus
      if(processStatus && processStatus.verbId == 'a') {
        if(getters.getRecordData.enableApproval__c
          && !getters.getRecordData.Approval_requested__c) buttons.push({ label: 'Submit', disabled: false })
      } else buttons.push({ label: 'Save', disabled: state.emptyRequiredFields}) 

      if(getters.canApprove) buttons.push({ label: 'Confirm', disabled: Object.keys(state.approvalInfo).length == 0 })

      buttons.push({ label: 'Cancel', disabled: false })

      if(processStatus && processStatus.candelete) buttons.push({ label: 'Delete', disabled: false })
      return buttons
    },
    getQueryOperator: (state) => (type) => {
      switch(type) {
        case 'picklist':
          return 'EQ'
        case 'text':
          return 'LIKE'
        case 'date':case 'checkbox':
          return 'NO_STRING_EQ'
      }
    },
    getadvancedFilteringProperties: (state) => (prop) => {
      console.log('getadvancedFilteringProperties')
      for(var f of state.advancedFilteringProperties) {
        if(f.fieldname == prop) return (f.value)
      }
      return ''
    },
    isApprover(state, getters) {
      // if(getters.isAdmin) return true 
      if(getters.getRecordData && state.myprofile.contactId == getters.getRecordData.SalesPaddy_Approver__c) return true
      return false
    },
    canApprove(state, getters) {
      if(getters.isApprover && getters.getRecordData.Approval_requested__c && getters.getRecordData.Approval_status__c == 'PENDING') return true
      return false
    }
  },
  mutations: {
    SET_PROCESS_STATUS_DATA: function(state, payload) { 
      let s=state.process_stack[state.process_stack.length-1];
      console.log('s',s);
      
      Vue.set(s, payload.name, payload.value);
    },
    PUSH_PROCESS_STATUS: function(state, payload) { 
      state.process_stack.push(payload);
    },
    POP_PROCESS_STATUS: function(state) { 
      console.log('POP_PROCESS_STATUS')
      return state.process_stack.pop();
    },
    SET_FIELD_VALUE: function(state, payload) { 
      if (!state.config.processes) return {};

      if (state.process_stack.length>0) {
        let s=state.process_stack[state.process_stack.length-1];
        console.log('SET_FIELD_VALUE process', s, payload);

        setFieldValue(state.records_data[s.processId], payload.fieldname,  payload.fieldvalue);
      } else {
        console.log('SET_FIELD_VALUE normal', state.global_state_process, payload);
        setFieldValue(state.records_data[state.global_state_process], payload.fieldname,  payload.fieldvalue);
      }
    },
    SET_BUSY: function(state, payload) { state.busy=payload },

    SET_CONNECTION_ID: function(state, payload) { 
      state.connection = payload;
      localStorage.setItem('salespaddy_id', payload);
    },
    SET_USER_ID: function(state, payload) { 
      state.userid = payload;
      localStorage.setItem('salespaddy_userid', payload);
    },
    SET_LOGIN_STATE: function(state, payload) { state.login = payload; },

    SET_GLOBAL_CONFIG: function(state, payload) { 
      state.config=payload;
      console.log("payload global config ", state.config.database);
      if (state.config.database) {
        state.config.database.forEach( (d) => {
          var fields=new Map();
          d.fields.forEach( (f) => {
            fields.set( f.name, f);
          })
          state.database.set( d.table, fields );
        })
      }
      // if (payload.objects) {
      //   payload.objects.forEach((obj) => {
      //     Vue.set(state.editmode, obj.id, false); // Imposta editmode a false per ogni componente
      //   });
      // }
    },
    SET_CONFIG: function(state, payload) { 
      Vue.set(state.config, payload.id, payload.cfg);
    },
    SET_CONFIG_OBJECT: function(state, payload) { 
    
      let i=state.config.objects.findIndex( (obj) => { return obj.id==payload.id; } );
      console.log(i, "index of component to update", i>=0);
      if (i>=0) {
        state.config.objects.splice(i, 1);
        state.config.objects.splice(i, 0, payload );
      } else {
        state.config.objects.push( payload );
 
      }
      
     
    },
    SET_EDITMODE: function(state, payload) { Vue.set(state.editmode, payload.id, payload.editmode); },
    SET_ROMODE: function(state, payload) { Vue.set(state.romode, payload.id, payload.romode); },

   

    // id is the name of the component
    SET_RECORDS_LIST: function(state, payload) { Vue.set(state.records_list, payload.id, payload.data); },

    // id is the name of the component
    SET_RECORDS_ID: function(state, payload) { 
      console.log('SET_RECORDS_ID', payload);
      Vue.set(state.records_id, payload.id, payload.data); },

    INIT_RECORDS_DATA: function(state, id) { 
      console.log('INIT_RECORDS_DATA', id);
      let data={};

      let pconfig=state.config.processes.find( (v) => { if (id) return v.id==id; else return v.id=="default"; } );
      let fields=state.database.get(pconfig.table);
      fields.forEach( (f) => {
        if (!f.readonly) {
          data[f.name]=f.default;
        }
      })

      Vue.set(state.records_data, id, data ); },

    SET_RECORD_ERRORS: function(state, payload) { 
        console.log('SET_RECORD_ERRORS',payload);
    },
    SET_RECORDS_DATA: function(state, payload) { 
      console.log('SET_RECORDS_DATA',payload);
      Vue.set(state.records_data, payload.id, payload.data); },
    SET_RECORDS_STATE: function(state, payload) { 
      console.log('SET_RECORDS_STATE',payload);
      Vue.set(state.records_state, payload.id, payload.state); },
    SET_RECORDS_ASSOC: function(state, payload) { Vue.set(state.records_assoc, payload.id, payload.table, payload.data); },

    SET_GLOBAL_STATE_PROCESS: function(state, payload) { state.global_state_process=payload },
    SET_GLOBAL_STATE_VERB: function(state, payload) { state.global_state_verb=payload },
    SET_GLOBAL_STATE_PAGEID: function(state, payload) { state.global_state_pageid=payload },

    POST_MESSAGE: function(state, payload) { state.message=payload.message; state.message_payload=payload.payload },
    CLEAR_MESSAGE: function(state) { state.message=""; state.message_payload="" },

    BROADCAST_MESSAGE: function(state, payload) { 
      console.log('BROADCAST_MESSAGE',payload.message)
      state.broadcast_message=payload.message; state.broadcast_message_payload=payload.payload 
    },
    CLEAR_BROADCAST: function(state) { state.broadcast_message=""; state.broadcast_message_payload="" },

    SET_LOOKUP_FIELD: function(state, payload) { state.lookup_field=payload },
    SET_LOOKUP_VALUE: function(state, payload) { state.lookup_field_value=payload },

    SET_GOOGLE_MAP_LOADER: function(state, payload) { state.google_map_loader=payload },
    SET_MYPROFILE: function(state, payload) { state.myprofile=payload },
    
    // REMOVE_RECORDS_DATA: function(state, payload) { 
    //   console.log('REMOVE_RECORDS_DATA',payload);
    //   state.records_data.delete(id)
    //   // Vue.set(state.records_data, payload.id, payload.data);
    // },
    
    UPDATE_CONFIG_OBJECTS(state, updatesArray) {
      // controllo che che `config.objects` sia inizializzato come array
      if (!Array.isArray(state.config.objects)) {
        state.config.objects = [];
      }
  
      updatesArray.forEach(update => {
        const { id, type, data } = update; // Estrai i campi `id`, `type` e `data`
  
        // Cerca un oggetto con il valore `id` corrispondente
        const existingObject = state.config.objects.find(obj => obj.id === id);
  
        if (existingObject) {
          // Se l'oggetto esiste, aggiorna `type` e `data`
          existingObject.type = type;
          existingObject.data = data;
        } else {
          // Se l'oggetto non esiste, aggiungilo all'array
          state.config.objects.push({ id, type, data });
        }
      });
    },
    SET_EDIT_PROPS(state, status){
      state.editprops = status;
    },
    SET_EMPTYREQUIREDFIELDS(state, status) { 
      console.log('SET_EMPTYREQUIREDFIELDS', status)
      state.emptyRequiredFields = status 
    },
    SET_ADVANCEDFILTERINGPROPERTIES(state, payload) {
      console.log('SET_ADVANCEDFILTERINGPROPERTIES', payload)
      var changed = false
      state.advancedFilteringProperties.forEach( f => {
        if(f.fieldname == payload.fieldname) {
          f.value = payload.value
          changed = true
        }
      })
      if(!changed) state.advancedFilteringProperties.push(payload) 
    },
    CLEAR_ADVANCEDFILTERINGPROPERTIES(state) {
      console.log('SET_ADVANCEDFILTERINGPROPERTIES')
      state.advancedFilteringProperties = [] 
    },
    REMOVE_ONE_ADVANCEDFILTERINGPROPERTIES(state, payload) {
      console.log('REMOVE_ONE_ADVANCEDFILTERINGPROPERTIES')
      var index = -1
      state.advancedFilteringProperties.forEach( (f, i) => {
        if(f.fieldname == payload.fieldname) index = i
      })
      state.advancedFilteringProperties.splice(index, 1)
    },
    SET_APPROVAL_INFO(state, payload) { state.approvalInfo = payload },
    CLEAR_APPROVAL_INFO(state) { state.approvalInfo = {} }
  },
  modules: {
  },
  actions: {
    checkRequiredFields: function(context) {
      console.log('--> checkRequiredFields')
      var process = context.getters.getProcessStatus
      let table 
      if(process && process.table) table = context.state.database.get(process.table);
      if(table) {
        var record = context.getters.getRecordData
        var tmp = false
        for(var f of table.keys()) {
          if(table.get(f).required && !record[f]) {
            console.log(f, record[f])
            context.commit('SET_EMPTYREQUIREDFIELDS', true)
            tmp = true
            break
          }
        }
        if(!tmp) context.commit('SET_EMPTYREQUIREDFIELDS', false)
      } else context.commit('SET_EMPTYREQUIREDFIELDS', false)
    },
    googleMapLoader: async function(context, payload ) {
      if (!context.state.google_map_loader) {
        console.log('store: loading loader');
        
        let loader = new payload.loader({ apiKey: payload.apiKey, version: "beta", });
        // let loader = new payload.loader({ apiKey: payload.apiKey, version: "weekly", });
        context.commit('SET_GOOGLE_MAP_LOADER', loader);
      }
      return context.state.google_map_loader;
    },
    ssologin: async function(context, payload) {
      console.log('action login', payload);
      
      context.commit('SET_CONNECTION_ID', '');
      context.commit('SET_LOGIN_STATE', false);

      let resp=await apiclient.ssologin();
      return resp;
    },
    myprofile: async function(context) {
      let resp=await apiclient.myprofile(context.getters.connection_id);
      console.log("myprofile", resp.myprofile);
      context.commit('SET_MYPROFILE', resp.myprofile);

      return resp;
    },
    

    login: async function(context, payload) {
      console.log('action login', payload);
      
      context.commit('SET_CONNECTION_ID', '');
      context.commit('SET_LOGIN_STATE', false);

      try {
        let resp=await apiclient.login(payload.email, payload.myid_token);
        console.log(resp);
        if (resp.token) {
          context.commit('SET_CONNECTION_ID', resp.token);
          context.commit('SET_LOGIN_STATE', true)
          return true;  
        } else {
          return resp;
        }
      } catch(err) {
        console.log('login failed');
        return false;
      }
    },
    validate_login: async function(context) {
      console.log('test_validate');
      
      try {
        let resp=await apiclient.validate(context.getters.connection_id);
        console.log(resp);
        context.commit('SET_CONNECTION_ID', resp.token);
        context.commit('SET_LOGIN_STATE', true)
        return true;
      } catch(err) {
        console.log('login failed');
        console.log(err);
        
        return false;
      }
      
    },
    getConfigFromMeta: async function(context) {

      if (context.getters.config.database) {
        console.log('getConfigFromMeta: configuration alrady present, skipped');        
        return;
      }

      context.commit('SET_BUSY', true);
      
      try {
        let resp=await apiclient.getmeta(context.getters.connection_id);
        console.log(resp);
        context.commit( 'SET_GLOBAL_CONFIG', resp );

        context.commit('SET_BUSY', false);
        return resp;

      } catch(err) {
        console.log('getConfigFromMeta failed');
        console.log(err);
        
        context.commit('SET_BUSY', false);
        return false;
      }
      
    },
    /*
        {
          "total": 1,
          "results": [
            {
              "id": "59621911167",
              "properties": {
                "hs_createdate": "2024-09-05T06:16:18.738Z",
                "hs_lastmodifieddate": "2024-09-05T06:16:18.738Z",
                "hs_object_id": "59621911167"
              },
              "createdAt": "2024-09-05T06:16:18.738Z",
              "updatedAt": "2024-09-05T06:16:18.738Z",
              "archived": false
            }
          ]
        }
    */
    load_records: async function(context, payload) {
      console.log('load_records', payload);

      let p=[];
      console.log(payload.cols);
      payload.cols.forEach( f => {
        if (f.fieldname!='id') p.push(f.fieldname);
      });

      let record=context.getters.getRecordData;
  
      if (payload.filters) {
        payload.filters.forEach( f => {
          if (f.recordField && record) f.value=record[f.recordField];
        });
      }

      let res=await context.dispatch('search_records', { id: payload.id, object: payload.tablename, properties:p, filters: payload.filters, orders: payload.orders, groups: payload.groups, offset: payload.offset, limit: payload.limit, childQuery: payload.childQuery });

      return res;
    },
    
    search_records: async function(context, payload) {
      console.log('search_records', payload);
      context.commit('SET_BUSY', true);
      
      try {
        let resp=await apiclient.search(context.getters.connection_id, payload.object, payload.properties, payload.filters, payload.orders, payload.groups, payload.offset, payload.limit, payload.childQuery );
        console.log(resp);

        if(resp.error) {
          console.log('ERRORE: '+resp.error[0].errorCode+': '+resp.error[0].message)
          context.dispatch('showError', { error: resp.error })
          return false
        }

        if (resp && resp.data.records) {
          for(var r of resp.data.records) {
            r['show'] = true
          }
          // TODO: hubspot version for totals and pagination
          context.commit('SET_RECORDS_LIST', {id: payload.id, data: { records: resp.data.records, totalRecords: resp.data.totalSize, complete: resp.data.done, nextPage: resp.data.nextRecordsUrl } });
        }
        context.commit('SET_BUSY', false);
        return resp;

      } catch(err) {
        console.log('search_records failed', payload);
        console.log(err);
        
        context.commit('SET_BUSY', false);
        return false;
      }
      
    },
    locate_records: async function(context, payload) {
      console.log('locate_records', payload);
      context.commit('SET_BUSY', true);
      
      try {
        let resp=await apiclient.locate(context.getters.connection_id, payload.querystring );
        console.log(resp);

        if(resp.error) {
          console.log('ERRORE: '+resp.error[0].errorCode+': '+resp.error[0].message)
          context.dispatch('showError', { error: resp.error })
          return false
        }
        if(resp && resp.data && resp.data.searchRecords) {
          resp = resp.data.searchRecords
          var labels = {
            "Account": "Customer",
            "Opportunity": "Project",
            "Product2": "Product"
          }
          resp.forEach( r => {
            if(labels[r.attributes.type]) r.attributes['label'] = labels[r.attributes.type]
            else r.attributes['label'] = r.attributes.type
          })
        }
        
        context.commit('SET_BUSY', false);
        return resp;

      } catch(err) {
        console.log('locate_records failed', payload);
        console.log(err);
        
        context.commit('SET_BUSY', false);
        return false;
      }
      
    },
    retrieve_record: async function(context, payload) {
      console.log('retrieve_record', payload);
      context.commit('SET_BUSY', true);
      
      try {
        let resp=await apiclient.retrieve(context.getters.connection_id, payload.object, payload.recordId, payload.properties, payload.associations);
        console.log('retrieve_record',resp);

        if(resp.error) {
          console.log('ERRORE: '+resp.error[0].errorCode+': '+resp.error[0].message)
          context.dispatch('showError', { error: resp.error })
          return false
        }

        if (resp) {
          context.commit( 'SET_RECORDS_ID', { id: payload.id, data:resp.data.Id });
          context.commit( 'SET_RECORDS_DATA', { id: payload.id, data:resp.data });
          // context.commit( 'SET_RECORDS_STATE', { id: payload.id, state:'READY' });
          
          /* vabbè andava e l'ho rotto TODO: SET_RECORDS_ASSOC
          if (resp.data.associations) {
            for (let assoc in resp.data.associations) {
              let ids=[];
              resp.data.associations[assoc].results.forEach( (r) => {
                ids.push({ "id":r.id, "type": r.type });
              })
              context.commit('SET_RECORDS_ASSOC', { table: assoc, data:ids} );
            }
          }
          */
        }
        context.commit('SET_BUSY', false);
        return resp;

      } catch(err) {
        console.log('search_records failed', payload);
        console.log(err);
        context.commit('SET_BUSY', false);
        
        return false;
      }
      
    },
    batchread_records: async function(context, payload) {
      console.log('batchread_records', payload);
      context.commit('SET_BUSY', true);
      
      try {
        let resp=await apiclient.batchread(context.getters.connection_id, payload.object, payload.recordIds, payload.properties);

        if(resp.error) {
          console.log('ERRORE: '+resp.error[0].errorCode+': '+resp.error[0].message)
          context.dispatch('showError', { error: resp.error })
          return false
        }

        console.log(resp);
        if (resp) {
          context.commit('SET_RECORDS_LIST', {id: payload.id, data: resp.data });
          context.commit('SET_BUSY', false);
        }
        return resp;

      } catch(err) {
        console.log('search_records failed', payload);
        console.log(err);
        context.commit('SET_BUSY', false);
        
        return false;
      }
    },
    hydrate_records: async function(context, payload) {
      console.log('hydrate_records', payload);
      context.commit('SET_BUSY', true);

      let records=context.getters.getRecords(payload.id);
      let ids=[];
      records.forEach((r) => {
        ids.push(r.id);
      })
      try {
        let resp=await apiclient.batchread(context.getters.connection_id, payload.object, ids, payload.properties);
        console.log(resp);
        if (resp) {
          context.commit('SET_RECORDS_LIST', {id: payload.id, data: resp.data });
          context.commit('SET_BUSY', false);
        }
        return resp;

      } catch(err) {
        console.log('hydrate_records failed', payload);
        console.log(err);
        context.commit('SET_BUSY', false);
        
        return false;
      }
    },
    create_record: async function(context, payload) {
      console.log('create_record', payload);
      context.commit('SET_BUSY', true);
      
      try {
        let resp=await apiclient.create(context.getters.connection_id, payload.object, payload.properties);
        console.log('index.js create_record resp',resp);

        if(resp.error) {
          console.log('ERRORE: '+resp.error[0].errorCode+': '+resp.error[0].message)
          context.dispatch('showError', { error: resp.error })
          return false
        }

        if (resp) {
          context.commit('SET_FIELD_VALUE', { fieldname: 'Id', fieldvalue: resp.data.id} );
          if (!resp.success)  context.commit('SET_RECORD_ERRORS', { errors: resp.errors} );
//          context.commit('SET_RECORDS_LIST', {id: payload.id, data: resp.data });
          context.commit('SET_BUSY', false);
        }
        return resp;

      } catch(err) {
        console.log('create_record failed', payload);
        console.log(err);
        context.commit('SET_BUSY', false);
        
        return false;
      }
    },
    update_record: async function(context, payload) {
      console.log('update_record', payload);
      context.commit('SET_BUSY', true);
      
      try {
        let resp=await apiclient.update(context.getters.connection_id, payload.object, payload.recordId, payload.properties);
        console.log('index.js update_record resp',resp);

        if(resp.error) {
          console.log('ERRORE: '+resp.error[0].errorCode+': '+resp.error[0].message)
          context.dispatch('showError', { error: resp.error })
          return false
        }
        
        if (resp) {
//          context.commit('SET_RECORDS_LIST', {id: payload.id, data: resp.data });
          context.commit('SET_BUSY', false);
        }
        return resp;

      } catch(err) {
        console.log('update_record failed', payload);
        console.log(err);
        context.commit('SET_BUSY', false);
        
        return false;
      }
    },
    delete_record: async function(context, payload) {
      console.log('delete_record', payload);
      context.commit('SET_BUSY', true);
      
      try {
        let resp=await apiclient.delete(context.getters.connection_id, payload.object, payload.recordId);
        console.log('index.js delete_record resp',resp);

        if(resp.error) {
          console.log('ERRORE: '+resp.error[0].errorCode+': '+resp.error[0].message)
          context.dispatch('showError', { error: resp.error })
          return false
        }
        
        if (resp) context.commit('SET_BUSY', false);
        return resp;

      } catch(err) {
        console.log('delete_record failed', payload);
        console.log(err);
        context.commit('SET_BUSY', false);
        
        return false;
      }
    },
    showError: function(context, payload) {
      console.log('mostrato alert di errore')
      let error = payload.error
      let errList = []
      error.forEach( e => {
        errList.push(e.errorCode+': '+e.message)
      })
      alert(errList)
      context.commit('SET_BUSY', false);
    },
    logout: function(context) {
      console.log('User signed out.');
      localStorage.removeItem('salespaddy_id');
      context.commit('SET_CONNECTION_ID', '');
      context.commit('SET_LOGIN_STATE', false);
    },
    getConfigFromDB: async function(context) {
      context.commit('SET_BUSY', true);
      //chiamata ad apiclient
      const data = await apiclient.getConfig(context.getters.connection_id);
      //Commit per aggiornare lo stato globale con i dati ricevuti
      context.commit('SET_GLOBAL_CONFIG', data);
      
      context.commit('SET_BUSY', false);

    },
    setObjectDB: async function(context, payload) {
      context.commit('SET_BUSY', true);
      console.log("payload of object props send: ", payload);  // Log completo
    
     
      //console.log('id__c', payload.id, 'props',payload.props.data);
      
      const result = await apiclient.setObject(context.getters.connection_id, payload);


      context.commit('SET_BUSY', false);

      return result;
    },
    //richiede nel payload: id, type.
    createObject: async function(context, payload) {
      context.commit('SET_BUSY', true);
      console.log("payload of object props create: ",payload);  // Log completo
    
     
      //console.log('id__c', payload.id, 'props',payload.props.data);
      
      const result = await apiclient.createObject(context.getters.connection_id, payload);


      context.commit('SET_BUSY', false);

      return result;
    },
    deleteObject: async function(context, payload){
      context.commit('SET_BUSY', true);
      console.log(payload, " delete object");
      const result = await apiclient.toDelete(context.getters.connection_id, payload);



      context.commit('SET_BUSY', false);
      return result;
    },
    getObjectFromDB: async function(context, payload){
      context.commit('SET_BUSY', true);
      //chiamata ad apiclient
      console.log("entro nel router");
      const data = await apiclient.getObject(context.getters.connection_id, payload);
      //Commit per aggiornare lo stato globale con i dati ricevuti
      console.log(data);
      let nmPayload={ id: data.id, cfg: data };    
      context.commit('SET_CONFIG',context, nmPayload);
      context.commit('SET_BUSY', false);

    }, 
    isUsedByDB: async function(context, payload){
      context.commit('SET_BUSY', true);
      
      console.log("recived id by is used DB", payload)

      //await context.dispatch('getObject', payload);
      await context.dispatch('getConfigFromDB');

      await context.getters.isUsed(payload);



      context.commit('SET_BUSY', false);

    }, 
    getHousings: async function(context, payload){
      context.commit('SET_BUSY', true);


      const data = await apiclient.getHousings(context.getters.connection_id, payload);
      console.log(data, " get housing index");
      context.commit('UPDATE_CONFIG_OBJECTS', data);
      console.log("end of get housing ");
      context.commit('SET_BUSY', false);

    },
    switchEditProps: function(context){
      const newStatus = !context.getters.getEditProps
      context.commit('SET_EDIT_PROPS', newStatus);
    },
    saveSubscription: async function(context, payload){
      context.commit('SET_BUSY', true);
      payload.ContactId__c = (await context.dispatch('myprofile')).myprofile.contactId;
      const data = await apiclient.saveSubscription(context.getters.connection_id, payload);
      console.log( "saveSubscription data: ", data);
      
      console.log("end of saveSubscription ");
      context.commit('SET_BUSY', false);
    },
    
    
  }
})
