<template>
  <bulmaModal v-bind:buttons="['Save', 'Cancel']" 
    v-on:click="manageModal($event)"
    v-bind:title="'Configura FieldSet'"
  >

    
    <!-- Nome della tabella e Dual Mode in una singola riga -->
    <div class="columns">
      <div class="column is-3">
        <div class="field">
          <label class="label">Title</label>
          <div class="control"> 
            <input class="input" type="text" v-model="toUpdate.title" placeholder="Title">
          </div>
        </div>
      </div>
      <div class="column is-3">
        <div class="field">
          <label class="label">Table Name</label>
          <div class="control"> 
            <input class="input" type="text" v-model="toUpdate.table" placeholder="Table Name">
          </div>
        </div>
      </div>
      <div class="column is-3">
        <div class="field">
          <label class="label">API Key</label>
          <div class="control"> 
            <input class="input" type="text" v-model="toUpdate.apiKey" placeholder="API Key">
          </div>
        </div>
      </div>
      

      <div class="column is-3">
        <div class="field">
          <label class="label">Dual Mode</label>
          <div class="control">
            <label class="checkbox">
              <input type="checkbox" v-model="toUpdate.dual">
              Dual Mode
            </label>
          </div>
        </div>
      </div>
    </div>

    <!-- Numero di campi su una riga -->
    <div class="field">
      <label class="label">Number of Fields</label>
      <div class="control">
        <input class="input" type="number" v-model.number="fieldsno" v-on:change="setFields(fieldsno)"
               placeholder="Number of Fields">
      </div>
    </div>

    <!-- Lista dei campi -->
    <div class="columns is-multiline">
      <div class="column is-half" v-for="(field, index) in toUpdate.fields" :key="index">
        <div class="field">
          <label class="label">Field n. {{ index + 1 }}</label>
          <div class="control">
            <input class="input" type="text" v-model="field.fieldname" placeholder="Field Name">
          </div>
        </div>
      </div>
    </div>
  </bulmaModal>
</template>

<script>
export default {
  name: 'bulmaFieldSet2Props',
  props: {
    config: {
  
      required: false
    },
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      // Initialize toUpdate with either the passed config or default values
      toUpdate: {
        title: this.config && this.config.title ? this.config.title : '',
        table: this.config && this.config.table ? this.config.table : '',
        apiKey: this.config && this.config.apiKey ? this.config.apiKey : '', 
        dual: this.config && this.config.dual ? this.config.dual : false,
        fields: this.config && this.config.fields && this.config.fields.length &&  this.config.fields.length > 0 
                ? this.config.fields.map(field => ({ ...field })) 
                : [{ fieldname: '' }]
      },
      fieldsno: this.config && this.config.fields ? this.config.fields.length : 1
    };
  },
  created(){
   
      //if (this.config) {
        this.toUpdate = {
          title: this.config && this.config.title ? this.config.title : '',
          table: this.config && this.config.table ? this.config.table : '',
          apiKey: this.config && this.config.apiKey ? this.config.apiKey : '', 
          dual: this.config && this.config.dual ? this.config.dual : false,
          fields: this.config && this.config.fields && this.config.fields.length &&  this.config.fields.length > 0 
                ? this.config.fields.map(field => ({ ...field })) 
                : [{ fieldname: '' }]
        };
        this.fieldsno= this.config && this.config.fields && this.config.fields.length ? this.config.fields.length : 1
      

  },
  methods: {
    // Modifica il numero di campi
    setFields(fieldCount) {
      fieldCount = parseInt(fieldCount) || 1;

      // Limita il numero di campi
      if (fieldCount > 20) fieldCount = 20;
      if (fieldCount < 1) fieldCount = 1;

      // Aggiusta il numero di campi
      if (fieldCount > this.toUpdate.fields.length) {
        for (let i = this.toUpdate.fields.length; i < fieldCount; i++) {
          this.toUpdate.fields.push({ fieldname: '' });
        }
      } else if (fieldCount < this.toUpdate.fields.length) {
        this.toUpdate.fields.splice(fieldCount);
      }

      this.fieldsno = fieldCount;
    },

    // Gestisci la chiusura del modale
    async manageModal(buttonIndex) {
      if (buttonIndex === 1 || buttonIndex === -1) {
        this.$emit('close'); // Annulla le modifiche
        return;
      } 
      const props = {
        data: this.toUpdate // Use toUpdate for saving
      };
      await this.$store.dispatch('setObjectDB', { id: this.id, data: this.toUpdate });
      this.$store.commit('SET_CONFIG_OBJECT', { id: this.id, type: "bulmaFieldSet2", data: this.toUpdate });
      console.log('new props for container: ', props);
      this.$emit('close'); // Emit change with toUpdate
      
    }
  }
};
</script>

<style scoped>
/* Optional styling */
</style>
