<template lang="html">
  <div>
  <bulmaModal
    v-bind:buttons="['Save', 'Cancel']"
    v-on:click="manageModal($event)"
    v-bind:title="'Set Rows'"
  >
    <!-- Numero di righe -->
    <div class="field mb-5">

      <label class="label">Number of Rows:</label>
      <div class="control">
        <input
          class="input"
          type="number"
          :placeholder="this.rows.length"
          :value="this.rows.length"
          v-on:change="handleInputChange($event)"
        >
      </div>
    </div>
    <hr>
    <div class="container mb-4"  v-for="(row, key) in rows"  :key="key">
      <div class="box is-link">
        <strong>{{ "Row N."+ (key+1) + (": " + (row.id ? row.id : ""))  }}</strong> 
        <button class="button is-small is-pulled-right ml-4" v-on:click="removeRow(key)">
          <span class="icon is-small">
            <i class="fas fa-trash"></i>
          </span>
        </button>
      </div>
      
    </div>
  </bulmaModal>
</div>
</template>
<script>
  export default {
  name: 'bulmaRowsProps',
  props: {
    
    rows: {
      type: Array,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      refLength: 1
    }
  }, 
  mounted(){
    this.refLength = (this.rows.length ? this.rows.length : 1);
  },
  methods: {
    handleInputChange(event) {
      let value = parseInt(event.target.value) ? parseInt(event.target.value) : 0;
      console.log("minimum value is 1");
      // inserimento forzato del valore minimo
      if (value < 1){
        value = this.refLength;
        event.target.value = this.refLength;
      } 

      // Imposta il numero di righe
      this.setRows(value);

      // Emette l'evento per aggiornare `rowsno` al componente padre
      //this.$emit('update:rowsno', value);
    },
    setRows(rowCount) {
  
      let localRows = this.rows;
      // Aggiusta il numero di righe
      if (rowCount > localRows.length) {
        for (let i = localRows.length; i < rowCount; i++) {
       
          //const newId = `row_${this.id}_${Date.now()}_${this.idCounter}`;
          localRows.push({  type: 'bulmaEmpty' });
          //this.idCounter++;
        }
      } else if (rowCount < localRows.length) {
        localRows.splice(rowCount);
      }
      const payload={
        id: this.id,
        cfg:{
          id: this.id,
          type: 'bulmaRows',
          data: {
            rows: localRows
          }
        }
      };
      this.$store.commit('SET_CONFIG', payload);

 
    },
    manageModal(buttonIndex) {
      if (buttonIndex === 0) {
        
        //this.$emit('change', { rowsno: this.rowsno, rows: this.rows });
        // Salva e invia le righe aggiornate 
        let props={
            rows: this.rows
          
        }
        console.log("dati passati da rows props: ", this.id, props);
        this.$store.dispatch('setObjectDB', {id: this.id, data: props});
        
      }
      this.$emit('cancel');
    },
    removeRow(index){

      let localRows = this.rows;
      // localRows.splice(index, 1)
      let data={
        rows: localRows
        
      }
      data.rows.splice(index, 1);
      console.log(data, " updated rows");

      //riscrivo rows nel db senza la row spacifica
      //await this.$store.dispatch('setObjectDB', {id: this.id, props: props});

      this.$emit('remove-component', localRows);
      //this.$store.commit('SET_CONFIG_OBJECT', { id: this.id, type: "bulmaRows", data: data });
    },
    // async removeRowMK2(index){
    //   //rimuovo row richiesta
    //   let localRows = this.rows;
    //   // localRows.splice(index, 1)
    //   const id = localRows[index].id ? localRows[index].id  : "";
    //   console.log("id test ", id)
    //   let data={
    //     rows: localRows
        
    //   }
    //   data.rows.splice(index, 1);

    //   //riscrivo rows nel db senza la row spacifica
    //   await this.$store.dispatch('setObjectDB', {id: this.id, data: data});

    //   //richiedo componenti che possono contenere altri componenti
    //   await this.$store.dispatch('getHousings');
      
    //   //controllo se il componente rimosso non è usato da altri contenitori
    //   console.log(await this.$store.getters.isUsed(id || ""), "   IS IN CONFIGUTATION?");

    //   if(!await this.$store.getters.isUsed(id && id!="")){ //controlla se false va bene !!!!!
    //     // se non usato elimino
    //     await this.$store.dispatch('deleteObject', id);
    //   }else console.log("component still used, not discarded")
      
    //   //aggiorno per verificare i dati memorizzati dal db fin da subito
    //   //await this.$store.dispatch('getObjectFromDB', this.id);
    //   this.$emit('remove-component', data.rows);
    // }
  }
};

</script>