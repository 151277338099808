<template lang="html">
  <div>
   
  <div class="" v-bind:id="id" v-if="hasConfig()">
    <!-- POP-UP INPUT filter -->
    <div :class="showInputFilter? 'is-active':''" class="modal">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="notification mx-4">
          <button class="delete" @click="showFilterPopUp(columnFilter[0])"></button>
          <b>Filter</b>
          <div class="is-flex mt-4">
            <input type="text" class="input is-small is-rounded" v-model="inputFilter" placeholder="Filter">
            <i class="fas fa-lg fa-circle-arrow-right is-align-content-center ml-2" @click="search()"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="is-justify-content-space-between mb-3 is-flex-tablet">
      <div><h1 class="title is-5 pr-1 pt-1">{{ config.title }}</h1></div>

      <div class="" :class="showInput ? 'is-flex-tablet':'is-flex'">
        <!-- SEARCH -->
        <div class="field has-addons mb-0 py-1">
          <div v-if="showInput" class="control">
            <input type="text" class="input is-small is-rounded" v-model="inputSearch" placeholder="Search" @input="search()">
          </div>
          <div class="control">
            <button class="button is-rounded is-small" @click="toggleSearch()">
              <span class="icon"><i class="fas fa-magnifying-glass"></i></span>
            </button>
          </div>
        </div>

        <div class="field is-flex is-align-items-baseline py-1">
          <!-- ADD -->
          <button v-if="config.caninsert" class="button is-rounded is-small">
            <span class="icon" v-on:click="showAddRecord()"><i class="fas fa-plus"></i></span>
          </button>

          <!-- RELOAD -->
          <button class="button is-rounded is-small"> <span class="icon" v-on:click="loadRows()"><i class="fas fa-rotate-right"></i></span> </button>

          <!-- filters -->
          <button v-if="searchableFields" class="button is-rounded is-small" @click="showFiltri = !showFiltri" :class="{ 'is-link' : $store.state.advancedFilteringProperties.length > 0, 'is-active':showFiltri}"><span class="icon"><i class="fas fa-filter"></i></span></button>

          <div class="select is-rounded is-small" v-if="config.lists.length > 1">
            <select v-model="current_list_name" v-on:change="changeList">
              <option v-for="list in config.lists" v-bind:key="list.name" v-bind:value="list.name">{{ $t(list.label) }}</option>
            </select>
            <!-- TODO: change my default -->
          </div>
        </div>
          
      </div>
    </div>

    <!-- <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title is-5">{{ config.title }}</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">

          SEARCH
          <button class="button is-rounded is-small">
            <span class="icon" v-on:click="search()"><i class="fas fa-magnifying-glass"></i></span>
          </button>

          <div class="field has-addons mb-0">
            <div v-if="showInput" class="control">
              <input type="text" class="input is-small is-rounded" v-model="inputSearch" placeholder="Search" @input="search()">
            </div>
            <div class="control">
              <button class="button is-rounded is-small" @click="toggleSearch()">
                <span class="icon"><i class="fas fa-magnifying-glass"></i></span>
              </button>
            </div>
          </div>

          ADD
          <button v-if="config.caninsert" class="button is-rounded is-small">
            <span class="icon" v-on:click="showAddRecord()"><i class="fas fa-plus"></i></span>
          </button>

          <button class="button is-rounded is-small"> <span class="icon" v-on:click="loadRows()"><i class="fas fa-rotate-right"></i></span> </button>
          
        </div>
        <div class="level-item">
          <div class="select is-rounded is-small" v-if="config.lists.length > 1">
            <select v-model="current_list_name" v-on:change="changeList">
              <option v-for="list in config.lists" v-bind:key="list.name" v-bind:value="list.name">{{ $t(list.label) }}</option>
            </select>
            TODO: change my default
          </div>
        </div>
      </div>
    </div> -->

    <div class="table-container">

      <!-- SEZIONE FILTRI -->
      <div v-if="showFiltri" class="mb-2" style="border: 1px solid;background-color:lightgrey;border-radius:10px"> <!-- possibile colore sfondo #4CA9CA-->
        <div class="columns is-multiline is-gapless p-1 mb-0" v-for="field in searchableFields" v-bind:key="field.name" style="border-bottom: 1px solid">
          <div class="column is-one-third my-1" v-for="f in field" v-bind:key="f.name">
            <bulmaField v-bind:id="f.name" v-bind:data="f"></bulmaField>
          </div>
        </div>

        <div class="column mx-1">
          <div class="columns is-mobile">
            <div class="column is-8-mobile">
              <button class="button is-rounded is-small" @click="resetAdvancedFilters()">RESET</button>
            </div>
            <div class="column">
              <button class="button is-rounded is-small is-active is-link is-pulled-right" @click="loadRows()">SEARCH</button>
            </div>
          </div>
        </div>
      </div>

      <!-- HEADER -->
      <div v-if="true" class="columns is-multiline is-mobile is-gapless mb-1">
        <div class="column is-text-overflow has-text-weight-bold" v-for="(col,index) in current_list_cols" v-bind:key="col.id" 
          v-bind:style="'text-align:'+align(col.format)" v-bind:class="{'is-hidden':index==0, 'is-one-third':index>0}" >
          <span v-if="canFilter(col.format)" class="icon" v-on:click="showFilterPopUp(col)"><i class="fa-solid fa-filter" :class="{'has-text-link' : col.filtering}"></i></span>
          <span v-on:click="sortOrder(col)">
            {{ col.label }}<span class="icon"><i :class="'fa-solid fa-sort-'+col.sorting"></i></span>
          </span>
        </div>
      </div>

      <!--  versione columns -->
      <div  v-if="true" class="columns is-multiline is-mobile is-gapless" style="border-top: 1px solid;"  v-for="record in current_page_records" v-bind:key="record.id">

        <div class="column is-text-overflow" v-for="(col,index) in current_list_cols" v-bind:key="col.id" v-bind:style="'text-align:'+align(col.format)" v-bind:class="{'is-full':index==0, 'is-one-third':index>0}" >

          <span v-if="index==0 && record.show && config.checkbox" class="mr-1">
            <input :type="config.checkbox == 'multi' ? 'checkbox':'radio'" :id="record.Id" :value="record.Id" v-model="selected_list_id" @change="saveRecords(record)"/>
          </span>

          <span v-if="col.format=='link'">
            <span v-if="config.checkbox" @click="saveRecords(record)">{{ filter( getFieldValue(record, col.fieldname), col.format ) }}</span>
            <span v-else>
              <a href="#" v-on:click.prevent="select(record, col)">
                {{ filter( getFieldValue(record, col.fieldname), col.format ) }}
              </a>
            </span>
          </span>

          <a v-if="isActionable(col.format)" v-bind:href="filter( getFieldValue(record, col.fieldname), col.format )" >
            {{ getFieldValue(record, col.fieldname) }}
          </a>

          <span v-if="!isActionable(col.format) && col.format!='link' && col.format!='hidden'" >
            <span v-if="col.format=='alert' && record[col.name]" class="icon"><i class="fas fa-bell"></i></span>
            {{ filter( getFieldValue(record, col.fieldname), col.format ) }}
          </span>

        </div>

      </div>
      <!--  versione columns -->

      <!--  versione table -->
      <table v-if="false" class="table is-narrow">
        <thead>
          <tr>
            <!-- TODO: filter -->
            <!-- TODO: sort -->
            <th v-if="config.checkbox"><input v-if="config.checkbox == 'multi'" type="checkbox" v-model="checkedAllOptions" @click="selectAll(checkedAllOptions)" /></th>
            <th v-for="(col, index) in current_list_cols" v-bind:key="col.id" style="vertical-align:top"  v-on:click="$emit('filter',index)" >
              <span v-bind:style=" (wrapheader?'white-space: normal':'') " >{{col.label}}</span>
              <span v-if="col.filtered" class="icon"><i class="fas fa-filter"></i></span>
            </th>
          </tr>
        </thead>
        <tbody>
            <tr v-for="record in current_page_records" v-bind:key="record.id"><!-- v-on:click="$emit('row',record)" -->
              
              <!-- <td v-if="config.checkbox"><label class="checkbox"><input type="checkbox" /></label></td> -->
              <td v-if="record.show && config.checkbox">
                <input :type="config.checkbox == 'multi' ? 'checkbox':'radio'" :id="record.Id" :value="record.Id" v-model="selected_list_id" @change="saveRecords(record)"/>
              </td>

              <td v-if="record.show" v-for="col in current_list_cols" v-bind:key="col.id" v-bind:style="'text-align:'+align(col.format)" >

                <a v-if="col.format=='link'" href="#" v-on:click.prevent="select(record, col)">
                  {{ filter( getFieldValue(record, col.fieldname), col.format ) }}
                </a>

                <a v-if="isActionable(col.format)" v-bind:href="filter( getFieldValue(record, col.fieldname), col.format )">
                  {{ getFieldValue(record, col.fieldname) }}
                </a>

                <span v-if="!isActionable(col.format) && col.format!='link' && col.format!='hidden'">
                  <span v-if="col.format=='alert' && record[col.name]" class="icon"><i class="fas fa-bell"></i></span>
                  {{ filter( getFieldValue(record, col.fieldname), col.format ) }}
                </span>

              </td>

            </tr>
        </tbody>
      </table>
      <!--  versione table -->

    </div>
  
    <nav class="level" v-if="$store.state.busy">
      <div class="level-item has-text-centered">
        <button class="button is-loading" >Loading</button>
      </div>
    </nav>
    <bulmaPagination v-if="!$store.state.busy" v-bind:maxPages="maxPages" v-bind:currentPage="currentPage"  v-on:goto="loadPage" v-on:next="loadNextPage" v-on:previous="loadPreviousPage"></bulmaPagination>
 
    <div v-if="isAdmin() && getEditProps">
      <bulmatableprops
        v-if="showModal"
        v-bind:config="config"
        v-bind:id="id"
        v-on:change="closeModal"
        v-on:close="closeModal"
        v-on:refresh="updateTable"
      ></bulmatableprops>
   
      <button class="button" v-on:click="changeShowModal()">
        <span class="icon is-small">
          <i class="fas fa-pen"></i>
        </span>
        <span>Table</span>
      </button>
    </div>
    

  </div>
  <div v-else-if="!hasConfig() && isAdmin() && getEditProps"> 
    <bulmatableprops
      v-if="showModal"
      v-bind:config="config ? config : {}"
      v-bind:id="id"
      v-on:change="closeModal"
      v-on:close="closeModal"
      v-on:refresh="updateTable"
    ></bulmatableprops>
    <button class="button" v-on:click="changeShowModal()">Initialise Table</button>
  </div>
    </div>
</template>

<script>
/* { "id":"id", "label":"Key", "fieldname":"Id", "format": "hidden", },*/

import numeral from 'numeral'
import moment from 'moment'
import bulmatableprops from '../components/bulma_tableprops.vue'

export default {
  name: 'bulmaTable',
  components: {
    bulmatableprops
  },
  props: {
    id: String,
    
    wrapheader: Boolean,
    wrapcell: Boolean,
  },
  data: function () {
    return {
      current_list: {},
      current_list_name:'',

      records: [],
      currentPage:1,
      nrecbypage:8,

      selected_list_id:[],
      checkedAllOptions: false,
      variabile: '',

      showInput: false,
      inputSearch: '',
      searchFilter: '',
      searchDelay: '',

      showFiltri: false,
      orderingColumn: '',
      sortingAsc: true,

      showInputFilter: false,
      inputFilter: '',
      columnFilter: {},
      showModal: false,
      showModal2: false,
    }
  },
  watch: {
    broadcast_message: function(newVal) { // bus message
      if (newVal && newVal.message) {
        console.log('broadcast_message:', this.broadcast_message);
        if (this.broadcast_message.message=='refresh') {
          this.loadRows();
        }
      }
    },
    id: function(newVal, oldVal) {
      if(newVal != oldVal) this.loadComponentData()
    }
  },
  computed: {
    broadcast_message: function() { // bus message
      return { message: this.$store.state.broadcast_message, payload :this.$store.state.broadcast_message_payload };
    },
    current_page_records: function() {
      let s=(this.currentPage-1)*this.nrecbypage;
      let e=s+this.nrecbypage;
      return this.records.slice(s,e);
    },
    current_list_cols: function() {
      if (!this.current_list || !this.current_list.cols) return this.current_list;
      return this.current_list.cols.filter( (c) => { return c.format!='hidden'; } )
    },
    maxPages: function() {
      let res=this.$store.getters.getRecordsList(this.id);
      if (res) {
        return Math.ceil(res.totalRecords/this.nrecbypage);
      }
      return 0;
    },
    config: function() {
      return this.$store.getters.getObject(this.id).data;
    },
    checkedAll() {
      if(this.selected_list_id.length == this.current_page_records.length) return true
      if(this.checkedAllOptions) return true
      return false
    },
    lookup_process() {
      return this.config.lookup_process;
    },
    numCols() {
      return ( 'is-' + Math.floor( 12 / (this.current_list_cols.length - 1) ) )
    },
    current_cols_map() {
      var map = {}
      for(var c of this.current_list_cols) { map[c.fieldname] = c }
      return map
    },
    
    changeEditMode(){
      var newEditStatis = !this.$store.getters.getEditMode(this.id)
      var payload={ id: this.id, editmode: newEditStatis};
      this.$store.commit('SET_EDITMODE', payload);
    },
    getEditProps(){
      return this.$store.getters.getEditProps;
    },

    searchableFields: function () {
      if(this.config && this.config.searchSection) {
        if (!this.$store.state.database.has(this.config.table)) return [];
        let solvedfields=[]
        this.config.searchSection.forEach( s => {
          var solvedSection = []
          var table = this.$store.state.database.get(s.table)
          s.searchableColumns.forEach( f => {
            if (table.has(f.fieldname)) {
              if(table.get(f.fieldname).type != 'hidden') {
                let f2={ ...table.get(f.fieldname) }
                f2['table'] = s.table
                solvedSection.push( f2 );
              }
            }
          })
          solvedfields.push(solvedSection)
        })
        return solvedfields;
      }
    },
  },
  mounted: function() {
    this.loadComponentData()
    if(this.config && this.config.searchSection) this.$store.commit('SET_RECORDS_STATE',{ id: this.config.process, state: 'SEARCH'})
  },
  methods: {
    loadComponentData() {
      if(!this.hasConfig()){
        return;
      }
      // current list
      this.current_list=this.config.lists.find( (l) => { return l.default; });
      if (!this.current_list) this.config.lists[0];
      if(!this.current_list || !this.current_list.name){
        return;
      }

      this.current_list_name=this.current_list.name;

  //    this.search()

      if (this.config.nrecbypage) this.nrecbypage=this.config.nrecbypage;

      if(this.config.checkbox) {
        if(this.config.checkbox == 'multi') this.selected_list_id.push(this.$store.state.lookup_field_value)
        else this.selected_list_id = this.$store.state.lookup_field_value
      }
      console.log('this.selected_list_id',this.selected_list_id)

      this.current_list.cols.forEach( c => {
        c['sorting'] = ''
        c['filtering'] = false
      })

      // hydration
      if (this.config.hydration) {
        this.hydrate();
      } else {
        this.loadRows();
      }
    },
    resetAdvancedFilters() {
      this.$store.commit('CLEAR_ADVANCEDFILTERINGPROPERTIES')
      this.loadRows()
    },
    isActionable: function(type) {
      if (type=='email') return true;
      if (type=='phone') return true;
      return false;
    },
    toggleSearch: function() {
      this.showInput = !this.showInput;
      if (!this.showInput) { // reset filter
        this.searchFilter='';
        this.inputSearch='';
        this.changeList();
      }
    },
    localAction: function(action) {
      switch (action.action) {
        case 'new_record':
          this.create();
          break;
        case 'search_record':
          this.search();
        break;
        case 'reload_records':
          this.loadRows();
        break;
      }
    },
    changeList: function() {
      console.log("changeList", this.current_list_name);
      this.current_list=this.config.lists.find( (l) => { return this.current_list_name==l.name; });
      console.log("current_list", this.current_list);

      this.loadRows();
    },
    create: function() {
      // new_record
      let m={ message: 'new_record', payload: this.config.process};
      this.$store.commit('POST_MESSAGE', m);
    },
    search() {
      console.log('--> search')
      if (this.searchDelay) clearTimeout(this.searchDelay);
      this.searchDelay=setTimeout( () => {
        this.searchFilter={
          fieldname: this.columnFilter[0] ? this.columnFilter[0].fieldname : 'Name',
          operator: 'LIKE',
          value: this.columnFilter[0] ? this.inputFilter : this.inputSearch,
        }
        this.showInputFilter = false
        console.log('this.searchFilter',this.searchFilter)
        this.loadRows()
      },500)
    },
    sortOrder(col) {
      // this.sortingAsc = !this.sortingAsc
      this.current_list.cols.forEach( c => { if(c.id != col.id) c.sorting = '' })
      switch (col.sorting) {
        case '':
          col.sorting = 'up'
          this.sortingAsc = true
          this.orderby(col.fieldname)
          break
        case 'up':
          col.sorting = 'down'
          this.sortingAsc = false
          this.orderby(col.fieldname)
          break
        case 'down':
          col.sorting = ''
          this.changeList()
          break
      }
    },
    orderby(column) {
      console.log('F --> orderby')
      // var column = this.orderingColumn
      console.log('column',column)

      // INDIVIDUA VALUES
      if(column.includes('.')) var parentAndField = column.split('.')
      var containsNum = false

      var infos = []
      for(var r of this.records) {
        for(var f of Object.keys(r)) {
          // console.log(f, column, f == column)
          // console.log(column.includes('.'), f, parentAndField)
          if(f == column) {
            infos.push(r[f])
            if(typeof(r[f]) == 'number') containsNum = true
            break
          } else if( column.includes('.') && f == parentAndField[0] ) {
            infos.push(r[parentAndField[0]][parentAndField[1]])
            if(typeof(r[parentAndField[0]][parentAndField[1]]) == 'number') containsNum = true
            break
          }
        }
      }
      console.log('infos',infos)

      // SORT VALUES
      console.log('sort')
      infos = infos.sort()
      if(containsNum) infos.sort(function(a, b){return a-b})
      if(!this.sortingAsc) {
        console.log('reverse')
        infos = infos.reverse()
      }
      console.log('infos ', infos)

      // SHOW SORTED VALUES
      let recordsNotSorted = this.records
      this.records = []

      var identifiers = []
      for(var i of infos) {
        for(var r of recordsNotSorted) {
          for(var f of Object.keys(r)) {
            var identifier
            if(r.Id) identifier = r.Id
            else console.log('manca identifier nel record',r)

            var col = column
            var info = r[f]

            if(column.includes('.')) {
              col = parentAndField[0]
              info = r[parentAndField[0]][parentAndField[1]]
            }

            if(f == col && i == info && r.show && !identifiers.includes(identifier)) {
              this.records.push(r)
              identifiers.push(identifier)
            }

          }
        }
      }
    },
    canFilter(format) {
      switch(format) {
        case 'number':
        case 'currency':
          return false
        default:
          return true
      }
    },
    showFilterPopUp(col) {
      console.log('--> filterRows on', col)
      this.inputFilter = ''

      // TODO: basta farsi passare l'indice per evitare questo scempio
      const updatedCols = this.current_list.cols.map(c => {
        return { ...c, filtering: !col.filtering && c.id === col.id ? true :  false };
      });
      this.current_list.cols = updatedCols;

      if(!col.filtering) {
        this.showInputFilter = true
      // TODO: basta farsi passare l'indice per evitare questo scempio
      this.columnFilter = this.current_list.cols.filter( (c) => { return c.id === col.id } )
      } else {
        this.showInputFilter = false
        this.searchFilter = ''
        this.columnFilter = {}
        this.loadRows()
      }
    },
    loadNextPage: async function() {
      this.currentPage++;
    },
    loadPreviousPage: async function() {
      this.currentPage--;
    },
    loadPage: async function(p) {
      this.currentPage=p;
    },
    loadRows: async function() {
      if(!this.config) return;
      console.log('--> loadRows (bulma_table)')
      this.records=[];

      let f=this.current_list.filters;
      if (this.searchFilter) f=f.concat(this.searchFilter);

      var advancedFilters = []
      if (this.config && this.config.searchSection && this.$store.state.advancedFilteringProperties.length > 0) {
        this.config.searchSection.forEach( s => {
          if(s.table == this.config.table) {
            f = f.concat(this.$store.state.advancedFilteringProperties.filter( f => f.table == s.table ))
          } else {
            var filters = this.$store.state.advancedFilteringProperties.filter( f => f.table == s.table )
            if(filters.length) {
              advancedFilters.push({
                parentKey : s.parentKey,
                tablename : s.table,
                filters : filters
              })
            }
          }
        })
      }
      console.log(advancedFilters, advancedFilters.filters)

      var payload={
        id: this.id,
        tablename: this.config.table,
        cols: this.current_list.cols,
        filters: f,
        childQuery: advancedFilters.length ? advancedFilters : [],
        orders: this.current_list.order,
        offset: 0,
        limit: this.current_list.limit ? this.current_list.limit : 16
      }
      console.log('PAYLOAD', payload)

      await this.$store.dispatch('load_records', payload );

      let data=this.$store.getters.getRecordsList(this.id);
      if (data) {
        console.log('data',data)
        this.records=data.records
        if(this.orderingColumn) this.orderby()
      }
    },
    // TODO: hydrate is for hubspot, currently broken :)
    hydrate: async function() {
      this.records=[];

      let records=this.$store.getters.getRecordAssoc(this.config.table);
      this.$store.commit('SET_RECORDS_DATA', { id:this.id, data:records});
      let p=[];
      this.config.cols.forEach( f => {
        if (f.fieldname!='id') p.push(f.fieldname);
      });

      let res=await this.$store.dispatch('hydrate_records', { id: this.id, object: this.config.table, properties:p});
      if (res.data) {
        res.data.results.forEach( (r) => {
          r.properties.id=r.id;
          this.records.push(r.properties);
        })
      }
    },
    filter: function(v, type) {
      if (!v) return v;

      // TODO: locale
      switch(type) {
        case 'hidden':
          return;
        case 'date':
          var d=new moment(v);
          return d.format('MM/DD/YYYY');
        case 'datetime':
          var dt=new moment(v);
          return dt.format('MM/DD/YYYY HH:mm');
        case 'decimal':
          return numeral(v).format('0,0');
        case 'currency':
          return numeral(v).format('$ 0,0.00');
        case 'alert':
          return '';
        case 'phone':
          return 'tel:'+v;
        case 'email':
          return 'mailto:'+v;
        case 'truncate':
            return v.substr(0,32);
      }
      return v;
    },
    getFieldValue: function(obj, path) {
      // eslint-disable-next-line no-redeclare
      for (var i=0, path=path.split('.'), len=path.length; i<len; i++){
        if (obj)
          obj = obj[path[i]];
      }
      return obj;
    },
    align: function(type) {
      switch(type) {
        case 'currency':
        case 'decimal':
          return 'right';
      }
      return 'left';
    },
    select: function(record, col) {
      console.log('select: ', record, col)
      if(this.lookup_process) this.showEdit(record)
      else {
        let id=col.link_fieldname;
        // this.$store.commit('SET_RECORDS_ID', { id: this.id, data:record[id] });
        // this.$store.commit('SET_RECORDS_STATE', { id: this.id, state:'TOLOAD' });
        this.$router.push(this.config.path.details+record[id]);
        // this.$emit('click', index);
      }
    },
    showEdit(record) {
      console.log('--> showEdit', record);
      delete record.show

      var verb = this.$store.getters.getVerb( this.$store.getters.getProcess(this.lookup_process), 'e' )
      //recupero la page associata
      var page = this.$store.getters.getPage( verb.pageid )
      console.log("--> showEdit, page: ", page);


      var data = {
        processId: this.lookup_process,
        verbId: "e",
        //utilizzo il componente associato alla page
        title: this.$store.getters.getObject(page.initialid).data.title,
        candelete: this.config.candelete,
        table: this.config.table
      }
      console.log('data',data)
      this.$store.commit('PUSH_PROCESS_STATUS', data);
      this.$store.commit('SET_RECORDS_STATE', {id: this.lookup_process, state:'EDIT'});
      this.$store.commit('SET_RECORDS_DATA', {id: this.lookup_process, data: record });
    },
    selectAll(checkedAll) {
      console.log(checkedAll)
      this.selected_list_id = []
      if(!checkedAll) {
        for(var r of this.current_page_records) {
          // this.selected_list_name.push(r[this.config.Id.fieldname])
          this.selected_list_id.push(r.Id)
        }
        this.saveRecords()
      }
    },
    showAddRecord() {
      console.log('--> showAddRecord');

      let pid=this.config.lookup_process;
      if (!pid) {
        this.create();
        // this.$router.push(this.config.path.create);
        return;
      }
      var data = {
        processId: this.config.lookup_process,
        verbId: "n",
        title: this.config.title,
        table: this.config.table,
        defaults: this.config.defaults
      }
      this.$store.commit('PUSH_PROCESS_STATUS', data);
      this.$store.commit('SET_RECORDS_STATE', {id: this.config.lookup_process, state:'NEW'});
      this.$store.commit('SET_RECORDS_DATA', {id: this.config.lookup_process, data:{}});
    },
    showAddRecordOld() {
      console.log('--> showAddRecord');

      var object = this.$store.getters.getObject(this.config.addForm.id)
      var data = {
        id: this.config.addForm.id,
        title: object.data.title,
        type: object.type
      }
      this.$store.commit('POST_MESSAGE', { message: 'goto_search', payload: data} );
      // this.$store.commit('SET_RECORDS_STATE', {id: this.$store.state.global_state_process, state:'NEW'});
      this.$store.commit('SET_RECORDS_STATE', {id: this.config.addForm.id, state:'NEW'});
      this.$store.commit('SET_RECORDS_DATA', {id: this.config.addForm.id, data:{}});
    },
    saveRecords(record) {
      console.log('saveRecords', record)
      this.selected_list_id = record.Id
      this.$store.commit('SET_PROCESS_STATUS_DATA', {
        name: 'selected_id',
        value: record.Id,
      });
      this.$store.commit('SET_PROCESS_STATUS_DATA', {
        name: 'selected_name',
        value: record.Name,
      });
/*
      if(!this.$store.getters.getRecordData.length) {
        var status = this.$store.getters.getProcessStatus;
        var config = this.$store.getters.getProcess(status.processId);

        var data = {}
        var fields = this.$store.state.database.get(config.table)
        console.log('fields',fields)
        fields.forEach( f => {
          console.log(f.name, record[f.name])
          if(!f.readonly) data[f.name] = record[f.name]
        });
        var defaults = this.$store.getters.getVerb(config, status.verbId).defaults
        if(defaults != undefined) {
          console.log('defaults')
          defaults.forEach( d => {
            var defaultField = d.default.split('.')[1]
            var value = ''
            switch (d.default.split('.')[0]) {
              case '$previous':
                value = this.$store.state.records_data[this.$store.state.global_state_process][defaultField]
                break
              default:
                value = d.default
            }
            console.log('value',value)
            data[d.fieldname] = value
          })
        } 
        console.log('data: ', data)
        this.$store.commit('SET_RECORDS_DATA', {id: status.processId, data: data});
      }
*/
      // al momento solo per la single select
      /*
      var r = this.$store.getters.getRecordData
      r[this.$store.state.lookup_field] = this.selected_list_id
      this.$store.commit('SET_RECORDS_DATA', r);
      */
    },
    isAdmin(){
      return this.$store.getters.isAdmin;
    },
    changeShowModal(){
      this.showModal=!this.showModal;
    },
    closeModal(){
      this.showModal=false;
    },
    hasConfig() {
      //return this.config && 'actions' in this.config;
      if(this.config){
        return true
      }else return false;
    },
    updateTable(){
      console.log("--> updateTable")
      let m={ message: 'refresh'};
      this.$store.commit('BROADCAST_MESSAGE', m);
      this.showModal=false;
    }
  }
}
</script>

<style scoped>
.bloccato {
  position: sticky;
  top: 0;
  z-index: 1;
}
.is-text-overflow {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.is-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>