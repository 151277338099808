<template>
  <div class="container">
    <!-- Edit Global Button -->
    <div class="actions">
      <button 
        class="button is-primary is-rounded" 
        :disabled="!isAnyLocationSelected"
        @click="editGlobal"
      >
        Edit Global
      </button>
    </div>
<!-- Global Edit Modal -->
<div v-if="showGlobalModal" id="editGlobalModal" class="modal is-active">
  <div class="modal-background" @click="closeGlobalModal"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Edit Global Settings</p>
      <button class="delete" aria-label="close" @click="closeGlobalModal"></button>
    </header>
    <section class="modal-card-body">
      <div class="tabs is-boxed">
        <ul>
          <li :class="{ 'is-active': globalCurrentTab === 'settings' }" @click="showGlobalTab('settings')">
            <a>Settings</a>
          </li>
          <li :class="{ 'is-active': globalCurrentTab === 'hours' }" @click="showGlobalTab('hours')">
            <a>hours</a>
          </li>
        </ul>
      </div>

      <!-- Settings Tab with Input Fields -->
      <div v-show="globalCurrentTab === 'settings'" class="tab-content">
        <h3 class="title is-5">Profile Description</h3>
        <textarea v-model="editableDescription" class="textarea is-rounded" rows="4" placeholder="Enter description"></textarea>

        <h3 class="title is-5">Website URL</h3>
        <input v-model="editableWebsiteUri" class="input is-rounded" type="text" placeholder="Enter website URL" />
      </div>
      <div v-show="globalCurrentTab === 'hours'" class="tab-content">
  <h3 class="title is-5">Store Hours (Monday to Friday)</h3>
  <table class="table is-bordered is-striped is-narrow">
    <thead>
      <tr>
        <th>Day</th>
        <th>Open Time</th>
        <th>Close Time</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(day, index) in ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY']" :key="index">
        <td>{{ day }}</td>
        <td>
          <!-- Time input for open time -->
          <input v-model="globalHours[day].openTime" class="input is-rounded" type="time" placeholder="Open Time">
        </td>
        <td>
          <!-- Time input for close time -->
          <input v-model="globalHours[day].closeTime" class="input is-rounded" type="time" placeholder="Close Time">
        </td>
      </tr>
    </tbody>
  </table>
</div>


    </section>
   <!-- Save Changes Button -->
<footer class="modal-card-foot">
  <button @click="saveGlobal" class="button is-primary is-rounded">Save Changes</button>
</footer>

  </div>
</div>

    <!-- Locations Table -->
    <div class="table-container">
      <table class="table is-fullwidth is-striped is-hoverable">
        <thead>
          <tr>
            <!-- Select All Checkbox -->
            <th>
            <input 
              type="checkbox" 
              class="select-all-checkbox" 
              @change="toggleSelectAll" 
              :checked="allLocationsSelected"
              :indeterminate="someLocationsSelected && !allLocationsSelected"
            />
          </th>
            <th>Address</th>
            <th>Store Code</th>
            <th>Phone Number</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(location, index) in locations" :key="location.id">
            <td>
              <input 
                type="checkbox" 
                class="location-checkbox" 
                v-model="location.selected"
                @change="toggleLocationSelection"
              />
            </td>
            <td>
              {{ location.storefrontAddress ? location.storefrontAddress.addressLines.join(', ') : 'N/A' }}
            </td>
            <td>
              {{ location.storeCode || 'N/A' }}
            </td>
            <td>
              {{ location.phoneNumber || 'N/A' }}
            </td>
            <td>
              <button 
              class="button is-link is-light is-rounded" 
              @click="editLocationByIndex(index)"
            >
              <i class="fas fa-edit"></i> Edit
            </button>

            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Modal for Editing Location -->
    <div v-if="showModal" id="editLocationModal" class="modal is-active">
      <div class="modal-background" @click="closeModal"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Edit Location</p>
          <button class="delete" aria-label="close" @click="closeModal"></button>
        </header>
        <section class="modal-card-body">
          <div class="tabs is-boxed">
            <ul>
              <li :class="{ 'is-active': currentTab === 'hours' }" @click="showTab('hours')"><a>Hours</a></li>
              <li :class="{ 'is-active': currentTab === 'location' }" @click="showTab('location')"><a>Location</a></li>
              <li :class="{ 'is-active': currentTab === 'other' }" @click="showTab('other')"><a>Other</a></li>
            </ul>
          </div>
          <!-- Hours Tab -->
          <div v-show="currentTab === 'hours'" class="tab-content">
            <table class="table is-bordered is-striped is-narrow">
              <thead>
                <tr>
                  <th>Day</th>
                  <th>Open Time</th>
                  <th>Close Time</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(period, index) in selectedLocation.regularHours.periods" :key="index">
                  <td>{{ period.openDay }}</td>
                  <td>
                    <input
                      class="input is-rounded"
                      type="time"
                      v-model="period.openTimeFormatted"
                    />
                  </td>
                  <td>
                    <input
                      class="input is-rounded"
                      type="time"
                      v-model="period.closeTimeFormatted"
                    />
                  </td>
                </tr>

              </tbody>
            </table>
          </div>

          <!-- Location Tab -->
          <div v-show="currentTab === 'location'" class="tab-content">
            <div>
              <h3 class="title is-5">Store Information</h3>

              <div class="field" v-for="(line, index) in selectedLocation.storefrontAddress.addressLines" :key="index">
                <label class="label">Address Line {{ index + 1 }}</label>
                <input 
                  v-model="selectedLocation.storefrontAddress.addressLines[index]" 
                  class="input is-rounded" 
                  type="text" 
                  :placeholder="'Address Line ' + (index + 1)"
                />
              </div>

              <div class="field">
                <label class="label">Locality</label>
                <input v-model="selectedLocation.storefrontAddress.locality" class="input is-rounded" type="text" placeholder="Locality">
              </div>

              <div class="field">
                <label class="label">State</label>
                <input v-model="selectedLocation.storefrontAddress.administrativeArea" class="input is-rounded" type="text" placeholder="State">
              </div>

              <div class="field">
                <label class="label">Postal Code</label>
                <input v-model="selectedLocation.storefrontAddress.postalCode" class="input is-rounded" type="text" placeholder="Postal Code">
              </div>

              <div class="field">
                <label class="label">Phone</label>
                <input v-model="selectedLocation.primaryPhone" class="input is-rounded" type="text" placeholder="Phone Number">
              </div>
            </div>
          </div>

          <!-- Other Tab -->
          <div v-show="currentTab === 'other'" class="tab-content">
            <div v-if="selectedLocation && selectedLocation.categories">
              <h3>Categories:</h3>
              <div v-for="(category, categoryIndex) in getCategoryDisplayNames(selectedLocation.categories)" :key="categoryIndex">
                <p><strong>{{ category.displayName }}</strong></p>
              </div>
            </div>
            <p>Parking is on-site (you can use cash or card).</p>
          </div>
          <div v-if="successMessage" class="notification is-success is-light">
            {{ successMessage }}
          </div>

        </section>
        <footer class="modal-card-foot">
          <button @click="saveButton" class="button is-primary is-rounded">Save Changes</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/apiclient.js';

export default {
  name: 'Locations',
  data() {
  return {
    locations: [],
    loading: true,
    error: null,
    showModal: false,
    selectedLocation: null,
    currentTab: 'hours',
    globalCurrentTab: 'settings',
    showGlobalModal: false,
    successMessage: '',
    successTimeout: null,
    selectedLocations: [], // Array to store selected locations
    globalHours: {
      MONDAY: { openTime: '', closeTime: '' },
      TUESDAY: { openTime: '', closeTime: '' },
      WEDNESDAY: { openTime: '', closeTime: '' },
      THURSDAY: { openTime: '', closeTime: '' },
      FRIDAY: { openTime: '', closeTime: '' }
    }
  };
},

  mounted() {
    this.fetchLocations();
  },
  computed: {
    isAnyLocationSelected() {
    return this.selectedLocations.length > 0;
  },
  allLocationsSelected() {
      return this.locations.length > 0 && this.locations.every(location => location.selected);
    },
    someLocationsSelected() {
      return this.locations.some(location => location.selected) && !this.allLocationsSelected;
    }
  },
  methods: {
    async fetchLocations() {
      try {
        this.locations = await apiClient.fetchLocations();
        this.loading = false;
      } catch (err) {
        this.error = err;
        this.loading = false;
      }
    },
    editLocationByIndex(index) {
  this.selectedLocation = { ...this.locations[index] };
  
  // Format the time periods for easier editing
  this.selectedLocation.regularHours.periods = this.selectedLocation.regularHours.periods.map(period => ({
    ...period,
    openTimeFormatted: this.formatTimeToString(period.openTime.hours, period.openTime.minutes),
    closeTimeFormatted: this.formatTimeToString(period.closeTime.hours, period.closeTime.minutes),
  }));
  this.showModal = true;
},

    showSuccessMessage(message) {
      this.successMessage = message;
      
      if (this.successTimeout) {
        clearTimeout(this.successTimeout);
      }
      
      this.successTimeout = setTimeout(() => {
        this.successMessage = '';
      }, 3000);
    },
    showTab(tabId) {
      this.currentTab = tabId;
    },

    closeModal() {
      this.showModal = false;
      this.selectedLocation = null;
    },
    formatTimeToString(hours) {
      const formattedHours = hours < 10 ? `0${hours}:00` : `${hours}:00`;
      return formattedHours;
    },
    saveHours() {
      const updatedHours = this.selectedLocation.regularHours.periods.map(period => {
      const [openHours, openMinutes] = period.openTimeFormatted.split(':').map(Number);
      const [closeHours, closeMinutes] = period.closeTimeFormatted.split(':').map(Number);
      return {
        openDay: period.openDay,
        closeDay: period.openDay,
        openTime: { hours: openHours, minutes: openMinutes },
        closeTime: { hours: closeHours, minutes: closeMinutes }
      };
    });

    console.log(updatedHours);
    apiClient.updateHours(this.selectedLocation.name, updatedHours)
      .then(() => {
        this.showSuccessMessage('Store hours updated successfully!');
      })
      .catch(error => console.error('Error updating hours:', error));
  },


    saveLocation() {
      const updatedAddress = {
        revision: 0,
        regionCode: this.selectedLocation.storefrontAddress.regionCode,
        languageCode: this.selectedLocation.storefrontAddress.languageCode,
        postalCode: this.selectedLocation.storefrontAddress.postalCode,
        administrativeArea: this.selectedLocation.storefrontAddress.administrativeArea,
        locality: this.selectedLocation.storefrontAddress.locality,
        addressLines: this.selectedLocation.storefrontAddress.addressLines,
        primaryPhone: this.selectedLocation.primaryPhone
      };

      apiClient.updateLocation(this.selectedLocation.name, updatedAddress)
        .then(() => {
          this.showSuccessMessage('Location details updated successfully!');
        })
        .catch(error => console.error('Error updating location:', error));
    },

    saveButton() {
      if (this.currentTab === 'hours') {
        this.saveHours();
      }
      else if(this.currentTab === 'location') {
        this.saveLocation();
      }
    },
    getCategoryDisplayNames(categories) {
      const categoryList = [];
      if (categories.primaryCategory) {
        categoryList.push({
          name: categories.primaryCategory.name,
          displayName: categories.primaryCategory.displayName
        });
      }
      if (categories.additionalCategories && categories.additionalCategories.length > 0) {
        categories.additionalCategories.forEach(category => {
          categoryList.push({
            name: category.name,
            displayName: category.displayName
          });
        });
      }
      return categoryList;
    },
    toggleSelectAll(event) {
      const isChecked = event.target.checked;
      this.locations.forEach(location => {
        location.selected = isChecked;
      });

      // Update selectedLocations array
      this.selectedLocations = isChecked ? [...this.locations] : [];
    },
    toggleLocationSelection() {
      // Update the selectedLocations array based on which locations are checked
      this.selectedLocations = this.locations.filter(location => location.selected);
    },
    editGlobal() {
      if (this.selectedLocations.length > 0) {
        const firstSelectedLocation = this.selectedLocations[0];
        this.editableDescription = firstSelectedLocation.profile?.description || '';
        this.editableWebsiteUri = firstSelectedLocation.websiteUri || '';
        this.showGlobalModal = true;
      }
    },
    closeGlobalModal() {
      this.showGlobalModal = false;
    },
    showGlobalTab(tabId) {
      this.globalCurrentTab = tabId;
    },
    async saveGlobal() {
      for (const location of this.selectedLocations) {
      try {
        if (this.globalCurrentTab === 'settings') {
          await this.saveGlobalSettings(location);
        } else if (this.globalCurrentTab === 'hours') {
          await this.saveGlobalHours(location);
        }
      } catch (error) {
        console.error(`Error saving for location ${location.name}:`, error);
        alert(`Failed to save for location ${location.name}.`);
      }
    }

    this.showSuccessMessage('Global updates saved successfully!');
  },
  prepareGlobalHoursData() {
  return Object.keys(this.globalHours).map(day => {
    const openTime = this.globalHours[day].openTime.split(":");
    const closeTime = this.globalHours[day].closeTime.split(":");

    return {
      openDay: day,
      openTime: {
        hours: parseInt(openTime[0]), 
        minutes: parseInt(openTime[1])
      },
      closeDay: day,
      closeTime: {
        hours: parseInt(closeTime[0]), 
        minutes: parseInt(closeTime[1]) 
      }
    };
  });
}
,
  saveGlobalHours(location){
    try {
      const globalHoursData = this.prepareGlobalHoursData();
      console.log(globalHoursData);
       apiClient.updateHours(location.name, globalHoursData);
      console.log(`Hours updated for ${location.name}:`);
    } catch (error) {
      console.error(`Error updating hours for ${location.name}:`, error);
      throw error;
    }

  },
    saveGlobalSettings(location) {
      console.log('selected locations' , this.selectedLocations)
      
        location.profile.description = this.editableDescription;
        location.websiteUri = this.editableWebsiteUri;

        apiClient.updateGlobal(location.name, location.profile.description , location.websiteUri )
        console.log(this.editableDescription , ' ' , this.editableWebsiteUri)
    

  },
  }

};
</script>

 <style scoped>
.container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #2c3e50; 
}

.actions {
  margin-bottom: 20px;
}

.table-container {
  background-color: #34495e;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.table {
  border-radius: 8px;
  background-color: #34495e;
  color: #ecf0f1; 
}

th, td {
  padding: 15px;
  text-align: left;
  vertical-align: middle;
  color: #ecf0f1;
}

th {
  background-color: #34495e; 
}

.button.is-rounded {
  border-radius: 25px;
}

.modal-card {
  border-radius: 10px;
}

.tabs ul {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab-content {
  margin-top: 20px;
  color: #ecf0f1; 
}

.field {
  margin-bottom: 15px;
}

.input.is-rounded {
  border-radius: 20px;
}

button {
  font-weight: bold;
}

.modal-background {
  background-color: rgba(0, 0, 0, 0.5);
}

.table.is-bordered {
  background-color: #000000; 
  color: #ecf0f1;
}

.table th {
  background-color: #34495e; 
  color: #ecf0f1;
}
.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.notification.is-success {
  background-color: #48c774;
  color: #fff;
}
tr.selected-row {
  background-color: #3a506b;
} 
.textarea.is-rounded {
  border-radius: 20px;
  margin-bottom: 15px;
}
.input.is-rounded {
  border-radius: 20px;
}
</style>
