<template>
  <div v-if="isAdmin() && getEditProps">
    <!-- Pulsante per aprire il modal -->
     <div v-if="editButton" class="button mt-4" v-on:click="openModal">
      <span class="icon"><i class="fas fa-pen"></i></span>
        <p>Edit Id and Type</p>
     </div>
    <div v-else class="box mt-4" v-on:click="openModal">
      <span class="icon"><i class="fas fa-plus"></i></span>
    </div>
    

    <bulmaModal
      v-if="showModal"
      v-bind:title= titleDeterminator()
      :buttons="['Create Component', 'Undo Changes']"
      @click="handleModalClick"
    >
      <!-- Form per inserire ID e Tipo del nuovo componente -->
      <div class="field">
        <label class="label">Component ID*</label>
        <input class="input" v-model="newComponentId" placeholder="Insert an ID" />
      </div>

      <!-- Contenitore flex per Tipo di componente e Nota del componente -->
      <div class="is-flex">
        <div class="field mr-4" style="flex: 1;">
          <label class="label">Component Type*</label>
          <div class="control">
            <div class="select">
              <select v-model="newComponentType">
                <option disabled value="">Select a Component</option>
                <option value="bulmaMap">Map</option>
                <option value="bulmaContainer">Container</option>
                <option value="bulmaTable">Table</option>
                <option value="bulmaSimpleTable">Simple Table</option>
                <option value="bulmaSections">Sections</option>
                <option value="bulmaTabBar">Tab-Bar</option>
                <option value="bulmaFieldSet2">Fieldset</option>
                <option value="bulmaBottomBar">Bottom-Bar</option>
                <option value="bulmaActions">Actions</option>
                <option value="bulmaSubTab">Subtab</option>
                <option value="bulmaRows">Rows</option>
                <option value="bulmaGraph">Graph</option>
                
              </select>
            </div>
          </div>
        </div>

        <!-- <div class="field" style="flex: 1;">
          <label class="label">Nota del componente</label>
          <input class="input" v-model="newComponentNote" placeholder="Nota opzionale..." />
        </div> -->
      </div>
    </bulmaModal>
  </div>
</template>



<script>
// Importa il componente BulmaModal
import bulmaModal from '../components/bulma_modal.vue';

export default {
  name: 'creator',
  components: {
    bulmaModal
  },
  props: {
    oldid: {
      type: String,
      required: false,  
    },
    fatherId: {
      type: String,
      required: false,  
    },
    editButton: {
      type: Boolean,
      required: false,  
    },
    index: {
  
      required: false,  
    },
  },
  data() {
    return {
      showModal: false,        // Controlla la visibilità del modal
      newComponentId: '',      // ID del nuovo componente
      newComponentType: '',     // Tipo (nome) del nuovo componente
    };
  }, 
  computed: {
    getEditProps(){
      return this.$store.getters.getEditProps;
    }
  },
  methods: {
    isAdmin(){
      return this.$store.getters.isAdmin;
    },
    openModal() {
      this.showModal = true;
    },
    async handleModalClick(buttonIndex) {
      // Controlla quale pulsante è stato cliccato

      if (buttonIndex === 0) {
        await this.addComponent();
      } else {
        this.closeModal();
      }
    },
    // async OLDaddComponent() {
    //   // Controllo se ID e Tipo sono validi
    //   if (!this.newComponentId || !this.newComponentType) {
    //     alert("ID e Tipo sono richiesti");
    //     return;
    //   }
    //   //ricerca del componente nella lista dei componenti 
    //   let exist = await this.$store.getters.getObject(this.newComponentId);
    //   console.log("exist is: ", exist);
    //   //se non presente componente viene agigungo alla lista dei componenti
    //   if(!exist) this.$store.dispatch("createObject", {
    //     id: this.newComponentId,
    //     type: this.newComponentType, 
    //     // notes: this.newComponentNote
    //   });
    //   // Emette l'evento verso il genitore con i dati del nuovo componente
    //   var data = {
    //     component:{
    //       id: this.newComponentId,
    //       type: this.newComponentType,
    //     },
    //     oldid: this.oldid || "",
    //   }//aggiongi note !!!!
    //   // notes: this.newComponentNote || ""
     
    //   console.log("ADDCOMPONENT emit component: ", data);
    //   this.$emit('create-component', data);



    //   // Resetta i campi e chiude il modal
    //   this.closeModal();
    // },
    async addComponent() {
      // Controllo se ID e Tipo sono validi
      if (!this.newComponentId || !this.newComponentType) {
        alert("ID and Type are required ");
        return;
      }
      //ricerca del componente nella lista dei componenti 
      let exist = await this.$store.getters.getObject(this.newComponentId);
      console.log("exist is: ", exist);
      //se non presente componente viene agigungo alla lista dei componenti
      if(!exist){
        let obj={
          id: this.newComponentId,
          type: this.newComponentType, 
        }
        await this.$store.commit("SET_CONFIG_OBJECT", obj);
        await this.$store.dispatch('setObjectDB', obj);

    }
      // Emette l'evento verso il genitore con i dati del nuovo componente
      var data = {
        component:{
          id: this.newComponentId,
          type: this.newComponentType,
        },
        index: this.index!=undefined && this.index >= 0 ? this.index : -1,
      }//aggiongi note !!!!
      // notes: this.newComponentNote || ""
     
      console.log("ADDCOMPONENT emit component: ", data);
      this.$emit('create-component', data);



      // Resetta i campi e chiude il modal
      this.closeModal();
    },
    closeModal() {
      // Chiude il modal e resetta i campi
      this.showModal = false;
      this.newComponentId = '';
      this.newComponentType = '';
      this.newComponentNote = '';
    },
    titleDeterminator(){
      if(this.editButton){
        return "Edit Component"
      }else return "Create a new Component";
    }
  }
};
</script>

<style scoped>
</style>
